import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { SmallFooter } from "../Footer";
import { NavBar } from "./NavBar";

export const FullPageLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      id="PageWrapper"
      sx={{
        backgroundColor: "#eee",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box id="Content" flex="1 0 auto">
        <NavBar />

        <Box id="Page">
          <Container maxWidth="xl">{children}</Container>
        </Box>
      </Box>
      <SmallFooter />
    </Box>
  );
};
export default FullPageLayout;
