import {
  OrderPricing,
  OrderTestTypeSamples,
  useQuoteCartMutation,
} from "../../generated/graphql";
import { useFormContext } from "react-hook-form";
import { formatPrice } from "../../lib/testTypes";
import { useEffect } from "react";
import { parseGQLErrorMessage } from "../../lib/validation";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {useAuthenticated} from "../../lib/auth";

export default function Subtotal({
  pricingInfo,
  setPricingInfo,
  error,
  setError,
}: {
  pricingInfo?: OrderPricing;
  setPricingInfo: (pricingInfo?: OrderPricing) => void;
  error?: string;
  setError: (error: string) => void;
}) {
  const formMethods = useFormContext();
  const [quoteCart] = useQuoteCartMutation();
  const {profile} = useAuthenticated();

  const testTypes = formMethods.watch("testTypes");
  const couponCode = formMethods.watch("couponCode") || "";
  const shipToLabMethod = formMethods.watch("shipToLabMethod") || "";
  const shipToBuyerMethod = formMethods.watch("shipToBuyerMethod") || "";
  const clientCompanyId = formMethods.watch("clientCompanyId") || profile?.clientCompany?.id;

  useEffect(() => {
    async function doQuoteCart() {
      try {
        const result = await quoteCart({
          variables: {
            input: {
              couponCode,
              testTypes,
              shipToBuyerMethod,
              shipToLabMethod,
              clientCompanyId,
            },
          },
        });
        setError("");
        setPricingInfo(result.data?.quoteCart?.pricing ?? undefined);
      } catch (e: any) {
        setError(
          parseGQLErrorMessage(e) ||
            "An unknown error occurred, please contact support"
        );
      }
    }

    if (testTypes) doQuoteCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    testTypes.map((t: OrderTestTypeSamples) => t?.numberOfSamples).join(","),
    testTypes.map((t: OrderTestTypeSamples) => t?.pricePerUnitInCents).join(","),
    couponCode,
    shipToLabMethod,
    shipToBuyerMethod,
    clientCompanyId,
    quoteCart,
  ]);

  const subtotal = formatPrice(pricingInfo?.subtotalPriceInCents || 0);

  const shipping = formatPrice(pricingInfo?.shippingTotalPriceInCents || 0);

  const discount = formatPrice(pricingInfo?.couponDiscountInCents || 0);

  const total = formatPrice(pricingInfo?.totalPriceInCents || 0);

  return (
    <>
      {pricingInfo?.couponCodeValid ? (
        <Box color="green" fontWeight="bold" marginTop={"2em"}>
          Coupon {pricingInfo.couponCode?.toUpperCase()} applied!
          <br />
          {pricingInfo.couponDiscountDescription}
        </Box>
      ) : null}
      {error ? (
        <Box color="red" marginTop={"2em"}>
          {error}
          <br />
          {pricingInfo?.couponDiscountDescription}
        </Box>
      ) : null}

      <Grid container>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Subtotal</TableCell>
              <TableCell align="right">{subtotal}</TableCell>
            </TableRow>

            {pricingInfo?.shippingTotalPriceInCents ? (
              <TableRow>
                <TableCell>Shipping</TableCell>
                <TableCell align="right">{shipping}</TableCell>
              </TableRow>
            ) : null}
            {pricingInfo?.couponCodeValid ? (
              <TableRow>
                <TableCell>Discount</TableCell>
                <TableCell align="right">-{discount}</TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell align="right">
                <Typography variant={"h5"}>{total}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}
