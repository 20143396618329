import {useFormContext} from "react-hook-form";
import {Info} from "@mui/icons-material";
import {formatPrice} from "../../lib/testTypes";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {useAuthenticated} from "../../lib/auth";
import {ClientCompanyFieldsFragment} from "../../generated/graphql";


export default function ShippingOptions({company, disabled}: {company?: ClientCompanyFieldsFragment, disabled?: boolean}) {
  const formMethods = useFormContext()
  const shipToLabMethod = formMethods.watch("shipToLabMethod") || "";
  const shipToBuyerMethod = formMethods.watch("shipToBuyerMethod") || "";
  const {profile, lab, labStaff} = useAuthenticated()
  const shippingToBuyerRequired = !labStaff && lab?.checkoutCustomerMustBuyShippingLabel
  const shippingToLabRequired = !labStaff && lab?.checkoutCustomerMustBuyReturnLabel

  if(lab?.priceShippingUspsFirst === 0 && lab?.priceShippingFedexOvernight === 0) {
    return <>
      <input
        type={"hidden"}
        {...formMethods.register("shipToBuyerMethod")}
        value={""} />
      <input
        type={"hidden"}
        {...formMethods.register("shipToLabMethod")}
        value={""} />
    </>
  }


  return <Grid container>
    <Grid item xs={12}>
      <Grid container alignItems={"center"}>
        <Grid item>
          <h4>Shipping</h4>
        </Grid>
        <Grid item>
          <Tooltip title={disabled ? "Modifying shipping labels is not allowed, if you need to change shipping you must void the order and create a new one" : "Deliver the order after purchase, and include a return label to send the samples back to the lab for testing"}>
            <Info color="primary"/>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        label="Ship to Buyer"
        disabled={disabled || !company?.easypostAddressId}
        fullWidth
        {...formMethods.register("shipToBuyerMethod")}
        select
        defaultValue={shippingToBuyerRequired ? "usps_first" : "none"}
        helperText={
          shipToBuyerMethod === "usps_first"
            ? "Estimated 2 day delivery time"
              : undefined
        }
      >
        {shippingToBuyerRequired ? null : (
          <MenuItem value="none">
            {`No Delivery - ${formatPrice(0)}`}
          </MenuItem>
        )}

        <MenuItem value="usps_first">{`USPS - First Class ${
          lab?.priceShippingUspsFirst &&
          formatPrice(lab.priceShippingUspsFirst)
        }`}</MenuItem>
      </TextField>
      <Typography variant="body2" sx={{mb: 3}}>
        {company && shipToBuyerMethod && shipToBuyerMethod !== "none" ?
          <>
            {`${company?.addressStreet} ${company?.addressStreet2}`}
            <br/>{" "}
            {`${company?.addressCity}, ${company?.addressState} ${company?.addressZip}`}
            <br/>
            <Link style={{color: "#aed581"}} to={"/company-profile"}>
              Edit
            </Link>
          </> : null}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        label="Return to Lab"
        fullWidth
        disabled={disabled || !company?.easypostAddressId}
        {...formMethods.register("shipToLabMethod")}
        select
        defaultValue={shippingToLabRequired ? "usps_first" : "none"}
        helperText={
          shipToLabMethod === "usps_first"
            ? "Estimated 2 day delivery time"
              : undefined
        }
      >
        {shippingToLabRequired ? null : (
          <MenuItem value="none">
            {`No Return Label - ${formatPrice(0)}`}
          </MenuItem>
        )}

        <MenuItem value="usps_first">
          {`USPS - First Class ${
            lab?.priceShippingUspsFirst &&
            formatPrice(lab.priceShippingUspsFirst)
          }`}
        </MenuItem>
      </TextField>
      <Typography variant="body2">
        {shipToLabMethod && shipToLabMethod !== "none" ?
          <>
            {`${lab?.addressStreet} ${lab?.addressStreet2}`}
            <br/>{" "}
            {`${lab?.addressCity}, ${lab?.addressState} ${lab?.addressZip}`}
          </> : null}
      </Typography>
    </Grid>
  </Grid>
}