import { Sample, useTrackingColumnsHistoriesQuery } from "../generated/graphql";
import {
  TrackingColumnHistoriesMap,
  TrackingColumnType,
  TRACKING_COLUMN_ORDER,
} from "../types/types";
import {useAuthenticated} from "./auth";

export const useVisibleTrackingColumns = () => {
  const {profile} = useAuthenticated();

  return profile?.trackingColumnsEnabled as string[];
};

export function convertGQLBlankChoice(option: string | undefined | null) {
  if (option === "A_") return "";
  return option || "";
}

export function convertSampleToSampleInfo(sample: Sample) {
  return Object.assign({}, sample, {
    __typename: undefined,
    status: undefined,
    id: undefined,
    testType: convertGQLBlankChoice(sample.testType),
    stage: convertGQLBlankChoice(sample.stage),
  });
}

export function convertSampleToSampleInfoWithId(sample: Sample) {
  return Object.assign({}, convertSampleToSampleInfo(sample), {
    id: sample.id,
  });
}

export function useSampleTrackingColumnHistories() {
  const trackingColumns = useVisibleTrackingColumns() || [];

  const historiesQuery = useTrackingColumnsHistoriesQuery();

  const showColumns: TrackingColumnType[] = TRACKING_COLUMN_ORDER.filter((c) =>
    trackingColumns.includes(c)
  );

  const remoteHistoriesList =
    historiesQuery.data?.myProfile?.trackingColumnsHistories || [];

  const remoteHistories = (
    remoteHistoriesList?.filter((obj) => obj) || []
  ).reduce((result, obj) => {
    if (!obj) return result;
    return { ...result, [obj.name as string]: obj.history || [] };
  }, {}) as TrackingColumnHistoriesMap;

  return showColumns.reduce(
    (result, col) => ({
      ...result,
      [col]: remoteHistories[col] || [],
    }),
    {}
  );
}
