import {makeStyles} from "@mui/styles";
import {
  AnalyteHealthRangeFieldsFragment,
  AnalyteInfo,
  HealthProfileFragment,
  useGetApplicationSettingsQuery
} from "../generated/graphql";
import {useCallback} from "react";
import { ProductLabels } from "./testTypes";

export function useFindAnalyte() {
  const settings = useGetApplicationSettingsQuery()?.data?.getApplicationSettings;
  return useCallback((kind: string): AnalyteInfo | undefined => {
    const cleaned = kind.replace("result_", "").toLowerCase()
    return settings?.analytes?.find(a => a.id === cleaned)
  }, [settings])
}

export function useFormatAnalyte() {
  const findAnalyte = useFindAnalyte()
  return useCallback((kind: string, value: number): string => {
    const analyte = findAnalyte(kind)
    if (!analyte) return value.toString()

    if (value <= 0) return "Not Detected"
    if (!value) return "Not Tested"

    if (analyte.id === "ph")
      return value.toFixed(2)

    if (analyte.measurementUnit === "no_unit")
      return value.toFixed(1)

    if (analyte.measurementUnit === "cec_estimation")
      return `${value.toFixed(0)} cmol/kg`

    if (analyte.measurementUnit === "ec")
      return `${value.toFixed(0)} mS/cm`

    if (analyte.measurementUnit === "percent") {
      const pct = value / 10000
      const precision = 3
      return `${(pct).toFixed(precision)} %`
    }

    if (analyte.measurementUnit === "ppm") {
      const precision = value > 10 ? 1 : 3
      return `${value.toFixed(precision)} ppm`
    }

    if (analyte.measurementUnit === "ppb") {
      const precision = value > 10 ? 1 : 3
      return `${value.toFixed(precision)} ppb`
    }

    if (analyte.measurementUnit === "detectable") {
      return value ? "Detected" : "Not Detected"
    }

    return value.toString() || ""
  }, [findAnalyte])
}

export const HEALTH_ANALYSIS_RESULTS = {
  DEFICIENT: "Deficient",
  LOW: "Low",
  HEALTHY: "Healthy",
  HIGH: "High",
  EXCESSIVE: "Excessive",
}
export const computeAnalyteHealth = (value: number, range: AnalyteHealthRangeFieldsFragment) => {
  if (value === undefined)
    return null
  if (value < range.min)
    return HEALTH_ANALYSIS_RESULTS.DEFICIENT
  if (value < range.low)
    return HEALTH_ANALYSIS_RESULTS.LOW
  if (value < range.high)
    return HEALTH_ANALYSIS_RESULTS.HEALTHY
  if (value < range.max)
    return HEALTH_ANALYSIS_RESULTS.HIGH
  if (value >= range.max)
    return HEALTH_ANALYSIS_RESULTS.EXCESSIVE
}
export const analyzeHealth = (profile: HealthProfileFragment, analyte: string, value: number) => {
  const range = profile?.analyteRanges?.find(a => a.analyte.toLowerCase() === analyte)
  if (range)
    return computeAnalyteHealth(value, range)
}
export const useHealthAnalysisClasses = makeStyles((theme) => ({
  [HEALTH_ANALYSIS_RESULTS.DEFICIENT]: {
    color: "purple"
  },
  [HEALTH_ANALYSIS_RESULTS.LOW]: {
    color: "blue"
  },
  [HEALTH_ANALYSIS_RESULTS.HEALTHY]: {
    color: "green"
  },
  [HEALTH_ANALYSIS_RESULTS.HIGH]: {
    color: "orange"
  },
  [HEALTH_ANALYSIS_RESULTS.EXCESSIVE]: {
    color: "red"
  }
}))

export const TestsExcludedFromAnalytics = [
  ProductLabels.PYTHIUM_SPP,
  ProductLabels.HOP_LATENT_VIROID,
  ProductLabels.FUSARIUM_SPP,
]