import FullPageLayout from "../../components/Layouts/FullPageLayout";
import { ColumnDefinition } from "../../components/DataTable/types";
import { DataTable } from "../../components/DataTable";
import Card from "@mui/material/Card";
import { useSampleFilterVariables } from "../../components/SampleFilterCard";
import { Loading } from "../../components/Loading";
import { ProductLabels } from "../../lib/testTypes";
import { get } from "lodash";

import {
  LabSamplesFieldsFragment,
  useLabSamplesQuery,
} from "../../generated/graphql";
import HTMLTitle from "../../components/HTMLTitle";

export const LabSamples = () => {
  const [filters] = useSampleFilterVariables();
  const { data, loading } = useLabSamplesQuery({
    variables: { filters: filters },
  });

  const samples = data?.getSamples;

  const columns: ColumnDefinition<LabSamplesFieldsFragment>[] = [
    {
      name: "id",
      label: "Sample ID",
    },
    {
      name: "order.clientCompany.businessName",
      label: "Client Company",
    },
    {
      name: "order.orderDate",
      label: "Order Date",
    },
    {
      name: "testType",
      label: "Test Type",
      render: (sample) => {
        return <HTMLTitle value={get(ProductLabels, sample?.testType) || ""} />;
      },
    },
    {
      name: "status",
      label: "Status",
    },
  ];

  return (
    <FullPageLayout>
      <h1>Samples</h1>

      {loading ? (
        <Loading />
      ) : samples?.length ? (
        <Card sx={{ padding: "1em", marginBottom: "2em" }}>
          <DataTable<LabSamplesFieldsFragment>
            data={samples}
            columns={columns}
            defaultControls={{
              showColumns: columns.map((c) => ({ name: c.name, show: true })),
            }}
          />
        </Card>
      ) : (
        <h3>This lab has no samples.</h3>
      )}
    </FullPageLayout>
  );
};

export default LabSamples;
