export function formatDropdownText(str: string) {
  if(["NFT", "LED", "CMH"].includes(str.toUpperCase()))
    return str.toUpperCase()
  return str.replace(/([A-Z])/g, ' $1').trim()
}

export function formatDropdownInt(str: string) {
    return str.replace("A_", "")
  }

export function formatDropDownDash(str: string) {
    return str.replace(/\b([A-Z])/g, '-$1').trim()
  }