import React, {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {
  LaboratoryFieldsFragment,
  ProfileFieldsFragment,
  useGetSiteLabQuery,
  useMyProfileQuery,
} from "../../generated/graphql";
import {
  TokenStatus,
  tokenStatus
} from "../../lib/auth";
import {useSearchParams} from "react-router-dom";

export type ProfileContextValues = {
  profile?: ProfileFieldsFragment | null | undefined;
  site?: LaboratoryFieldsFragment | null | undefined;
  lab?: LaboratoryFieldsFragment | null | undefined;
  labStaff?: boolean;
  loading: boolean;
  impersonating?: boolean;
}

export const ProfileContext = createContext<ProfileContextValues>({loading: true})

export const ProfileProvider: FC<PropsWithChildren> = ({children}) => {
  const {data, loading, refetch} = useMyProfileQuery();
  const profile = data?.myProfile?.id ? data.myProfile : null
  const impersonating = !!localStorage.getItem("impersonate")
  const [params] = useSearchParams()
  const getSite = useGetSiteLabQuery({variables: {domain: params.get("siteId") || localStorage.getItem("debug-test-hostname") || window.location.hostname}});
  const site = getSite.data?.getCurrentLaboratory;
  const [status, setStatus] = useState<TokenStatus>(tokenStatus())

  const labStaff = !!profile?.staffAtLaboratory?.id;
  const lab: LaboratoryFieldsFragment | null =
    (labStaff
      ? profile?.staffAtLaboratory
      : profile?.clientCompany?.laboratory) || null;

  useEffect(() => {
    const interval = setInterval(() => {
      setStatus(tokenStatus())
    }, 5_000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (lab?.customDomain && window.location.hostname.toLowerCase() !== lab?.customDomain.toLowerCase()) {
      if (!window.location.hostname.includes("localhost")) {
        if (!impersonating)
          window.location.hostname = lab?.customDomain
        else
          console.warn("Skipping domain redirect because we are impersonating")
      } else {
        console.warn("Skipping custom domain redirect for localhost")
      }
    }
  }, [lab?.customDomain])

  return <ProfileContext.Provider value={{
    profile,
    site,
    labStaff,
    lab,
    impersonating,
    loading: loading || getSite.loading,
  }}>
    {children}
  </ProfileContext.Provider>
}

export const useProfile = () => useContext(ProfileContext)