import React, {useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {DataTableProvider} from "./DataTableProvider";
import {DataTableWidget} from "./DataTableWidget";

import {
  BackendTableControls,
  ColumnDefinition,
  DataRow,
  DataTableContainerControls,
  DataTableOptions,
  DataTableProviderProps,
  DataTableWidgetProps, DEFAULT_PER_PAGE,
  DEFAULT_PER_PAGE_OPTIONS,
  getHeaderLabel,
  ShowColumn,
} from "./types";

//mui
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ViewColumn from "@mui/icons-material/ViewColumn";
import Search from "@mui/icons-material/Search";
import {PagingInfoFragment} from "../../generated/graphql";
import {Loading} from "../Loading";

export function DataTablePagination({count, backendTable, backendPagingResponse}: {
  count: number,
  backendTable?: BackendTableControls<any>,
  backendPagingResponse?: PagingInfoFragment
}) {
  const form = useFormContext<DataTableContainerControls>();
  const page = form.watch("page");
  const perPage = form.watch("perPage");
  const backend = !!backendTable && !!backendPagingResponse;
  return (
    <TablePagination
      count={(backend ? backendPagingResponse.totalCount : count) || 0}
      page={(backend ? backendPagingResponse.page - 1 : page) || 0}
      rowsPerPage={(backend ? backendPagingResponse.size : perPage) || DEFAULT_PER_PAGE}
      rowsPerPageOptions={DEFAULT_PER_PAGE_OPTIONS}
      onPageChange={(event, newPage) => {
        form.setValue("page", newPage);
        backendTable?.setPage(newPage)
      }}
      onRowsPerPageChange={(event) => {
        form.setValue("perPage", parseInt(event.target.value));
        form.setValue("page", 0);
        backendTable?.setPerPage(parseInt(event.target.value))
      }}
    />
  );
}

export function DataTable<T extends DataRow>({
                                               defaultControls,
                                               data,
                                               columns,
                                               options,
                                               loading
                                             }: DataTableWidgetProps<T> & Omit<DataTableProviderProps, "allColumnNames"> & {
  loading?: boolean
}) {
  return (
    <DataTableProvider
      defaultControls={defaultControls}
      allColumnNames={columns.map(({name}) => name)}
      backendTable={options?.backendTable}
      backendPagingResponse={options?.backendPagingResponse}
    >
      <TableContainer>
        <TableControlWidgets columns={columns} options={options || {}}/>
        {
          data?.length ?
            <DataTableWidget<T> columns={columns} data={data} options={options}/>
            : loading ? <Loading/> : <div style={{padding: "1em"}}>No data</div>
        }
      </TableContainer>
    </DataTableProvider>
  );
}

export function TableControlWidgets<T extends DataRow>({
                                                         columns,
                                                         options,
                                                       }: {
  columns: ColumnDefinition<T>[];
  options: DataTableOptions<T>;
}) {
  return (
    <Grid container alignItems={"flex-end"}>
      <ShowColumnsWidget columns={columns}/>
      <SearchWidget backendTable={options.backendTable}/>
      {options.extraButtons?.map((button, idx) => (
        <Grid item key={idx}>
          {button}
        </Grid>
      ))}
    </Grid>
  );
}

export function ShowColumnsWidget<T extends DataRow>({
                                                       columns,
                                                     }: {
  columns: ColumnDefinition<T>[];
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const methods = useFormContext<DataTableContainerControls>();
  const showColumns = useWatch({
    name: "showColumns",
    control: methods.control,
  }) as ShowColumn[];
  return (
    <Grid item>
      <Menu
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        {columns.filter(c => !c.disableToggle).map((c) => {
          const showColumnIndex = showColumns.findIndex(
            ({name}) => name === c.name
          );
          const field = `showColumns.${showColumnIndex}.show` as const;
          const isShown = Boolean(methods.watch(field));
          return (
            <Box key={c.name} style={{padding: "0.25em 0.5em"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isShown}
                    onChange={(e, newValue) => {
                      methods.setValue(field, newValue);
                    }}
                  />
                }
                label={getHeaderLabel(c)}
              />
            </Box>
          );
        })}
      </Menu>
      <Tooltip title={"Show/hide columns"}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <ViewColumn/>
        </IconButton>
      </Tooltip>
    </Grid>
  );
}

export function SearchWidget({backendTable}: { backendTable?: BackendTableControls<any> }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const methods = useFormContext<DataTableContainerControls>();
  return (
    <Grid item>
      <Menu
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        <TextField
          style={{margin: "0.5em"}}
          value={methods.watch("search")}
          label={"* Search"} // Without the * here, typing "S" breaks focus on the input box
          autoFocus
          onChange={(e) => {
            methods.setValue("search", e.target.value);
            backendTable?.setSearch(e.target.value)
          }}
        />
      </Menu>
      <Tooltip title={"Search"}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Search/>
        </IconButton>
      </Tooltip>
    </Grid>
  );
}
