import { useState } from "react";
import { Controller, get, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {setAuthToken, useAuthenticated} from "../lib/auth";
import { parseGQLErrorMessage } from "../lib/validation";
import { object, SchemaOf, string } from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";

// mui
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

// types
import {
  useMyProfileLazyQuery,
  useTokenAuthMutation,
} from "../generated/graphql";

export type AuthArgs = {
  email: string;
  password: string

}

const validationSchema: SchemaOf<Omit<AuthArgs, "username">> =
  object({
    email: string()
      .email("Please enter a valid email")
      .required("Please enter your email address"),
    password: string().required("Please enter a password"),
  });

export const LoginForm = () => {
  const { handleSubmit, control, formState } = useForm<AuthArgs>({
    resolver: yupResolver(validationSchema),
  });
  const [error, setError] = useState("");
  const [loginUser] = useTokenAuthMutation();
  const [getProfile] = useMyProfileLazyQuery();
  const {site} = useAuthenticated()
  const navigate = useNavigate();

  const _onSubmit = async (data: AuthArgs) => {
    try {
      const result = (
        await loginUser({
          variables: {
            ...data,
            email: data.email || "",
          },
        })
      ).data?.tokenAuth;
      setError("");

      if (result?.__typename === "TokenAuthSuccess") {
        setAuthToken(result.token || "", result.refreshToken || "");
        getProfile();
        window.location.pathname = '/'
      } else {
        setError("Invalid username or password");
      }
    } catch (err) {
      console.error(err);
      setError(
        parseGQLErrorMessage(err) || "An error occurred, please contact support"
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)} noValidate>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
          <Card
            style={{
              padding: "2em",
              textAlign: "center",
              margin: "1em 1em 2em",
            }}
          >
            <h1 style={{ margin: "0 0 1em", fontSize: "40px" }}>Login</h1>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Email"
                      fullWidth
                      required
                      error={!!get(formState.errors, "email")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"email"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Password"
                      fullWidth
                      required
                      type="password"
                      error={!!get(formState.errors, "password")}
                      helperText={
                        <Link
                          to="/password-reset"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            component="a"
                            variant="inherit"
                            color="primary"
                          >
                            Forgot password?
                          </Typography>
                        </Link>
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box color="red" marginTop={error ? "2em" : 0}>
              {error}
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ margin: "3em 0 1em" }}
              disabled={formState.isSubmitting}
            >
              Login
            </Button>
            {!site || site.accountRegistrationEnabled ?
            <Box pt={1}>
              <Typography variant="body2">
                <Link to="/register">Sign Up Now</Link>
              </Typography>
            </Box>
              : null }
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
