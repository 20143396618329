import {TableCellProps} from "@mui/material/TableCell";
import {ReactNode, useState} from "react";
import {get, UseFormReturn} from "react-hook-form";
import {PagingInfoFragment, PagingInput} from "../../generated/graphql";

export const DEFAULT_PER_PAGE = 10
export const DEFAULT_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100]
export type ShowColumn = { name: string, show?: boolean }
export type DataTableContainerControls = {
  page?: number;
  perPage?: number;
  showColumns?: ShowColumn[];
  sortBy?: string;
  sortOrder?: string;
  search?: string;
  view?: string;
}
export type DataTableProviderProps = {
  defaultControls?: DataTableContainerControls;
  allColumnNames: string[];
  backendTable?: BackendTableControls<any>;
  backendPagingResponse?: PagingInfoFragment
}
export type DataTableWidgetProps<T extends DataRow> = {
  columns: ColumnDefinition<T>[]
  data: T[];
  options?: DataTableOptions<T>
}

export type DataTableCallbacks<T extends DataRow> = {
  onControlsChange?: (controls: DataTableContainerControls) => void;
  onRowsChange?: (rows: T[]) => void;
}

export type DataTableOptions<T extends DataRow> = {
  showPagination?: boolean;
  callbacks?: DataTableCallbacks<T>;
  draggableColumns?: DataTableDraggableColumnOptions;
  viewOptions?: DataTableViewOptions;
  extraButtons?: ReactNode[];
  backendTable?: BackendTableControls<any>;
  backendPagingResponse?: PagingInfoFragment
}

export type DataTableDraggableColumnOptions = {
  enabled?: boolean;
}

export type DataTableViewOptions = {
  viewType: string,
  modifyViewControls?: (controls: DataTableContainerControls) => DataTableContainerControls
}

export type RenderMeta<T extends DataRow> = {
  methods: UseFormReturn<DataTableContainerControls>;
  column: ColumnDefinition<T>;
}
export type ColumnDefinition<T extends DataRow> = {
  name: string;
  label?: string | JSX.Element;
  accessor?: string;
  sortable?: boolean | string;
  filterable?: boolean;
  searchable?: boolean;
  draggable?: boolean;
  disableToggle?: boolean;
  getValue?: (row: T) => string | number | Date;
  getSortValue?: (row: T) => string | number | Date;
  getFilterValue?: (row: T) => string | number | Date;
  getSearchValue?: (row: T) => string | number | Date;
  render?: (row: T, meta: RenderMeta<T>) => string | number | Date | ReactNode;

  align?: "left" | "center" | "right";
  hint?: string | ReactNode;
  setCellProps?: () => TableCellProps
  setHeaderCellProps?: () => TableCellProps
}

export type DataRow = {
  id: string;
  [key: string]: any;
}

export function renderCell<T extends DataRow>(column: ColumnDefinition<T>, row: T, methods: UseFormReturn<DataTableContainerControls>) {
  if (column.render)
    return column.render(row, {methods, column})
  return column.getValue?.(row) ?? (
    getCellAccessorOrName(column, row)
  )
}

export function getCellAccessorOrName<T extends DataRow>(column: ColumnDefinition<T>, row: T) {
  return column.accessor ? get(row, column.accessor) : get(row, column.name)
}

export function getCellValue<T extends DataRow>(column: ColumnDefinition<T>, row: T) {
  return column.getValue ? column.getValue(row) : getCellAccessorOrName(column, row);
}

export function getSortValue<T extends DataRow>(column: ColumnDefinition<T>, row: T) {
  return column.getSortValue ? column.getSortValue(row) : getCellValue(column, row);
}

export function getSearchValue<T extends DataRow>(column: ColumnDefinition<T>, row: T) {
  return column.getSearchValue ? column.getSearchValue(row) : getCellValue(column, row);
}

export function searchRow<T extends DataRow>(row: T, columns: ColumnDefinition<T>[], search: string) {
  return search.split(" ").every(term => columns.some(column => {
    const value = getSearchValue(column, row);
    return value && value.toString().toLowerCase().includes(term.toLowerCase());
  }))
}

export function getHeaderLabel<T extends DataRow>(column: ColumnDefinition<T>) {
  return column.label ?? column.name.toUpperCase()
}


export type GenericBackendFilters = { [key: string]: string | undefined | null }
export type GenericBackendTableOptions = {
  paging?: PagingInput | undefined | null,
  filters?: GenericBackendFilters | undefined | null
}

export type BackendTableControls<T extends GenericBackendTableOptions> = {
  options: T
  setOptions: React.Dispatch<React.SetStateAction<T>>
  setPaging: (newPaging: PagingInput) => void
  setFilters: (newFilters: GenericBackendFilters) => void
  setPage: (page: number) => void
  setPerPage: (perPage: number) => void
  setSearch: (search: string) => void
  setOrdering: (ordering: string) => void
  setSorting: (sortBy: string, sortOrder?: "asc" | "desc") => void
}

export function useBackendTable<Options extends GenericBackendTableOptions>(defaultValue: Options): BackendTableControls<Options> {
  const [options, setOptions] = useState<Options>({
    paging: {start: 0, size: DEFAULT_PER_PAGE},
    filters: undefined,
    ...defaultValue
  });
  const setPaging = (newPaging: PagingInput) => {
    setOptions({...options, paging: {...options.paging, ...newPaging}})
  }
  const setFilters = (newFilters: GenericBackendFilters) => {
    setOptions({...options, filters: newFilters})
  }
  const setPage = (page: number) => {
    setPaging({...options.paging, start: options.paging?.size ? page * options.paging.size : 0})
  }
  const setPerPage = (size: number) => {
    setPaging({...options.paging, size, start: 0})
  }

  const setSearch = (search: string) => {
    setFilters({...options.filters, search})
  }

  const setOrdering = (ordering: string) => {
    setPaging({...options.paging, ordering})
  }

  const setSorting = (sortBy: string, sortOrder?: "asc" | "desc") => {
    setOrdering(!sortOrder || sortOrder === "asc" ? sortBy : `-${sortBy}`)
  }

  return {
    options,
    setOptions,
    setPaging,
    setFilters,
    setPage,
    setPerPage,
    setSearch,
    setOrdering,
    setSorting
  }
}