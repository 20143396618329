import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { get } from "lodash";
import { useEasySnackbar } from "../../lib/snackbar";
import { Loading } from "../../components/Loading";
import { DataTable } from "../../components/DataTable";
import { ColumnDefinition } from "../../components/DataTable/types";

import {
  ClientCompanyCultivationStyle,
  ClientCompanyGeneticsSource,
  ClientCompanyGrowingMedia,
  ClientCompanyInternalFragment,
  ClientCompanyIrrigationFrequency,
  ClientCompanyIrrigationStyle,
  ClientCompanyLightingType,
  ClientCompanyMarketDestination,
  ClientCompanyPotSizeGallon,
  ClientCompanyRunoffVolume,
  ClientCompanySourceCuttings,
  ClientCompanySourceTrim,
  ClientCompanyWaterSource,
  CreateLabClientNoteInput,
  GetClientCompanyDocument,
  GetClientCompanyInternalDocument,
  LabClientNoteFieldsFragment,
  useCreateLabClientNoteMutation,
  useGetClientCompanyInternalQuery,
  useUpdateClientCompanyInternalMutation,
} from "../../generated/graphql";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { formatDropDownDash, formatDropdownInt, formatDropdownText } from "../../lib/regex";

type ClientCompanyInternalWrapperProps = {
  companyId: String;
};

type ClientCompanyInternalFormProps = {
  data: ClientCompanyInternalFragment;
  companyId: String;
};

export const ClientCompanyInternalWrapper: FC<
  ClientCompanyInternalWrapperProps
> = ({ companyId }) => {
  const { data, loading } = useGetClientCompanyInternalQuery({
    variables: { id: companyId as string },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" fontWeight={"bold"}>
            Internal Lab Client Details
          </Typography>
          <Typography variant="body2">
            Take notes on Client Companies to help them better manage their
            processes. These fields are hidden to the Client Company.
          </Typography>

          <ClientCompanyInternalForm
            data={
              data?.getClientCompanyInternal as ClientCompanyInternalFragment
            }
            companyId={companyId}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" fontWeight={"bold"}>
            Lab Client Notes
          </Typography>

          <ClientCompanyInternalJournalEntryForm
            data={
              data?.getClientCompanyInternal as ClientCompanyInternalFragment
            }
            companyId={companyId}
          />
        </Card>
      </Grid>
    </>
  );
};

export const ClientCompanyInternalForm: FC<ClientCompanyInternalFormProps> = ({
  data,
  companyId,
}) => {
  const { register, handleSubmit, reset, control, watch } =
    useForm<ClientCompanyInternalFragment>({
      defaultValues: data,
    });

  const [updateClientCompanyInternal] = useUpdateClientCompanyInternalMutation({
    refetchQueries: [
      {
        query: GetClientCompanyDocument,
        variables: {
          id: companyId,
        },
      },
    ],
  });

  const snackbar = useEasySnackbar();

  const _handleSubmit = async ({
    __typename,
    notes,
    ...input
  }: ClientCompanyInternalFragment) => {
    try {
      await updateClientCompanyInternal({
        variables: {
          input: {
            id: companyId as string,
            cultivationStyle: input.cultivationStyle || null,
            harvestsPerYear: input.harvestsPerYear || null,
            numDaysHarvest: input.numDaysHarvest || null,
            numDaysMother: input.numDaysMother || null,
            numDaysPropagation: input.numDaysPropagation || null,
            numDaysVeg: input.numDaysVeg || null,
            numDaysFlower: input.numDaysFlower || null,
            motherRooms: input.motherRooms || null,
            propagationRooms: input.propagationRooms || null,
            vegRooms: input.vegRooms || null,
            flowerRooms: input.flowerRooms || null,
            roWaterFiltration: input.roWaterFiltration || null,
            sourceCuttings: input.sourceCuttings || null,
            sourceTrim: input.sourceTrim || null,
            dataTracking: input.dataTracking || null,
            growingMedia: input.growingMedia || null,
            containerSize: input.containerSize || null,
            nutrientLine: input.nutrientLine || null,
            facilitySize: input.facilitySize || null,
            numLights: input.numLights || null,
            numSites: input.numSites || null,
            issueYield: input.issueYield || null,
            issueQuality: input.issueQuality || null,
            issueConsistency: input.issueConsistency || null,
            issuePests: input.issuePests || null,
            issuePathogens: input.issuePathogens || null,
            issueBudSize: input.issueBudSize || null,
            issueWater: input.issueWater || null,
            issueEnvironmental: input.issueEnvironmental || null,
            issueCosts: input.issueCosts || null,
            issuePlantNutrition: input.issuePlantNutrition || null,
            issueStaffing: input.issueStaffing || null,
            goalYield: input.goalYield || null,
            goalQuality: input.goalQuality || null,
            goalConsistency: input.goalConsistency || null,
            additiveBacterias: input.additiveBacterias || null,
            additiveEnzymes: input.additiveEnzymes || null,
            additiveCarbohydrates: input.additiveCarbohydrates || null,
            additiveKelpExtract: input.additiveKelpExtract || null,
            additiveSilica: input.additiveSilica || null,
            additiveBloomBooster: input.additiveBloomBooster || null,
            additiveFlowerHardener: input.additiveFlowerHardener || null,
            additiveTerpeneEnhancer: input.additiveTerpeneEnhancer || null,
            additivePotencyEnhancer: input.additivePotencyEnhancer || null,
            additiveColorEnhancer: input.additiveColorEnhancer || null,
            additiveNutritional: input.additiveNutritional || null,
            sensorTemp: input.sensorTemp || null,
            sensorHumidity: input.sensorHumidity || null,
            sensorCo2: input.sensorCo2 || null,
            sensorLight: input.sensorLight || null,
            sensorSubstrate: input.sensorSubstrate || null,
            sensorVpd: input.sensorVpd || null,
            potSizeGallon: input.potSizeGallon || null,
            marketDestination: input.marketDestination || null,
            waterSource: input.waterSource || null,
            geneticsSource: input.geneticsSource || null,
            lightingType: input.lightingType || null,
            numStrains: input.numStrains || null,
            numFlagshipStrains: input.numFlagshipStrains || null,
            irrigationStyle: input.irrigationStyle || null,
            irrigationFrequency: input.irrigationFrequency || null,
            fertigationSystem: input.fertigationSystem || null,
            flushBeforeHarvest: input.flushBeforeHarvest || null,
            daysOfFlushBeforeHarvest: input.daysOfFlushBeforeHarvest || null,
            runoffVolume: input.runoffVolume || null,
            environmentalControlSystem: input.environmentalControlSystem || null,
          },
        },
      });
      snackbar.showSuccess("Internal Lab Notes were successfully updated!");
    } catch (e) {
      snackbar.showError(
        e,
        "There was an error updating these Internal Lab Notes"
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(_handleSubmit)}
      noValidate
      style={{ marginTop: "1em" }}
    >
      <Grid container spacing={3} mb={1}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}><Typography variant="h6">Facility</Typography></Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Facility Size"}
                {...register("facilitySize")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Data Tracking"}
                {...register("dataTracking")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Lighting Type"}
                {...register("lightingType")}
                defaultValue={data.lightingType}
                select
              >
                {Object.keys(ClientCompanyLightingType).map((type) => (
                  <MenuItem
                    key={type}
                    value={get(ClientCompanyLightingType, type)}
                  >
                    {formatDropdownText(type)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Environmental Control System"}
                {...register("environmentalControlSystem")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Market Destination"}
                {...register("marketDestination")}
                defaultValue={data.marketDestination}
                select
              >
                {Object.keys(ClientCompanyMarketDestination).map((market) => (
                  <MenuItem
                    key={market}
                    value={get(ClientCompanyMarketDestination, market)}
                  >
                    {formatDropdownText(market)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Water Source"}
                {...register("waterSource")}
                defaultValue={data.waterSource}
                select
              >
                {Object.keys(ClientCompanyWaterSource).map((source) => (
                  <MenuItem
                    key={source}
                    value={get(ClientCompanyWaterSource, source)}
                  >
                    {source}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Genetics Source"}
                {...register("geneticsSource")}
                defaultValue={data.geneticsSource}
                select
              >
                {Object.keys(ClientCompanyGeneticsSource).map((source) => (
                  <MenuItem
                    key={source}
                    value={get(ClientCompanyGeneticsSource, source)}
                  >
                    {formatDropdownText(source)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Source of Cuttings"}
                {...register("sourceCuttings")}
                defaultValue={data.sourceCuttings}
                select
              >
                {Object.keys(ClientCompanySourceCuttings).map((source) => (
                  <MenuItem
                    key={source}
                    value={get(ClientCompanySourceCuttings, source)}
                  >
                    {formatDropdownText(source)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Source of Trimming"}
                {...register("sourceTrim")}
                defaultValue={data.sourceTrim}
                select
              >
                {Object.keys(ClientCompanySourceTrim).map((source) => (
                  <MenuItem
                    key={source}
                    value={get(ClientCompanySourceTrim, source)}
                  >
                    {formatDropdownText(source)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Num Lights"}
                {...register("numLights", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Num Sites"}
                {...register("numSites", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Num Strains"}
                {...register("numStrains", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Num Flagship Strains"}
                {...register("numFlagshipStrains", { valueAsNumber: true })}
              />
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}><Typography variant="h6">Harvest</Typography></Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Cultivation Style"}
                inputProps={register("cultivationStyle")}
                defaultValue={data.cultivationStyle}
                select
              >
                {Object.entries(ClientCompanyCultivationStyle).map(
                  ([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {formatDropdownText(key)}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Harvests per Year"}
                {...register("harvestsPerYear", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Days to Harvest"}
                {...register("numDaysHarvest", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Growing Media"}
                {...register("growingMedia")}
                defaultValue={data.growingMedia}
                select
              >
                {Object.keys(ClientCompanyGrowingMedia).map((media) => (
                  <MenuItem
                    key={media}
                    value={get(ClientCompanyGrowingMedia, media)}
                  >
                    {formatDropdownText(media)}

                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Pot Size (Gal)"}
                {...register("potSizeGallon")}
                defaultValue={data.potSizeGallon}
                select
              >
                {Object.keys(ClientCompanyPotSizeGallon).map((size) => (
                  <MenuItem
                    key={size}
                    value={get(ClientCompanyPotSizeGallon, size)}
                  >
                    {formatDropdownInt(size)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Days in Mother"}
                {...register("numDaysMother", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Days in Propagation"}
                {...register("numDaysPropagation", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Days in Veg"}
                {...register("numDaysVeg", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Days in Flower"}
                {...register("numDaysFlower", { valueAsNumber: true })}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Mother Rooms"}
                {...register("motherRooms", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Propagation Rooms"}
                {...register("propagationRooms", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Veg Rooms"}
                {...register("vegRooms", { valueAsNumber: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Flower Rooms"}
                {...register("flowerRooms", { valueAsNumber: true })}
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12} md={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="h6">Goals</Typography></Grid>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel control={<Checkbox {...register("goalYield")} checked={watch("goalYield") || false} />} label="Yield" />
                <FormControlLabel control={<Checkbox {...register("goalQuality")} checked={watch("goalQuality") || false} />} label="Quality" />
                <FormControlLabel control={<Checkbox {...register("goalConsistency")} checked={watch("goalConsistency") || false} />} label="Consistency" />

              </FormGroup>
            </Grid>

          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="h6">Sensors</Typography></Grid>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel control={<Checkbox {...register("sensorTemp")} checked={watch("sensorTemp") || false} />} label="Temperature" />
                <FormControlLabel control={<Checkbox {...register("sensorHumidity")} checked={watch("sensorHumidity") || false} />} label="Humidity" />
                <FormControlLabel control={<Checkbox {...register("sensorCo2")} checked={watch("sensorCo2") || false} />} label="CO2" />

              </FormGroup>
            </Grid>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel control={<Checkbox {...register("sensorLight")} checked={watch("sensorLight") || false} />} label="Light" />
                <FormControlLabel control={<Checkbox {...register("sensorSubstrate")} checked={watch("sensorSubstrate") || false} />} label="Substrate" />
                <FormControlLabel control={<Checkbox {...register("sensorVpd")} checked={watch("sensorVpd") || false} />} label="VPD" />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}><Typography variant="h6">Irrigation</Typography></Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Style"}
                {...register("irrigationStyle")}
                defaultValue={data.irrigationStyle}
                select
              >
                {Object.keys(ClientCompanyIrrigationStyle).map((style) => (
                  <MenuItem
                    key={style}
                    value={get(ClientCompanyIrrigationStyle, style)}
                  >
                    {formatDropdownText(style)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={8}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Frequency"}
                {...register("irrigationFrequency")}
                defaultValue={data.irrigationFrequency}
                select
              >
                {Object.keys(ClientCompanyIrrigationFrequency).map((freq) => (
                  <MenuItem
                    key={freq}
                    value={get(ClientCompanyIrrigationFrequency, freq)}
                  >
                    {formatDropdownText(freq)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Fertigation System"}
                {...register("fertigationSystem")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Nutrient Line"}
                {...register("nutrientLine")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"Run Off Volume"}
                {...register("runoffVolume")}
                defaultValue={data.runoffVolume}
                select
              >
                {Object.keys(ClientCompanyRunoffVolume).map((vol) => (
                  <MenuItem
                    key={vol}
                    value={get(ClientCompanyRunoffVolume, vol)}
                  >
                    {formatDropdownInt(vol)}{vol !== "Other" && "%"}

                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="roWaterFiltration"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          checked={value as boolean}
                          inputRef={ref}
                        />
                      }
                      label="RO Water Filtration"
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="flushBeforeHarvest"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          checked={value as boolean}
                          inputRef={ref}
                        />
                      }
                      label="Flush Before Harvest"
                    />
                  </FormGroup>
                )}
              />
            </Grid>

            {watch("flushBeforeHarvest") ? <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={"Days of Flush Before Harvest"}
                {...register("daysOfFlushBeforeHarvest", { valueAsNumber: true })}
              />
            </Grid> : null}

            

          </Grid>
        </Grid>


        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="h6">Issues</Typography></Grid>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel control={<Checkbox {...register("issueYield")} checked={watch("issueYield") || false} />} label="Yield" />
                <FormControlLabel control={<Checkbox {...register("issueQuality")} checked={watch("issueQuality") || false} />} label="Quality" />
                <FormControlLabel control={<Checkbox {...register("issueConsistency")} checked={watch("issueConsistency") || false} />} label="Consistency" />
                <FormControlLabel control={<Checkbox {...register("issuePests")} checked={watch("issuePests") || false} />} label="Pests" />
                <FormControlLabel control={<Checkbox {...register("issuePathogens")} checked={watch("issuePathogens") || false} />} label="Pathogens" />
                <FormControlLabel control={<Checkbox {...register("issueBudSize")} checked={watch("issueBudSize") || false} />} label="Bud Size" />
              </FormGroup>
            </Grid>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel control={<Checkbox {...register("issueWater")} checked={watch("issueWater") || false} />} label="Water" />
                <FormControlLabel control={<Checkbox {...register("issueEnvironmental")} checked={watch("issueEnvironmental") || false} />} label="Environmental" />
                <FormControlLabel control={<Checkbox {...register("issueCosts")} checked={watch("issueCosts") || false} />} label="Costs" />
                <FormControlLabel control={<Checkbox {...register("issuePlantNutrition")} checked={watch("issuePlantNutrition") || false} />} label="Plant Nutrition" />
                <FormControlLabel control={<Checkbox {...register("issueStaffing")} checked={watch("issueStaffing") || false} />} label="Staffing" />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}><Typography variant="h6">Additives</Typography></Grid>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel control={<Checkbox {...register("additiveBacterias")} checked={watch("additiveBacterias") || false} />} label="Bacterias/Microbials" />
                <FormControlLabel control={<Checkbox {...register("additiveEnzymes")} checked={watch("additiveEnzymes") || false} />} label="Enzymes" />
                <FormControlLabel control={<Checkbox {...register("additiveCarbohydrates")} checked={watch("additiveCarbohydrates") || false} />} label="Carbohydrates/Sweetener" />
                <FormControlLabel control={<Checkbox {...register("additiveKelpExtract")} checked={watch("additiveKelpExtract") || false} />} label="Kelp Extract" />
                <FormControlLabel control={<Checkbox {...register("additiveSilica")} checked={watch("additiveSilica") || false} />} label="Silica" />
                <FormControlLabel control={<Checkbox {...register("additiveBloomBooster")} checked={watch("additiveBloomBooster") || false} />} label="Bloom Booster" />
              </FormGroup>
            </Grid>
            <Grid item xs>
              <FormGroup>
                <FormControlLabel control={<Checkbox {...register("additiveFlowerHardener")} checked={watch("additiveFlowerHardener") || false} />} label="Flower Hardener" />
                <FormControlLabel control={<Checkbox {...register("additiveTerpeneEnhancer")} checked={watch("additiveTerpeneEnhancer") || false} />} label="Terpene Enhancer" />
                <FormControlLabel control={<Checkbox {...register("additivePotencyEnhancer")} checked={watch("additivePotencyEnhancer") || false} />} label="Potency Enhancer" />
                <FormControlLabel control={<Checkbox {...register("additiveColorEnhancer")} checked={watch("additiveColorEnhancer") || false} />} label="Color Enhancer" />
                <FormControlLabel control={<Checkbox {...register("additiveNutritional")} checked={watch("additiveNutritional") || false} />} label="Nutritional" />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        size="small"
        type="submit"
        sx={{ float: "right" }}
      >
        Update Details
      </Button>
    </form>
  );
};

export const ClientCompanyInternalJournalEntryForm: FC<
  ClientCompanyInternalFormProps
> = ({ data, companyId }) => {
  const { register, handleSubmit, reset } = useForm<CreateLabClientNoteInput>();

  const [createLabClientNote] = useCreateLabClientNoteMutation({
    refetchQueries: [
      {
        query: GetClientCompanyInternalDocument,
        variables: {
          id: companyId,
        },
      },
    ],
  });

  const snackbar = useEasySnackbar();

  const _handleSubmit = async (input: CreateLabClientNoteInput) => {
    try {
      await createLabClientNote({
        variables: {
          input: {
            content: input.content,
            clientCompanyId: companyId as string,
          },
        },
      });
      snackbar.showSuccess("Lab Client Note Entry successfully created!");
      reset();
    } catch (e) {
      snackbar.showError(
        e,
        "There was an error creating a Lab Client Note entry."
      );
    }
  };

  const columns: ColumnDefinition<LabClientNoteFieldsFragment>[] = [
    {
      name: "createdAt",
      label: "Created",
      render: (note) => new Date(note.createdAt).toLocaleDateString(),
    },
    {
      name: "createdBy",
      label: "By",
      render: (note) =>
        `${note.createdBy?.firstName} ${note.createdBy?.lastName}`,
    },
    {
      name: "content",
      label: "Content",
    },
  ];

  return (
    <form
      onSubmit={handleSubmit(_handleSubmit)}
      noValidate
      style={{ marginTop: "1em" }}
    >
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            multiline
            rows={2}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={"Create Entry"}
            {...register("content")}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type="submit"
            sx={{ float: "right" }}
          >
            Submit Entry
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DataTable<LabClientNoteFieldsFragment>
            data={
              data.notes.filter((n) => n?.id) as LabClientNoteFieldsFragment[]
            }
            columns={columns}
            defaultControls={{
              showColumns: columns.map((c) => ({
                name: c.name,
                show: true,
              })),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
