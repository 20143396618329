import {useParams} from "react-router-dom";
import FullPageLayout from "../../components/Layouts/FullPageLayout";
import {
  ApplicationSettingsFragment,
  SampleTestType,
  useGetApplicationSettingsQuery,
  useGetTestResultImportsQuery,
  useUpdateResultsMutation
} from "../../generated/graphql";
import {Loading} from "../../components/Loading";
import {useState} from "react";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {Grid, IconButton, MenuItem, Tab, TableBody, TableCell, Tabs, Tooltip, Typography, useEventCallback} from "@mui/material";
import {Cancel, Check, Download} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {ProductLabels} from "../../lib/testTypes";
import TableContainer from "@mui/material/TableContainer";
import {ImportSampleAnalytes, readWorksheetAnalytes} from "../../lib/importXLS";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import {useEasySnackbar} from "../../lib/snackbar";
import {useAuthenticated} from "../../lib/auth";
import {useFormatAnalyte} from "../../lib/analytes";
import {RENDER_BASE} from "../../lib/assets";
import HTMLTitle from "../../components/HTMLTitle";

function TabPanel(props: { children?: React.ReactNode; index: any; value: any; }) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function TargetRowsPicker({
                            settings,
                            setImport
                          }: { settings: ApplicationSettingsFragment, setImport: (data?: ImportSampleAnalytes) => void }) {
  const [file, setFile] = useState<File>();
  const [tab, setTab] = useState(0);
  const [testType, setTestType] = useState<SampleTestType>(SampleTestType.PlantTissue);

  const handleFileChange = useEventCallback((file: File) => {
    setFile(file);
    const extension = file.name.toLowerCase().split('.').pop() || ""
    if (['xlsx', 'xls'].includes(extension)) {
      readWorksheetAnalytes("import values", file, settings, testType, (sheet, fileName, fileData) => setImport({
        testType,
        sheet,
        fileData,
        fileName
      }))
    }
  })

  return <Grid item>
    <Tabs onChange={(e, value) => {setTab(value); setImport()}} value={tab}>
      <Tab label={"Upload Results"} value={0}/>
      <Tab label={"Past Uploads"} value={1}/>
    </Tabs>
    <TabPanel value={tab} index={0}>
      <Card sx={{mb: 2}}>
        <CardHeader title={"Pick Results File"} subheader={file?.name}/>
        <CardContent>
          <Grid container alignContent="center" spacing={2}>
            <Grid item>
              <TextField
                style={{minWidth: 200}}
                label={"Test Type"}
                value={testType}
                onChange={(e) => {
                  setTestType(e.target.value as SampleTestType);
                  setImport()
                }}
                select
                variant={"outlined"}
              >
                {Object.entries(ProductLabels).map(([key, value]) => <MenuItem key={key} value={key}><HTMLTitle value={value} /></MenuItem>)}
              </TextField>
              <Box sx={{marginTop: "0.5em"}}>
                <a
                  href={RENDER_BASE + `api/render/template-${testType}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{color: "#888"}}
                >
                  <Grid container>
                    <Grid item>
                      <Download/>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                      >
                        Download {testType} Template
                      </Typography>
                    </Grid>
                  </Grid>
                </a>
              </Box>
            </Grid>
            <Grid item style={{marginTop: "0.5em"}}>
              <Button variant="contained" component="label">
                OPEN FILE
                <input hidden accept="*" type="file"
                       onChange={(e) => e.target.files?.length && handleFileChange(e.target.files[0])}/>
              </Button>
              {file ? <IconButton onClick={() => {
                setFile(undefined);
                setImport()
              }}><Cancel/></IconButton> : null}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              Expected Columns: {settings.testAnalytes.find(t => t.testType === testType)?.analytes.join(", ")}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </TabPanel>
    <TabPanel value={tab} index={1}>
      <PastResults/>
    </TabPanel>
  </Grid>
}

function PastResults() {
  const pastUploads = useGetTestResultImportsQuery()
  return <Card>
    <CardHeader title={"Past Uploads"}/>
    <CardContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File</TableCell>
              <TableCell>Test Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pastUploads?.data?.getTestResultImports?.map((result) => <TableRow key={result.id}>
              <TableCell>{result.filename}</TableCell>
              <TableCell>{result.testType}</TableCell>
              <TableCell>{result.createdAt}</TableCell>
              <TableCell>{result.importedBy?.firstName} {result.importedBy?.lastName}</TableCell>
              <TableCell><IconButton onClick={() => window.open(result.fileUrl)}><Download /></IconButton></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  </Card>
}

function ImportSheetDisplay(
  {
    importData,
  }
    :
    {
      settings: ApplicationSettingsFragment, importData
        :
        ImportSampleAnalytes, testType
        :
        SampleTestType
    }
) {
  const [showIgnored, setShowIgnored] = useState(false);
  const [imported, setImported] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [updateResults] = useUpdateResultsMutation()
  const formatAnalyte = useFormatAnalyte()
  const snackbar = useEasySnackbar()

  const handleImport = useEventCallback(async () => {
    try {
      const results = await updateResults({
        variables: {
          input: {
            testType: importData.testType,
            samples: importData.sheet.rows.map((row) => ({
              id: row.sampleId,
              analytes: Object.entries(row.importData).map(([key, value]) => ({
                analyteId: key,
                value: value ?? "",
              }))
            })),
            fileData: importData.fileData || "",
            fileName: importData.fileName || "",
          }
        },
      })
      if (results.data?.updateResults?.updated.length) {
        snackbar.showSuccess(`${results.data?.updateResults?.updated.length} rows imported`)
        setImported(results.data?.updateResults?.updated.map((result) => result.id))
      } else {
        snackbar.showSnackbar("No rows imported", {variant: "error"})
      }
      if (results.data?.updateResults?.errors.length) {
        snackbar.showSnackbar(`${results.data?.updateResults?.errors.length} rows did not import`)
        setErrors(results.data?.updateResults?.errors || [])
      }
    } catch (e: any) {
      snackbar.showError(e, "Error importing results")
    }
  })

  return <Card sx={{width: "100%"}}>
    <CardHeader subheader={importData.sheet.name} action={<Button
      color="primary"
      variant="contained"
      onClick={handleImport}
    >
      Save Import
    </Button>}/>
    <CardContent sx={{width: "100%"}}>
      {importData.sheet.ignoredColumns.length ? <FormControlLabel control={
        <Checkbox checked={showIgnored} onChange={(e) => setShowIgnored(e.target.checked)}/>
      } label={`Show ignored columns (${importData.sheet.ignoredColumns.length})`}/> : null}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Sample ID</TableCell>
              {importData.sheet.analyteColumns.map(column => <TableCell key={column.key}>
                <Tooltip title={`From column ${column.key}`}>
                  <Typography variant="body1" fontSize="smaller">
                    <b>
                      <HTMLTitle value={column.analyte?.label || ""} />
                    </b>
                  </Typography>
                </Tooltip>
              </TableCell>)}
              {showIgnored ? importData.sheet.ignoredColumns.map(column => <TableCell
                key={column.key}>
                <Typography variant="body1" fontSize="smaller">
                  {column.label}
                </Typography>
              </TableCell>) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {importData.sheet.rows.map((row, idx) => <TableRow key={idx}>
              <TableCell>
                {row.sampleId}
              </TableCell>
              <TableCell>
                <Fade in={imported.includes(row.sampleId)}>
                  <Check color="primary"/>
                </Fade>
                <Fade in={Boolean(errors.includes(row.sampleId))}>
                  <Tooltip title={"Row Not Found"}>
                    <Cancel color="error"/>
                  </Tooltip>
                </Fade>
              </TableCell>
              {importData.sheet.analyteColumns.map(column => {
                return <TableCell key={column.key}>
                  <Typography variant="body1" fontSize="smaller">
                    {column.analyte?.id && formatAnalyte(column.analyte.id, parseFloat(row.cellData[column.key]))}
                  </Typography>
                </TableCell>
              })}
              {showIgnored ? importData.sheet.ignoredColumns.map(column => <TableCell
                key={column.key}>{row.cellData[column.key]}</TableCell>) : null}
            </TableRow>)}
          </TableBody>
        </Table>

      </TableContainer>
    </CardContent>
  </Card>
}

function LabUploadResults(
  {
    labId,
    settings
  }
    :
    {
      labId: string, settings
        :
        ApplicationSettingsFragment
    }
) {
  const [data, setData] = useState<ImportSampleAnalytes>()


  return <Grid container direction="column" spacing={2}>
    <TargetRowsPicker settings={settings} setImport={setData}/>
    <Grid container item>
      <Grid item xs={12}>
        {data ?
          <ImportSheetDisplay settings={settings} importData={data} testType={data.testType}/>
          : null}
      </Grid>
    </Grid>
  </Grid>;
}


export default function LabDetails() {
  const {id} = useParams();
  const {lab} = useAuthenticated()
  const applicationSettings = useGetApplicationSettingsQuery()?.data?.getApplicationSettings;
  const labId = id || lab?.id
  const ready = labId && !!applicationSettings;

  return <FullPageLayout>
    {ready ? <LabUploadResults labId={labId} settings={applicationSettings}/> : <Loading/>}
  </FullPageLayout>;
}
