export const SAMPLE_TEST_PRICE_IN_CENTS = 7500;

export const ProductLabels = {
  PLANT_TISSUE: "Plant Tissue",
  SOIL: "Soil",
  FERTILIZER: "Fertilizer",
  WATER: "Water",
  RUN_OFF: "Run Off",
  NUTRIENT: "Nutrient",
  PYTHIUM_SPP: "<i>Pythium spp.</i>",
  FUSARIUM_SPP: "<i>Fusarium spp.</i>",
  HOP_LATENT_VIROID: "Hop Latent Viroid",
  HEAVY_METAL: "Heavy Metal",
  
};

export function formatPrice(num: number) {
  return `$${(num / 100).toFixed(2)}`;
}
