import { FC, useState } from "react";

import { LaboratoryFieldsFragment } from "../../generated/graphql";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import EditHealthProfileDialog from "./EditHealthProfileDialog";
import { ProductLabels } from "../../lib/testTypes";
import { get } from "lodash";

type HealthProfileTableProps = {
  lab: LaboratoryFieldsFragment;
  showArchived: Boolean;
};

const stageOptions = [
  { value: "", label: "-" },
  { value: "MOTHER", label: "Mother" },
  { value: "CLONE", label: "Clone" },
  { value: "VEG", label: "Veg" },
  { value: "FLOWER", label: "Flower" },
  { value: "LATE_FLOWER", label: "Late Flower" },
  { value: "DRYING", label: "Drying" }
];

const stageLabels: Record<string, string> = stageOptions.reduce(
  (acc: Record<string, string>, option: { value: string, label: string }) => {
    acc[option.value] = option.label;
    return acc;
  }, {});

export const HealthProfileTable: FC<HealthProfileTableProps> = ({
  lab,
  showArchived,
}) => {

  const activeProfiles =
    lab.healthProfiles &&
    lab.healthProfiles.filter((profile) => !profile?.archivedAt);

  const inactiveProfiles =
    lab.healthProfiles &&
    lab.healthProfiles.filter((profile) => profile?.archivedAt);

  //const [archive] = useArchiveHealthProfileMutation({ refetchQueries: [{ query: LaboratoryFieldsFragmentDoc }] })
  //const [unarchive] = useUnarchiveHealthProfileMutation({ refetchQueries: [{ query: LaboratoryFieldsFragmentDoc }] })

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  {/*const handleArchiveToggle = async (profile: HealthProfileFragment) => {
    try {
      if (profile.archivedAt) {
        await unarchive({ variables: { id: profile.id } });
      } else {
        await archive({ variables: { id: profile.id } });
      }
    } catch (error) {
      console.error('Error toggling archive state:', error);
    }
  }; */}

  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Test Type</TableCell>
            <TableCell>Stage</TableCell>
            <TableCell>Cultivar</TableCell>
            {/* <TableCell>Archive Status</TableCell> */}
            {!showArchived ? <TableCell>Edit</TableCell> : null}
            {!showArchived ? <TableCell>Duplicate</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {showArchived
            ? inactiveProfiles
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                (profile) =>
                  profile && (
                    <TableRow key={profile.id}>
                      <TableCell>{profile.title}</TableCell>
                      <TableCell>{profile.testType}</TableCell>
                      <TableCell>{stageLabels[profile.stage as string]}</TableCell>
                      <TableCell>{profile.cultivar}</TableCell>
                      {/*<TableCell>
                        <IconButton onClick={() => handleArchiveToggle(profile)}>
                          <Unarchive />
                        </IconButton>
                  </TableCell> */}
                    </TableRow>
                  )
              )
            : activeProfiles
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                (profile) =>
                  profile && (
                    <TableRow key={profile.id}>
                      <TableCell>{profile.title}</TableCell>
                      <TableCell>{get(ProductLabels, profile.testType)}</TableCell>
                      <TableCell>{stageLabels[profile.stage as string]}</TableCell>
                      <TableCell>{profile.cultivar}</TableCell>
                      {/*<TableCell>
                        <IconButton onClick={() => handleArchiveToggle(profile)}>
                          <Archive />
                        </IconButton>
                  </TableCell> */}

                      <TableCell>
                        <EditHealthProfileDialog
                          existingHealthProfile={profile}
                        />

                      </TableCell>
                      <TableCell>
                        <EditHealthProfileDialog
                          existingHealthProfile={profile}
                          duplicate
                        />

                      </TableCell>
                    </TableRow>
                  )
              )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={
          (showArchived ? inactiveProfiles?.length : activeProfiles?.length) || 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
