import { FC } from "react";
import { useForm } from "react-hook-form";
import {
  LaboratoryFieldsFragment,
  LabTestPricesFragment,
  useUpdateLaboratoryMutation,
} from "../generated/graphql";
import { useEasySnackbar } from "../lib/snackbar";
import { get } from "lodash";

import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import HTMLTitle from "./HTMLTitle";

const PlainCell = styled(TableCell)({
  border: "none",
});

type TestProps = {
  lab: LaboratoryFieldsFragment | undefined;
  showDeactivated?: boolean;
};

type ProductPricing = {
  field: keyof LabTestPricesFragment;
  label: string;
};

const PRODUCT_PRICINGS: ProductPricing[] = [
  { field: "priceTestPlantTissue", label: "Plant Tissue Test" },
  { field: "priceTestSoil", label: "Soil Test" },
  { field: "priceTestFertilizer", label: "Fertilizer Test" },
  { field: "priceTestWater", label: "Water Test" },
  { field: "priceTestRunOff", label: "Runoff Test" },
  { field: "priceTestNutrient", label: "Nutrient Test" },
  { field: "priceTestPythiumSpp", label: "<i>Pythium spp.</i> Test" },
  { field: "priceTestHopLatentViroid", label: "Hop Latent Viroid Test" },
  { field: "priceTestHeavyMetal", label: "Heavy Metal Test" },
  { field: "priceTestFusariumSpp", label: "<i>Fusarium spp.</i> Test" },
];

export const TestSettingsTable: FC<TestProps> = ({ lab, showDeactivated }) => {
  const { register, handleSubmit, watch, setValue } =
    useForm<LaboratoryFieldsFragment>({
      defaultValues: {
        ...PRODUCT_PRICINGS.reduce(
          (acc, { field }) => ({
            ...acc,
            [field]: ((get(lab, field) as number) || 0) / 100,
          }),
          {}
        ),
      },
    });
  const [updateLaboratory] = useUpdateLaboratoryMutation();
  const snackbar = useEasySnackbar();

  const _handleSubmit = async (input: LaboratoryFieldsFragment) => {
    try {
      await updateLaboratory({
        variables: {
          input: {
            id: lab?.id,
            ...PRODUCT_PRICINGS.reduce(
              (acc, { field }) => ({
                ...acc,
                [field]: Math.round(((get(input, field) as number) || 0) * 100),
              }),
              {}
            ),
          },
        },
      });
      snackbar.showSuccess("Lab was successfully updated");
    } catch (e) {
      snackbar.showError(e, "There was an error updating this lab");
    }
  };

  const values = watch();

  return (
    <TableContainer>
      <form onSubmit={handleSubmit(_handleSubmit)} noValidate>
        <Table size="small">
          <TableBody>
            {PRODUCT_PRICINGS.filter(
              (product) => showDeactivated || get(values, product.field)
            ).map((product) => (
              <TableRow key={product.field}>
                <PlainCell>
                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <Typography variant="body1">
                    <HTMLTitle value={product.label} />
                  </Typography>
                </PlainCell>
                <PlainCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!get(
                            values,
                            product.field as keyof LaboratoryFieldsFragment
                          )
                        }
                      />
                    }
                    onChange={(e, checked) =>
                      setValue(
                        product.field as keyof LaboratoryFieldsFragment,
                        checked ? 50 : 0
                      )
                    }
                    label="Active?"
                  />
                </PlainCell>
                <PlainCell>
                  <TextField
                    size="small"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    {...register(
                      product.field as keyof LaboratoryFieldsFragment
                    )}
                  />
                </PlainCell>
              </TableRow>
            ))}
            <TableRow>
              <PlainCell />
              <PlainCell />
              <PlainCell>
                <Grid container justifyContent={"flex-end"}>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      type="submit"
                    >
                      Save Price Changes
                    </Button>
                  </Grid>
                </Grid>
              </PlainCell>
            </TableRow>
          </TableBody>
        </Table>
      </form>
    </TableContainer>
  );
};
