import { FC } from "react";
import { get } from "lodash";
import { UseFormReturn } from "react-hook-form";

import { CouponCodeFieldsFragment, CouponCodeKind } from "../../generated/graphql";

// mui
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";

type CouponFormProps = {
  formMethods: UseFormReturn<CouponCodeFieldsFragment>;
};

export const CouponForm: FC<CouponFormProps> = ({ formMethods }) => {
  const { register } = formMethods;

  return (
    <DialogContent>
      <TextField
        size="small"
        variant="outlined"
        label="Coupon Code"
        placeholder="FREETEST123"
        autoFocus
        InputLabelProps={{ shrink: true }}
        fullWidth
        required
        sx={{ mb: 2, mt: 1 }}
        {...register("code")}
      />
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            variant="outlined"
            label="Value"
            type="number"
            fullWidth
            required
            {...register("value", { valueAsNumber: true })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Kind"
            required
            select
            {...register("kind")}
            defaultValue={CouponCodeKind.Percent}
          >
            {Object.keys(CouponCodeKind).map((key) => (
              <MenuItem
                key={get(CouponCodeKind, key)}
                value={get(CouponCodeKind, key)}
              >
                {get(CouponCodeKind, key)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <FormControlLabel
        control={<Checkbox />}
        label="Discount applies to shipping costs"
        {...register("discountAppliesToShippingCosts")}
        sx={{ mb: 1 }}
      />
      <FormControlLabel
        control={<Checkbox />}
        label="Automatic coupon for the public"
        {...register("publicAutomatic")}
        sx={{ mb: 1 }}
      />

      <Grid container alignItems="center" spacing={1} mb={2}>
        <Grid item>
          <Typography variant="body1">Free shipping cost up to:</Typography>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
            {...register("freeShippingCostUpToPriceInCents", {
              valueAsNumber: true,
            })}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={1} mb={2}>
        <Grid item>
          <Typography variant="body1">Minimum Order Price:</Typography>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
            {...register("minimumOrderPriceInCents", {
              valueAsNumber: true,
            })}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={1} mb={2}>
        <Grid item>
          <Typography variant="body1">Maximum Orders Per User:</Typography>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            variant="outlined"
            type="number"
            fullWidth
            {...register("maxOrdersPerUser", { valueAsNumber: true })}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={1} mb={2}>
        <Grid item>
          <Typography variant="body1">Maximum Samples Per User:</Typography>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            variant="outlined"
            type="number"
            fullWidth
            {...register("maxSamplesPerUser", { valueAsNumber: true })}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={1} mb={2}>
        <Grid item>
          <Typography variant="body1">Valid Until:</Typography>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            variant="outlined"
            type="date"
            fullWidth
            {...register("validUntil")}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default CouponForm;
