import { FC } from "react";
import { useForm } from "react-hook-form";
import { useEasySnackbar } from "../lib/snackbar";

import {
  LaboratoryFieldsFragment,
  useUpdateLaboratoryMutation,
} from "../generated/graphql";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

type LabInfoProps = {
  lab: LaboratoryFieldsFragment | undefined;
};

export const CheckoutForm: FC<LabInfoProps> = ({ lab }) => {
  const { register, handleSubmit } = useForm<LaboratoryFieldsFragment>({
    defaultValues: {
      priceShippingUspsFirst:
        lab?.priceShippingUspsFirst && lab.priceShippingUspsFirst / 100,
      priceShippingFedexOvernight:
        lab?.priceShippingFedexOvernight &&
        lab.priceShippingFedexOvernight / 100,
    },
  });

  const [updateLaboratory] = useUpdateLaboratoryMutation();
  const snackbar = useEasySnackbar();

  const _handleSubmit = async (input: LaboratoryFieldsFragment) => {
    try {
      await updateLaboratory({
        variables: {
          input: {
            id: lab?.id,
            priceShippingUspsFirst:
              input.priceShippingUspsFirst &&
              Math.round(input.priceShippingUspsFirst * 100),
            priceShippingFedexOvernight:
              input.priceShippingFedexOvernight &&
              Math.round(input.priceShippingFedexOvernight * 100),
          },
        },
      });
      snackbar.showSuccess("Lab was successfully updated");
    } catch (e) {
      snackbar.showError(e, "There was an error updating this lab");
    }
  };
  return (
    <form onSubmit={handleSubmit(_handleSubmit)} noValidate>
      <Typography variant="h5" fontWeight={"bold"} mb={2}>
        Public Checkout
      </Typography>
      <Typography variant="body1">
        Public Web Store Checkout is{" "}
        <b>{lab?.checkoutEnabled ? "enabled" : "disabled"}</b>; for checkout and
        payments configuration, contact your account manager.
      </Typography>

      <Typography variant="h5" fontWeight={"bold"} mb={2} mt={2}>
        Shipping Options
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={"bold"}>
            USPS - First Class Shipping Price:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...register("priceShippingUspsFirst")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={"bold"}>
            FedEx - Overnight Shipping Price:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...register("priceShippingFedexOvernight")}
          />
        </Grid>
      </Grid>

      <Button
        color="primary"
        variant="contained"
        size="small"
        type="submit"
        sx={{ float: "right" }}
      >
        Save Checkout Settings
      </Button>
    </form>
  );
};
