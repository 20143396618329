import { useNavigate } from "react-router-dom";
import { useAuthenticated } from "../lib/auth";

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Payment from "@mui/icons-material/Payment";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// types
import {
  OrderPaymentStatus,
  OrderQuerysetOptionsInput,
  OrderSummaryFieldsFragment,
  useGetOrdersQuery,
} from "../generated/graphql";
import { imgURL } from "../lib/assets";

//components
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { ServiceHero } from "../components/ServiceHero";
import { Loading } from "../components/Loading";
import OrdersTableCard from "../components/OrdersTableCard";
import { useBackendTable } from "../components/DataTable/types";
import { Typography } from "@mui/material";
import { formatPrice } from "../lib/testTypes";

export const HomePage = () => {
  const auth = useAuthenticated();
  const backendTable = useBackendTable<OrderQuerysetOptionsInput>({
    filters: {
      laboratoryId: auth.lab?.id as string,
      clientCompanyId: auth.profile?.clientCompany?.id,
    },
  });
  const ordersQuery = useGetOrdersQuery({
    variables: { options: backendTable.options },
  });
  const unpaidQuery = useGetOrdersQuery({
    variables: {
      options: { paging: { size: 100 }, filters: { unpaid: "true" } },
    },
  });
  const unpaidList = (unpaidQuery?.data?.getOrders?.items ||
    []) as OrderSummaryFieldsFragment[];
  const totalUnpaid =
    unpaidQuery?.data?.getOrders?.options?.paging?.totalCount || 0;

  const navigate = useNavigate();

  const orders = ordersQuery.data;

  if (auth.loading) return <Loading />;
  else if (auth.profile?.email) {
    if (auth.profile.staffAtLaboratory) navigate(`/lab/manage-orders`);
    return (
      <FullPageLayout>
        <Box>
          {unpaidList.length &&
          auth.lab?.checkoutEnabled ? (
            <>
              <Typography variant="h5">
                <b>You have {totalUnpaid} unpaid orders!</b>
              </Typography>
              <Typography variant="subtitle2" sx={{ paddingBottom: "0.5em" }}>
                Please click the credit card icon to complete payment for
                your order.
              </Typography>

              {unpaidList.map((item) => (
                <Alert
                  key={item?.id}
                  severity="error"
                  sx={{ marginBottom: "1em" }}
                  onClick={() => (item ? window.open("/pay/" + item.id) : null)}
                  action={
                    <Tooltip title="Pay Now" arrow>
                      <IconButton>
                        <Payment />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <b>
                    Payment of {formatPrice(item.totalPriceInCents)} owed for
                    Order {item.id}!
                  </b>
                  {"  "}
                  Order date {item?.orderDate}.
                </Alert>
              ))}
            </>
          ) : null}
          <OrdersTableCard data={orders} backendTable={backendTable} />
        </Box>
      </FullPageLayout>
    );
  } else {
    navigate(`/login`);
    return <Loading />;
  }
};

export default HomePage;

const styles = {
  paperContainer: {
    backgroundImage: `url("${imgURL("/soilLabsLandingBackground1.svg")}")`,
    backgroundSize: "cover",
    width: "100%",
  },
};

export const LandingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <FullPageLayout>
      <Grid container justifyContent="center" margin={"2em 0"}>
        <Grid item xs={12} md={11}>
          <Grid
            container
            alignItems={"center"}
            spacing={4}
            justifyContent="center"
          >
            <Grid item md={4}>
              <img
                src={imgURL("/Soil-Lab-Logo-1.png")}
                style={{ maxWidth: "100%", maxHeight: "600px" }}
                alt="logo"
              />
            </Grid>
            <Grid item md={8}>
              <Grid container direction={"column"}>
                <h1
                  style={
                    smallScreen
                      ? {
                          fontSize: "40px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }
                      : { fontSize: "61px", fontWeight: "bold" }
                  }
                >
                  Optimize Growing Conditions to Improve your Final Product.
                </h1>
              </Grid>
              <Grid item>
                {smallScreen ? (
                  <Grid container justifyContent={"center"}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={
                          smallScreen
                            ? {
                                fontSize: "14px",
                                padding: "0.5em 1em",
                              }
                            : {
                                fontSize: "20px",
                                padding: "0.5em 1em",
                              }
                        }
                        onClick={() => navigate("/register")}
                      >
                        Register Now
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={
                      smallScreen
                        ? {
                            fontSize: "14px",
                            padding: "0.5em 1em",
                          }
                        : {
                            fontSize: "20px",
                            padding: "0.5em 1em",
                          }
                    }
                    onClick={() => navigate("/register")}
                  >
                    Register Now
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container margin={"0 0 5em"}>
        <Paper style={styles.paperContainer}>
          <ServiceHero />
        </Paper>
      </Grid>
    </FullPageLayout>
  );
};
