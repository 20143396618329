import { FC, useState } from "react";
import EditCouponCodeDialog from "./EditCouponCodeDialog";
import { formatPrice } from "../../lib/testTypes";

import {
  LaboratoryFieldsFragment,
  useGetCouponCodesQuery,
} from "../../generated/graphql";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";

type CouponCodeProps = {
  lab: LaboratoryFieldsFragment;
  showInactive: Boolean;
};

export const CouponCodesTable: FC<CouponCodeProps> = ({
  lab,
  showInactive,
}) => {
  const { data } = useGetCouponCodesQuery({
    variables: {
      filters: {
        laboratoryId: lab?.id,
      },
    },
  });

  const activeCodes =
    data?.getCouponCodes &&
    data.getCouponCodes.filter((code) => code?.enabled === true);

  const inactiveCodes =
    data?.getCouponCodes &&
    data.getCouponCodes.filter((code) => code?.enabled === false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Kind</TableCell>
            <TableCell>Claimed</TableCell>
            <TableCell>Min Order Price</TableCell>
            <TableCell>Max Orders Per User</TableCell>
            <TableCell>Max Samples Per User</TableCell>
            <TableCell>Free Shipping Cost Up To</TableCell>
            <TableCell>Applies to Shipping</TableCell>
            <TableCell>Public Automatic</TableCell>
            <TableCell>Valid Until</TableCell>
            {!showInactive ? <TableCell>Edit</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {showInactive
            ? inactiveCodes
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (code) =>
                    code && (
                      <TableRow key={code.id}>
                        <TableCell>{code.code}</TableCell>

                        <TableCell>{code.value}</TableCell>
                        <TableCell>{code.kind}</TableCell>
                        <TableCell>
                          {code.claimedCount && code.claimedCount > 0
                            ? code.claimedCount
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {code.minimumOrderPriceInCents &&
                            formatPrice(code.minimumOrderPriceInCents)}
                        </TableCell>
                        <TableCell>{code.maxOrdersPerUser}</TableCell>
                        <TableCell>{code.maxSamplesPerUser}</TableCell>
                        <TableCell>
                          {code.freeShippingCostUpToPriceInCents &&
                            formatPrice(code.freeShippingCostUpToPriceInCents)}
                        </TableCell>
                        <TableCell>
                          {code.discountAppliesToShippingCosts === true
                            ? "Yes"
                            : "No"}
                        </TableCell>
                        <TableCell>
                          {code.publicAutomatic === true ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>{code.validUntil}</TableCell>
                      </TableRow>
                    )
                )
            : activeCodes
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (code) =>
                    code && (
                      <TableRow key={code.id}>
                        <TableCell>{code.code}</TableCell>

                        <TableCell>{code.value}</TableCell>
                        <TableCell>{code.kind}</TableCell>
                        <TableCell>
                          {code.claimedCount && code.claimedCount > 0
                            ? code.claimedCount
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {code.minimumOrderPriceInCents &&
                            formatPrice(code.minimumOrderPriceInCents)}
                        </TableCell>
                        <TableCell>{code.maxOrdersPerUser}</TableCell>
                        <TableCell>{code.maxSamplesPerUser}</TableCell>
                        <TableCell>
                          {code.freeShippingCostUpToPriceInCents &&
                            formatPrice(code.freeShippingCostUpToPriceInCents)}
                        </TableCell>
                        <TableCell>
                          {code.discountAppliesToShippingCosts === true
                            ? "Yes"
                            : "No"}
                        </TableCell>
                        <TableCell>
                          {code.publicAutomatic === true ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>{code.validUntil}</TableCell>
                        <TableCell>
                          <EditCouponCodeDialog
                            existingCoupon={code}
                            lab={lab}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={
          (showInactive ? inactiveCodes?.length : activeCodes?.length) || 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};
