import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CreateLabUserInput,
  GetLaboratoryDocument,
  LaboratoryWithUsersFieldsFragment,
  useCreateLabUserMutation,
  useDeleteLabUserMutation,
} from "../generated/graphql";
import { useEasySnackbar } from "../lib/snackbar";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UsersTable from "./UsersTable";

type LabUsersTableProps = {
  lab: LaboratoryWithUsersFieldsFragment;
};

export const LabUsersTable: FC<LabUsersTableProps> = ({ lab }) => {
  const snackbar = useEasySnackbar();
  const users = lab?.staffUsers;

  const [deleteUser] = useDeleteLabUserMutation({
    refetchQueries: [
      { query: GetLaboratoryDocument, variables: { id: lab.id } },
    ],
  });

  const tryDeleteUser = async (id: string) => {
    try {
      await deleteUser({
        variables: {
          input: { id },
        },
      });
      snackbar.showSuccess("User Deleted");
    } catch (err) {
      console.error(err);
      snackbar.showError(err, "Error Deleting User");
    }
  };

  return (
    <Grid item xs={12} lg={6}>
      <Card sx={{ p: 3 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight={"bold"}>
              {lab?.businessName} Staff
            </Typography>
          </Grid>
          <Grid item>
            <CreateLabUserDialog lab={lab} />
          </Grid>
        </Grid>
        <UsersTable users={users} deleteUser={tryDeleteUser} />
      </Card>
    </Grid>
  );
};

export const CreateLabUserDialog: FC<LabUsersTableProps> = ({ lab }) => {
  const [open, setOpen] = useState(false);
  const snackbar = useEasySnackbar();

  const formMethods = useForm<CreateLabUserInput>();
  const { register, handleSubmit } = formMethods;

  const [createLabUser] = useCreateLabUserMutation({
    refetchQueries: [
      {
        query: GetLaboratoryDocument,
        variables: {
          id: lab.id,
        },
      },
    ],
  });

  const _handleSubmit = async (input: CreateLabUserInput) => {
    try {
      await createLabUser({
        variables: {
          input: {
            ...input,
            staffAtLaboratoryId: lab.id,
          },
        },
      });
      setOpen(false);
      snackbar.showSuccess("User was successfully created");
    } catch (e) {
      snackbar.showError(e, "There was an error creating this User");
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Create User
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form
          onSubmit={handleSubmit(_handleSubmit)}
          noValidate
          style={{ marginTop: "1em" }}
        >
          <DialogTitle>Create User for {lab.businessName}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              <b>This user is lab staff.</b> Once the user is created, they will
              need an invitation to set up their password.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="First Name"
                  fullWidth
                  variant="outlined"
                  required
                  {...register("firstName")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  label="Last Name"
                  fullWidth
                  variant="outlined"
                  required
                  {...register("lastName")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  required
                  {...register("email")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button variant="contained" type="submit">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
