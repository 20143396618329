import React, { FC } from "react";
import { useVisibleTrackingColumns } from "../lib/trackingColumns";
import { useEasySnackbar } from "../lib/snackbar";

// mui
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// types
import {
  ClientCompanyFieldsFragment,
  MyProfileDocument,
  MyProfileQuery,
  useUpdateTrackingColumnMutation,
} from "../generated/graphql";

type TrackingColumnDialogProps = {
  company: ClientCompanyFieldsFragment;
};

export const TrackingColumnsCard: FC<TrackingColumnDialogProps> = ({
  company,
}) => {
  const trackingColumns = useVisibleTrackingColumns();
  const snackbar = useEasySnackbar();

  const [updateTrackingColumn] = useUpdateTrackingColumnMutation({
    update(cache, { data }) {
      if (!updateTrackingColumn) return;
      const currentData: MyProfileQuery | null = cache.readQuery({
        query: MyProfileDocument,
      });
      cache.writeQuery({
        query: MyProfileDocument,
        data: {
          ...currentData,
          myProfile: data?.updateTrackingColumn?.user || currentData?.myProfile,
        },
      });
    },
  });

  const handleChange = async (name: string, enabled: boolean) => {
    try {
      await updateTrackingColumn({
        variables: {
          name,
          enabled,
        },
      });
      snackbar.showSuccess(
        enabled
          ? `${name} is now enabled as a tracking column.`
          : `${name} is now disabled as a tracking column.`
      );
    } catch (e) {
      snackbar.showError(e, "There was an error updating this lab");
    }
  };

  const TrackingSwitch = ({
    name,
    label,
    after,
  }: {
    name: string;
    label: string;
    after?: React.ReactNode;
  }) => (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={trackingColumns?.includes(name)}
            onChange={(e) => handleChange(name, e.target.checked)}
            name={name}
          />
        }
        label={
          <Typography variant="body1" sx={{ fontSize: 18 }}>
            {label}
          </Typography>
        }
      />
      <Typography variant="body2" sx={{ marginBottom: "1em" }}>
        {after}
      </Typography>
    </>
  );

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h5" fontWeight={"bold"}>
        Tracking Columns
      </Typography>
      <Typography variant="body2">
        Choose tracking columns to include in your data. These are global
        settings and will change the columns and analytics you see throughout
        the app!
      </Typography>
      <Grid container>
        <Grid item>
          <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
            <FormGroup>
              <TrackingSwitch
                name="cultivar"
                label="Strain (Cultivar)"
                after={
                  <>
                    <b>Strongly Recommended:</b> Tracking cultivar allows access
                    to more analytics features.
                  </>
                }
              />

              <TrackingSwitch
                name="stage"
                label="Stage"
                after={
                  <>
                    <b>Strongly Recommended:</b> Tracking stages allows access
                    to more analytics features.
                  </>
                }
              />

              <TrackingSwitch
                name="daysInStage"
                label="Days In Stage"
                after={
                  <>
                    <b>Recommended:</b> Enables extended reporting on health
                    within each stage of the growth process
                  </>
                }
              />
              <TrackingSwitch
                name="label"
                label="Label / Notes"
                after={
                  <>
                    <b>Optional:</b> This column can be helpful to diferentiate
                    between similar tests by leaving notes, or a nickname for
                    this test.
                  </>
                }
              />

              <TrackingSwitch
                name="location"
                label="Location"
                after={
                  <>
                    <b>Optional:</b> Track growth locations to see analytics by
                    growth site. Ex: Field 1, Indoor, Basement
                  </>
                }
              />

              <TrackingSwitch
                name="harvestGroup"
                label="Harvest Group"
                after={
                  <>
                    <b>Optional:</b> Track similar strains across multiple
                    harvests over time by the name of the harvest.
                  </>
                }
              />

              <TrackingSwitch
                name="group"
                label="Custom Grouping Code"
                after={
                  <>
                    <b>Optional:</b> An extra column for analytics grouping. Ex:
                    "Grown By Jim" vs "Grown By Jane", or "Seed Vendor A" vs
                    "Seed Vendor B"
                  </>
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TrackingColumnsCard;
