export type OrderStatusProps = {
  name: string;
  label: string;
  description: string;
};
export const OrderProcessStatuses: OrderStatusProps[] = [
  {
    name: "",
    label: "Unknown (error)",
    description: "An unknown shipping update was received, please contact support",
  },
  {
    name: "UNFULFILLED",
    label: "Unfulfilled",
    description: "Ordered, unfulfilled",
  },
  {
    name: "PACKAGED",
    label: "Packaged",
    description: "Packaged, awaiting pickup",
  },
  {
    name: "SENDING_TO_BUYER",
    label: "Sending Tests",
    description: "In Transit To Buyer",
  },
  {
    name: "AT_SITE",
    label: "At Site",
    description: "At customer site, awaiting return",
  },
  {
    name: "RETURNING_TO_LAB",
    label: "Returning Tests",
    description: "Samples in-transit to Lab",
  },
  {
    name: "AT_LAB",
    label: "At Lab",
    description: "Received at lab, pending testing",
  },
  {
    name: "IN_PROGRESS",
    label: "In Progress",
    description: "Order In Progress",
  },
  {
    name: "COMPLETE",
    label: "Complete",
    description: "Testing Complete, Results Available",
  },
  {name: "CANCELED", label: "Canceled", description: "Voided or canceled"},
];
export const OrderPaymentStatuses: OrderStatusProps[] = [
  {
    name: "UNPAID",
    label: "Unpaid",
    description: "Unpaid, payment is due",
  },
  {
    name: "PAID",
    label: "Paid",
    description: "Payment Complete",
  },
  {
    name: "A_",
    label: "Unknown Error",
    description: "Unknown error occurred in payment, contact support",
  },
  {
    name: "CHECKOUT_INCOMPLETE",
    label: "Checkout Incomplete",
    description: "Customer did not complete checkout, or abandoned cart",
  },
  {
    name: "VOIDED",
    label: "Voided",
    description: "Voided order before payment was received",
  }
];
