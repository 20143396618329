import { useEffect } from "react";
import { ParamKeyValuePair, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import "react-datepicker/dist/react-datepicker.css";
import { ProductLabels } from "../lib/testTypes";
import { TestsExcludedFromAnalytics } from "../lib/analytes";
import HTMLTitle from "./HTMLTitle";
import { useProfile } from "./Auth/ProfileProvider";

// types
import { SampleFiltersCommon, STAGE_OPTIONS } from "../types/types";
import { useTrackingColumnsHistoriesQuery } from "../generated/graphql";

// mui
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

export const useSampleFilterVariables = (): [
  SampleFiltersCommon,
  CallableFunction,
  CallableFunction
] => {
  const [params, setParams] = useSearchParams();

  const variables = Object.fromEntries(new URLSearchParams(params));

  function addParams({
    filters,
    ...newParams
  }: Partial<typeof variables> & { filters?: Partial<typeof variables> }) {
    const combined = {
      ...variables,
      ...newParams,
      ...filters,
    };
    const newVars = Object.keys(combined)
      .filter((key) => combined[key] !== undefined)
      .map((key) => [key, combined[key]] as ParamKeyValuePair);
    setParams(newVars);
  }

  return [variables, addParams, setParams];
};

export const SampleFilterCard = ({ analytics }: { analytics?: boolean }) => {
  const { profile } = useProfile();
  const historiesQuery = useTrackingColumnsHistoriesQuery();

  const historiesList =
    historiesQuery.data?.myProfile?.trackingColumnsHistories || [];

  const [variables, addParams] = useSampleFilterVariables();

  const harvestGroupHistories = historiesList.filter(
    (history) => history?.name === "harvestGroup"
  )[0]?.history;

  const cultivarHistories = historiesList.filter(
    (history) => history?.name === "cultivar"
  )[0]?.history;

  const locationHistories = historiesList.filter(
    (history) => history?.name === "location"
  )[0]?.history;

  const groupHistories = historiesList.filter(
    (history) => history?.name === "group"
  )[0]?.history;

  useEffect(() => {
    if (!variables.testType)
      addParams({
        testType: "PLANT_TISSUE",
      });
  }, [variables.testType, addParams]);

  const endDate = variables.endDate
    ? DateTime.fromISO(variables.endDate).toJSDate()
    : profile?.mostRecentSampleUpdates
    ? DateTime.fromISO(profile?.mostRecentSampleUpdates).toJSDate()
    : new Date();
  const startDate = variables.startDate
    ? DateTime.fromISO(variables.startDate).toJSDate()
    : endDate
    ? endDate
    : new Date();

  return (
    <Card sx={{ padding: "1em", marginBottom: "1em" }}>
      <Typography variant="h5" mb={2}>
        Filters
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FormControl>
            <TextField
              aria-labelledby="test-type-label"
              label="Test Type"
              value={variables.testType}
              sx={{ width: 200 }}
              select
              onChange={(e) =>
                addParams({
                  testType: e.target.value || undefined,
                })
              }
            >
              {Object.entries(ProductLabels).map(([key, label]) =>
                !analytics || !TestsExcludedFromAnalytics.includes(label) ? (
                  <MenuItem key={key} value={key}>
                    <HTMLTitle value={label} />
                  </MenuItem>
                ) : null
              )}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item>
          <Autocomplete
            value={variables.stage}
            autoHighlight
            id="stage-filter"
            options={STAGE_OPTIONS.map((item) => item.value)}
            getOptionLabel={(option) => {
              const found = STAGE_OPTIONS.find((item) => item.value === option);
              return found?.label || found?.value || "";
            }}
            onChange={(e, newValue) =>
              addParams({ stage: newValue || undefined })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Stage"
                fullWidth
                sx={{ width: 200 }}
              />
            )}
          />
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body2">Start Date:</Typography>
              <DatePicker
                selected={startDate}
                onChange={(d) =>
                  addParams({
                    startDate: d && DateTime.fromJSDate(d).toISODate(),
                  })
                }
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item>
            <Typography variant="body2">End Date:</Typography>
              <DatePicker
                selected={endDate}
                onChange={(d) =>
                  addParams({
                    endDate: d && DateTime.fromJSDate(d).toISODate(),
                  })
                }
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </Grid>
          </Grid>
        </Grid>
        
        {harvestGroupHistories?.length ? (
          <Grid item>
            <Autocomplete
              value={variables.harvestGroup}
              autoHighlight
              id="harvest-group-filter"
              options={harvestGroupHistories}
              onChange={(e, newValue) =>
                addParams({
                  harvestGroup: newValue || undefined,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Harvest Group"
                  fullWidth
                  sx={{ minWidth: "200px" }}
                />
              )}
            />
          </Grid>
        ) : null}

        {cultivarHistories?.length ? (
          <Grid item>
            <Autocomplete
              value={variables.cultivar}
              autoHighlight
              id="cultivar-filter"
              options={cultivarHistories}
              onChange={(e, newValue) =>
                addParams({
                  cultivar: newValue || undefined,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cultivar/Strain"
                  fullWidth
                  sx={{ minWidth: "200px" }}
                />
              )}
            />
          </Grid>
        ) : null}

        {locationHistories?.length ? (
          <Grid item>
            <Autocomplete
              value={variables.location}
              autoHighlight
              id="location-filter"
              options={locationHistories}
              onChange={(e, newValue) =>
                addParams({
                  location: newValue || undefined,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  fullWidth
                  sx={{ minWidth: "200px" }}
                />
              )}
            />
          </Grid>
        ) : null}

        {groupHistories?.length ? (
          <Grid item>
            <Autocomplete
              value={variables.group}
              autoHighlight
              id="group-id-filter"
              options={groupHistories}
              onChange={(e, newValue) =>
                addParams({
                  group: newValue || undefined,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Group Id"
                  fullWidth
                  sx={{ minWidth: "200px" }}
                />
              )}
            />
          </Grid>
        ) : null}
      </Grid>
    </Card>
  );
};

export default SampleFilterCard;
