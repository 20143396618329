import { useNavigate } from "react-router-dom";
import FullPageLayout from "../../components/Layouts/FullPageLayout";
import { ColumnDefinition } from "../../components/DataTable/types";
import { DataTable } from "../../components/DataTable";

import {
  ClientCompanyFieldsFragment,
  useGetClientCompaniesQuery,
} from "../../generated/graphql";

import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import NavigateNext from "@mui/icons-material/NavigateNext";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Loading } from "../../components/Loading";

export const LabClientCompanies = () => {
  const { data, loading } = useGetClientCompaniesQuery();
  const navigate = useNavigate();

  const companies = data?.getClientCompanies;

  const columns: ColumnDefinition<ClientCompanyFieldsFragment>[] = [
    {
      name: "businessName",
      label: "Company Name",
    },
    {
      name: "addressStreet",
      label: "Address",
      getValue: (company) => {
        return `${company.addressStreet} ${company.addressStreet2}`;
      },
      render: (company) => {
        return (
          <>
            {company.easypostAddressId ? (
              <span style={{ color: "green" }}>✓</span>
            ) : (
              <span style={{ color: "red" }}>✗</span>
            )}
            {company.addressStreet} {company.addressStreet2}
          </>
        );
      },
    },
    {
      name: "billingEmail",
      label: "Billing Email",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "navigate",
      label: "",
      align: "center",
      disableToggle: true,
      render: (company) => {
        return (
          <IconButton
            onClick={() => navigate(`/lab/client-companies/${company.id}`)}
          >
            <NavigateNext />
          </IconButton>
        );
      },
    },
  ];

  if (loading) {
    return (
      <FullPageLayout>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <h1>Client Companies</h1>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/lab/create-client-company")}
            >
              Create Company
            </Button>
          </Grid>
          <Loading />
        </Grid>
      </FullPageLayout>
    );
  }

  return (
    <FullPageLayout>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <h1>Client Companies</h1>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/lab/create-client-company")}
          >
            Create Company
          </Button>
        </Grid>
      </Grid>
      {companies?.length ? (
        <Card sx={{ padding: "1em", marginBottom: "2em" }}>
          <DataTable<ClientCompanyFieldsFragment>
            data={companies}
            columns={columns}
            defaultControls={{
              showColumns: columns.map((c) => ({ name: c.name, show: true })),
            }}
          />
        </Card>
      ) : (
        <h3>You have no client companies.</h3>
      )}
    </FullPageLayout>
  );
};

export default LabClientCompanies;
