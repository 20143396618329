import { imgURL } from "../lib/assets";

// components
import FullPageLayout from "../components/Layouts/FullPageLayout";

// mui
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export const PlantTissuePage = () => {
  return (
    <FullPageLayout>
      <Box textAlign="center">
        <h1 style={{ fontSize: "50px" }}>Plant Tissue</h1>
      </Box>

      <Grid container justifyContent={"center"} sx={{ margin: "2em 0" }}>
        <Grid item>
          <img
            src={imgURL("/Plant-Sample-Graphic-767x473.png")}
            width="100%"
            alt="plant-sample-graphic"
          />
        </Grid>
      </Grid>
      <Box textAlign="center">
        <h1 style={{ fontSize: "34px", margin: "1em 0" }}>Sample Collection</h1>
      </Box>

      <Typography variant="body1">
        Choose the newest fully developed leaf. (A total of 3-5 leaves are
        needed for full analysis). Approximately 1 gram of fully dried plant
        material is required.
      </Typography>
      <ul>
        <li>Samples should be collected on a per strain basis.</li>
        <br />
        <li>
          After a plant sample has been collected, it should be prepared for
          shipment or delivery to the laboratory.
        </li>
        <br />

        <li>Plant tissue must be dusted off to remove soil particles.</li>
        <br />

        <li>
          DO NOT WASH tissue since soluble nutrients will be leached out of the
          sample.
        </li>
        <br />

        <li>Let plant sample dry for min of two hours</li>
        <br />
        <li>Make sure to note whether sample is HEALTHY OR UNHEALTHY.</li>
      </ul>
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        Sample Normal and Abnormal Areas
      </Typography>
      <Typography variant="body1">
        When a nutrient deficiency is suspected (even without visual symptoms),
        or there is a need to compare different areas in a field, it is
        recommended that similar plant parts be collected separately from both
        the affected plants and adjacent normal plants that are at the same
        stage of growth.
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        Do Not Include
      </Typography>
      <Typography variant="body1">
        Samples should not be taken from plants that obviously have been
        stressed from causes other than nutrients. Do not take samples from
        plants that —
      </Typography>
      <ul>
        <li>Are dead or insect damaged;</li>
        <br />
        <li>Are mechanically or chemically injured;</li>
        <br />
        <li>
          Have been stressed by too much or too little moisture (i.e., flooding
          or drought)
        </li>
      </ul>

      <Typography variant="body1" style={{ marginBottom: "2em" }}>
        Samples should be taken from the top one-third of the pot excluding the
        first two inches. The key to good data is consistent sampling
        techniques. Remove any large wood chips or roots from the sample. This
        can provide inaccurate data.
      </Typography>
      <Box textAlign="center">
        <h1 style={{ fontSize: "34px", margin: "1em 0" }}>Sample Submission</h1>
      </Box>
      <Grid container justifyContent={"center"}>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={3}>
              <img
                src={imgURL("/plant-sample1-644x1024.png")}
                width="100%"
                alt="plant-sample-step-1"
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={imgURL("/plant-sample2-730x1024.png")}
                width="100%"
                alt="plant-sample-step-2"
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={imgURL("/plant-sample3-768x1097.png")}
                width="100%"
                alt="plant-sample-step-3"
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={imgURL("/Icons-3.png")}
                width="100%"
                alt="plant-sample-step-4"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullPageLayout>
  );
};

export default PlantTissuePage;
