import FullPageLayout from "../../components/Layouts/FullPageLayout";
import { useNavigate } from "react-router-dom";

import {
  LaboratoryFieldsFragment,
  useGetLaboratoriesQuery,
} from "../../generated/graphql";

import { Loading } from "../../components/Loading";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import East from "@mui/icons-material/East";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

function LabManagement({ labs }: { labs: LaboratoryFieldsFragment[] }) {
  return (
    <Card>
      <CardHeader title="Laboratories" />
      <CardContent>
        <LabManagementTable labs={labs} />
      </CardContent>
    </Card>
  );
}

function LabManagementTable({ labs }: { labs: LaboratoryFieldsFragment[] }) {
  const navigate = useNavigate();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Address</TableCell>
          <TableCell>Phone</TableCell>
          <TableCell>Support Email</TableCell>
          <TableCell align="center">Go To Lab</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {labs?.map((lab) => (
          <TableRow key={lab.id}>
            <TableCell>{lab.businessName}</TableCell>
            <TableCell>
              {lab.addressStreet} {lab.addressCity}, {lab.addressState}{" "}
              {lab.addressZip}
            </TableCell>
            <TableCell>{lab.phone}</TableCell>
            <TableCell>{lab.supportEmail}</TableCell>
            <TableCell align="center">
              <IconButton
                onClick={() => {
                  navigate(`/lab/lab-settings?labId=${lab.id}`);
                }}
                color="primary"
              >
                <East />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default function Labs() {
  const labsQuery = useGetLaboratoriesQuery();
  return (
    <FullPageLayout>
      <Box sx={{ marginTop: "2em" }}>
        {labsQuery.loading ? (
          <Loading />
        ) : labsQuery.data?.getLaboratories?.length ? (
          <LabManagement labs={labsQuery.data?.getLaboratories} />
        ) : (
          <Box sx={{ textAlign: "center" }}>No laboratories found</Box>
        )}
      </Box>
    </FullPageLayout>
  );
}
