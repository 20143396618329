import {FC, useState} from "react";
import {useFormContext} from "react-hook-form";
import {camelCase, get} from "lodash";
import {
  ClientCompanyWithPricingFragment,
  SampleTestType,
} from "../../generated/graphql";
import {imgURL} from "../../lib/assets";
import {useAuthenticated} from "../../lib/auth";
import {formatPrice, ProductLabels} from "../../lib/testTypes";
import HTMLTitle from "../HTMLTitle";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import KebabDining from "@mui/icons-material/KebabDining";
import Typography from "@mui/material/Typography";
import HDRStrong from "@mui/icons-material/HdrStrong";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import InputAdornment from "@mui/material/InputAdornment";

type TestObj = {
  id: number;
  type: SampleTestType;
  image: string;
};

export const Products = [
  {
    id: 1,
    type: SampleTestType.PlantTissue,
    image: "/tissue.svg",
  },
  {
    id: 2,
    type: SampleTestType.Soil,
    image: "/soil.svg",
  },
  {
    id: 3,
    type: SampleTestType.Fertilizer,
    image: "/fertilizer.svg",
  },
  {
    id: 4,
    type: SampleTestType.Water,
    image: "/water.svg",
  },
  {
    id: 5,
    type: SampleTestType.RunOff,
    image: "/run-off.svg",
  },
  {
    id: 6,
    type: SampleTestType.Nutrient,
    image: <KebabDining/>,
  },
  {
    id: 7,
    type: SampleTestType.PythiumSpp,
    image: "/microbes.svg",
  },
  {
    id: 10,
    type: SampleTestType.FusariumSpp,
    image: <HDRStrong/>,
  },
  {
    id: 8,
    type: SampleTestType.HopLatentViroid,
    image: "/hplv.svg",
  },
  {
    id: 9,
    type: SampleTestType.HeavyMetal,
    image: "/metals.svg",
  },
  
] as TestObj[];

export const CheckoutTable: FC<{
  company?: ClientCompanyWithPricingFragment;
}> = ({company}) => {
  const {register, watch, setValue} = useFormContext();

  const {lab, profile} = useAuthenticated();

  const [editPrice, setEditPrice] = useState<number[]>([]);

  function priceField(t: string) {
    return camelCase(`price_test_${t}`);
  }

  function getPrice(t: string) {
    return get(company, priceField(t)) || get(lab, priceField(t));
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>Product Details</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Products.map((product, index) => {
            const numSamples = watch(`testTypes.${index}.numberOfSamples`);
            if (!get(lab, camelCase(`price_test_${product.type}`))) {
              return null;
            }
            return (
              <TableRow key={product.id}>
                <TableCell>
                  <Grid container alignItems={"center"} spacing={1}>
                    <Grid item>
                      {typeof product.image !== "string" ? (
                        product.image
                      ) : (
                        <img
                          src={imgURL(product.image)}
                          alt={`${product.type} icon`}
                          style={{height: "2rem"}}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" fontWeight={"bold"}>
                        <HTMLTitle value={get(ProductLabels, product.type)}/>
                        <input
                          type="hidden"
                          value={product.type}
                          {...register(`testTypes.${index}.testType`)}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <TextField
                    sx={{maxWidth: "8em"}}
                    variant="outlined"
                    size="small"
                    type="number"
                    margin="dense"
                    defaultValue={0}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 1000,
                      },
                    }}
                    {...register(`testTypes.${index}.numberOfSamples`, {
                      valueAsNumber: true,
                    })}
                  />
                </TableCell>
                <TableCell align="center">
                  {editPrice.includes(product.id) ? (
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <TextField
                          variant="outlined"
                          defaultValue={((watch(`testTypes.${index}.pricePerUnitInCents`) ??
                            getPrice(product.type)) / 100).toFixed(
                            2
                          )}
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setValue(
                              `testTypes.${index}.pricePerUnitInCents`,
                              parseFloat(e.target.value) * 100
                            )
                          }
                        />
                        <input
                          type={"hidden"}
                          {...register(
                            `testTypes.${index}.pricePerUnitInCents`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {formatPrice(watch(`testTypes.${index}.pricePerUnitInCents`) ??
                        getPrice(product.type))}
                      {profile?.staffAtLaboratory ? (
                        <Tooltip title="Edit Price">
                          <IconButton
                            onClick={() =>
                              setEditPrice([...editPrice, product.id])
                            }
                            color="primary"
                          >
                            <Edit/>
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  {formatPrice(
                    (watch(`testTypes.${index}.pricePerUnitInCents`) ??
                      getPrice(product.type)) * numSamples
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CheckoutTable;
