import React, { FC } from "react";
import {
  UserFieldsFragment,
  useSendAccountInvitationMutation,
} from "../generated/graphql";
import { DataTable } from "./DataTable";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { ColumnDefinition } from "./DataTable/types";
import { useEasySnackbar } from "../lib/snackbar";
import Email from "@mui/icons-material/Email";
import Delete from "@mui/icons-material/Delete";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import { useImpersonate } from "../lib/auth";

export const UsersTable: FC<{
  users: UserFieldsFragment[];
  deleteUser: CallableFunction;
}> = ({ users, deleteUser }) => {
  const snackbar = useEasySnackbar();
  const [sendInvite] = useSendAccountInvitationMutation();
  const { beginImpersonate } = useImpersonate();

  const handleSendInvite = async (user: UserFieldsFragment) => {
    try {
      await sendInvite({ variables: { id: user.id } });
      snackbar.showSuccess(`Invitation sent to ${user.email}`);
    } catch (e) {
      snackbar.showError(e, "Error sending invitation");
    }
  };

  const columns: ColumnDefinition<UserFieldsFragment>[] = [
    {
      name: "invite",
      label: "Invite",
      sortable: false,
      render: (user) => {
        return (
          <Tooltip title="Invite User">
            <IconButton onClick={() => handleSendInvite(user)}>
              <Email />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      name: "name",
      label: "Name",
      render: (user) => {
        return `${user.firstName} ${user.lastName}`;
      },
    },
    {
      name: "email",
      label: "Email",
    },

    {
      name: "delete",
      label: "Delete",
      sortable: false,
      render: (user) => {
        return (
          <Tooltip title="Delete User">
            <IconButton
              onClick={() =>
                window.confirm("Are you sure you want to delete this user?") &&
                deleteUser(user.id)
              }
            >
              <Delete />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      name: "impersonate",
      label: "Impersonate",
      sortable: false,
      render: (user) => {
        return (
          <Tooltip title="Impersonate User">
            <IconButton onClick={() => beginImpersonate(user)}>
              <PeopleAlt />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DataTable<UserFieldsFragment>
      data={users}
      columns={columns}
      defaultControls={{
        showColumns: columns.map((c) => ({
          name: c.name,
          show: true,
        })),
      }}
    />
  );
};

export default UsersTable;
