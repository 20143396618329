import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { object, SchemaOf, string } from "yup";
import { Controller, get, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { ErrorMessage } from "@hookform/error-message";

// components
import FullPageLayout from "../../components/Layouts/FullPageLayout";

// mui
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// types
import {
  SendPasswordResetEmailMutationVariables,
  usePasswordResetMutation,
  useSendPasswordResetEmailMutation,
} from "../../generated/graphql";

type ResetPasswordProps = {
  newPassword1: string;
  newPassword2: string;
};

const validationSchema: SchemaOf<ResetPasswordProps> = object({
  newPassword1: string().required("Please enter a new password"),
  newPassword2: string().required("Please repeat your new password"),
});

export const PasswordReset = ({ token }: { token: string }) => {
  const { handleSubmit, control, formState } = useForm<ResetPasswordProps>({
    resolver: yupResolver(validationSchema),
  });
  const [error, setError] = useState("");
  const [reset, response] = usePasswordResetMutation();
  const { enqueueSnackbar } = useSnackbar();
  const output = response?.data?.passwordReset;
  const navigate = useNavigate()

  useEffect(() => {
    if (output?.__typename === "Error") setError("An error occurred");
  }, [output?.__typename]);

  const _onSubmit = async (data: ResetPasswordProps) => {
    reset({ variables: { ...data, token } }).then(() => {
      enqueueSnackbar("Password reset success! Please log in...", { variant: "success" })
      navigate("/login");
    });
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)} noValidate>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
          <Card
            style={{
              padding: "2em",
              textAlign: "center",
              margin: "1em 1em 2em",
            }}
          >
            <h1 style={{margin:"0 0 1em"}}>Password Reset</h1>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="newPassword1"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="New Password"
                      fullWidth
                      required
                      error={!!get(formState.errors, "newPassword1")}
                      type={"password"}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"newPassword1"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="newPassword2"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Confirm New Password"
                      fullWidth
                      required
                      type="password"
                      error={!!get(formState.errors, "newPassword2")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"newPassword2"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box color="red" marginTop={error ? "2em" : 0}>
              {error}
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ margin: "3em 0 1em" }}
              disabled={formState.isSubmitting}
            >
              Change Password
            </Button>
            <Box pt={1}>
              <Typography variant="body2">
                Back to <Link to="/login">Login</Link>
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export const SendResetEmail = () => {
  const [sendReset, response] = useSendPasswordResetEmailMutation();
  const { handleSubmit, control, formState } =
    useForm<SendPasswordResetEmailMutationVariables>();
  const [error, setError] = useState("");

  const _onSubmit = (data: SendPasswordResetEmailMutationVariables) => {
    sendReset({ variables: data }).then((result) => {
      if (result.data?.sendPasswordResetEmail?.__typename === "Error")
        setError("An error occurred");
    });
  };

  return (
    <form onSubmit={handleSubmit(_onSubmit)} noValidate>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
          <Card
            style={{
              padding: "2em",
              textAlign: "center",
              margin: "1em 1em 2em",
            }}
          >
            <h1 style={{margin:"0 0 1em"}}>Password Reset</h1>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Email"
                      fullWidth
                      required
                      error={!!get(formState.errors, "email")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"email"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box color="red" marginTop={error ? "2em" : 0}>
              {error}
            </Box>

            {response.data?.sendPasswordResetEmail?.__typename === "SendPasswordResetEmailSuccess" ? (
              <b>Success! Check your email.</b>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ margin: "3em 0 1em" }}
              disabled={formState.isSubmitting}
            >
              Change Password
            </Button>
            <Box pt={1}>
              <Typography variant="body2">
                Back to <Link to="/login">Login</Link>
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export const PasswordResetPage = () => {
  const { token } = useParams();
  return (
    <FullPageLayout>
      {token ? <PasswordReset token={token} /> : <SendResetEmail />}
    </FullPageLayout>
  );
};

export default PasswordResetPage;
