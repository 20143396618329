import FullPageLayout from "../../components/Layouts/FullPageLayout";
import { RegisterForm } from "../../components/RegisterForm";
import {useAnonymousRequired} from "../../lib/auth";

export const Register = () => {
  useAnonymousRequired();
  return (
    <FullPageLayout>
      <RegisterForm />
    </FullPageLayout>
  );
};

export default Register;
