import { Route, Routes } from "react-router-dom";
import ClientCompanyDetail from "./ClientCompanyDetail";
import CreateClientCompany from "./CreateClientCompany";
import Details from "./Details";
import LabClientCompanies from "./LabClientCompanies";
import LabCreateEditOrder from "./LabCreateEditOrder";
import LabManageOrders from "./LabManageOrders";
import LabSamples from "./LabSamples";
import LabUploadResults from "./LabUploadResults";

export default function LabIndexRouter() {
  return (
    <Routes>
      <Route path="/lab-settings" element={<Details />} />
      <Route path="/upload-results" element={<LabUploadResults />} />
      <Route path="/manage-orders" element={<LabManageOrders />} />
      <Route path="/samples" element={<LabSamples />} />
      <Route path="/client-companies/:id" element={<ClientCompanyDetail />} />
      <Route path="/client-companies" element={<LabClientCompanies />} />
      <Route path="/create-client-company" element={<CreateClientCompany />} />
      <Route path="/order" element={<LabCreateEditOrder />} />
    </Routes>
  );
}
