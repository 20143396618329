import { FC } from "react";
import { useForm } from "react-hook-form";
import { useEasySnackbar } from "../lib/snackbar";

import {
  LaboratoryFieldsFragment,
  useUpdateLaboratoryMutation,
} from "../generated/graphql";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

type LabInfoProps = {
  lab: LaboratoryFieldsFragment | undefined;
};

export const LabInfoForm: FC<LabInfoProps> = ({ lab }) => {
  const { register, handleSubmit } = useForm<LaboratoryFieldsFragment>({
    defaultValues: {
      addressStreet: lab?.addressStreet,
      addressStreet2: lab?.addressStreet2,
      addressCity: lab?.addressCity,
      addressState: lab?.addressState,
      addressZip: lab?.addressZip,
      supportEmail: lab?.supportEmail,
      salesEmail: lab?.salesEmail,
      phone: lab?.phone,
    },
  });

  const [updateLaboratory] = useUpdateLaboratoryMutation();
  const snackbar = useEasySnackbar();

  const _handleSubmit = async (input: LaboratoryFieldsFragment) => {
    try {
      await updateLaboratory({
        variables: {
          input: {
            id: lab?.id,
            addressStreet: input.addressStreet,
            addressStreet2: input.addressStreet2,
            addressCity: input.addressCity,
            addressState: input.addressState,
            addressZip: input.addressZip,
            supportEmail: input.supportEmail,
            salesEmail: input.salesEmail,
            phone: input.phone,
          },
        },
      });
      snackbar.showSuccess("Lab was successfully updated");
    } catch (e) {
      snackbar.showError(e, "There was an error updating this lab");
    }
  };
  return (
    <form onSubmit={handleSubmit(_handleSubmit)} noValidate>
      <Typography variant="body1" fontWeight={"bold"}>
        Address:
      </Typography>
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} md={8}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("addressStreet")}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("addressStreet2")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("addressCity")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("addressState")}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("addressZip")}
          />
        </Grid>
        <Grid item xs={12} md={6} mt={1}>
          <Typography variant="body1" fontWeight={"bold"}>
            Support Email:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("supportEmail")}
          />
        </Grid>
        <Grid item xs={12} md={6} mt={1}>
          <Typography variant="body1" fontWeight={"bold"}>
            Sales Email:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("salesEmail")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" fontWeight={"bold"}>
            Phone:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            {...register("phone")}
          />
        </Grid>
      </Grid>

      <Button
        color="primary"
        variant="contained"
        size="small"
        type="submit"
        sx={{ float: "right" }}
      >
        Save Lab Settings
      </Button>
    </form>
  );
};
