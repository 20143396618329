// components
import { useBackendTable } from "../components/DataTable/types";
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { Loading } from "../components/Loading";
import OrdersTableCard from "../components/OrdersTableCard";
import {
  OrderQuerysetOptionsInput,
  useGetOrdersQuery,
} from "../generated/graphql";
import { useAuthenticated } from "../lib/auth";

export const Orders = () => {
  const auth = useAuthenticated();
  const backendTable = useBackendTable<OrderQuerysetOptionsInput>({
    filters: {
      laboratoryId: auth.lab?.id as string,
      clientCompanyId: auth.profile?.clientCompany?.id,
    },
  });
  const { data, loading } = useGetOrdersQuery({
    variables: { options: backendTable.options },
  });

  return (
    <FullPageLayout>
      {loading ? (
        <Loading />
      ) : (
        <OrdersTableCard data={data} backendTable={backendTable} />
      )}
    </FullPageLayout>
  );
};

export default Orders;
