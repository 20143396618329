import { imgURL } from "../lib/assets";

// components
import FullPageLayout from "../components/Layouts/FullPageLayout";

// mui
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export const SoilPage = () => {
  return (
    <FullPageLayout>
      <Box textAlign="center">
        <h1 style={{ fontSize: "50px" }}>Soil</h1>
      </Box>

      <Grid container justifyContent={"center"} sx={{ margin: "2em 0" }}>
        <Grid item>
          <img
            src={imgURL("/Soil-Sample-Graphic-all-963x350.png")}
            width="100%"
            alt="soil-sample-graphic"
          />
        </Grid>
      </Grid>
      <Box textAlign="center">
        <h1 style={{ fontSize: "34px", margin: "1em 0" }}>Sample Collection</h1>
      </Box>
      <Typography variant="body1" style={{ marginBottom: "2em" }}>
        <b>Outdoor Soil: </b> Samples should be taken to a depth of 6-7 inches.
        <br />
        <b>Potting Media: </b>
        Samples should be taken from the top one-third of the pot excluding the
        first two inches. The key to good data is consistent sampling
        techniques.
      </Typography>

      <ul>
        <li>
          Between 3-5 containers should be sampled and compiled into a single
          sample for submission.
        </li>
        <br />
        <li>Samples should be collected on a per strain basis.</li>
        <br />

        <li>
          Samples from healthy plants should be gathered as a single sample.
        </li>
        <br />

        <li>
          Soil samples from sick or unhealthy samples should be sampled
          individually and documented accordingly on the corresponding sample
          bags.
        </li>
        <br />

        <li>
          When submitting soil samples for sick or unhealthy plants it is
          suggested to provide soil samples from healthy plants as well. (This
          is key in comparing analytical values and may immediately provide a
          clue to the issue.)
        </li>
      </ul>
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        Do Not Include
      </Typography>
      <Typography variant="body1">
        Samples should not be taken from plants that obviously have been
        stressed from causes other than nutrients. Do not take samples from
        plants that —
      </Typography>
      <ul>
        <li>Are dead or insect damaged;</li>
        <br />
        <li>Are mechanically or chemically injured;</li>
        <br />
        <li>
          Have been stressed by too much or too little moisture (i.e., flooding
          or drought)
        </li>
      </ul>
      <Box textAlign="center">
        <h1 style={{ fontSize: "34px", marginTop: "2em" }}>
          Sample Submission
        </h1>
      </Box>

      <Grid container justifyContent={"center"}>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={3}>
              <img
                src={imgURL("/soil-sample1-710x1024.png")}
                width="100%"
                alt="soil-sample-step-1"
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={imgURL("/soil-sample2-691x1024.png")}
                width="100%"
                alt="soil-sample-step-2"
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={imgURL("/soil-sample3-767x1061.png")}
                width="100%"
                alt="soil-sample-step-3"
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={imgURL("/Icons-3.png")}
                width="100%"
                alt="soil-sample-step-4"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <h1 style={{ fontSize: "34px" }}>Testing For</h1>
      </Box>

      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        Essential Nutrients
      </Typography>
      <Typography variant="body1">
        pH, salinity, types and concentrations of soluble salts (calcium,
        magnesium, chloride, sodium, sulfate, phosphate, boron, and nitrate) in
        saturation extract, SAR, all essential nutrients, percent moisture, half
        saturation and 14 non-essential, potentially toxic trace minerals.
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        Non-Essential Nutrients
      </Typography>
      <Typography variant="body1">
        Boron, calcium, chloride, copper, iron, magnesium, manganese,
        molybdenum, nitrogen, nickel, phosphorus, potassium, sulfur, zinc and
        for some plants sodium.
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginTop: "2em" }}
      >
        Non-Essential Minerals
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "2em" }}>
        Aluminum, arsenic, barium, cadmium, chromium, cobalt, lead, lithium,
        mercury, selenium, silver, sodium, strontium, tin, titanium, and
        vanadium.
      </Typography>
    </FullPageLayout>
  );
};

export default SoilPage;
