import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { imgURL } from "../../lib/assets";
import { Navbars } from "../Layouts/NavBar";
import {useAuthenticated} from "../../lib/auth";

export const LogoBox = ({ navbar }: { navbar: Navbars }) => {
  const navigate = useNavigate();
  const {site, lab, loading} = useAuthenticated()
  const home = navbar === "lab" ? "/lab/manage-orders" : "/";

  const logo = loading ? undefined : lab?.logoAsset ?? site?.logoAsset ?? imgURL("/ArvumPlantLabsLogo.svg")

  return (
    <>
      <Box sx={{ mr: "2em", display: { xs: "none", md: "flex" } }}>
        <Tooltip title="Navigate Home" arrow>
          <img
            src={logo}
            alt="logo"
            style={{
              height: "2.5rem",
              cursor: "pointer",
            }}
            onClick={() => navigate(home)}
          />
        </Tooltip>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }}}>
        <Tooltip title="Navigate Home">
          <img
            src={logo}
            alt="logo"
            style={{
              height: "1.5rem",
              maxWidth: "30vw",
              cursor: "pointer",
            }}
            onClick={() => navigate(home)}
          />
        </Tooltip>
      </Box>
    </>
  );
};
