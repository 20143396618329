import FullPageLayout from "../../components/Layouts/FullPageLayout";
import TrackingColumnsCard from "../../components/TrackingColumnsCard";
import {ClientUsersTable} from "../../components/ClientUsersTable";
import {ClientCompanyDetailForm} from "./ClientCompanyDetailForm";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthenticated} from "../../lib/auth";
import {
  OrderQuerysetOptionsInput,
  useGetClientCompanyQuery,
  useGetOrdersQuery,
} from "../../generated/graphql";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Loading} from "../../components/Loading";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import {useState} from "react";
import OrdersTableCard from "../../components/OrdersTableCard";
import {ClientCompanyInternalWrapper} from "./ClientCompanyInternalForm";
import {CustomClientCompanyPricingTable} from "../../components/CustomClientCompanyPricingTable";
import { useBackendTable } from "../../components/DataTable/types";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ClientCompanyDetail = () => {
  let {id} = useParams();
  const navigate = useNavigate();

  const {profile, lab} = useAuthenticated();

  const myCompany = profile?.clientCompany;

  const backendTable = useBackendTable<OrderQuerysetOptionsInput>({
    filters: {
      laboratoryId: (lab?.id as string),
      clientCompanyId: id,
    },
  });
  const {data: ordersData} = useGetOrdersQuery({
    variables: {options: backendTable.options},
  });

  const {data, loading} = useGetClientCompanyQuery({
    variables: {id: (id as string) || myCompany?.id},
  });

  const company = data?.getClientCompany;

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <FullPageLayout>
        <Loading/>
      </FullPageLayout>
    );
  }

  if (!company) {
    return (
      <FullPageLayout>
        <Typography variant="h5" fontWeight={"bold"} mb={2}>
          This company does not exist.
        </Typography>
      </FullPageLayout>
    );
  }

  return (
    <FullPageLayout>
      <Grid container spacing={1} alignItems="center">
        {id ? (
          <Grid item>
            <Tooltip title="Back">
              <IconButton onClick={() => navigate("/lab/client-companies")}>
                <ArrowBack color="primary"/>
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}

        <Grid item>
          <h1>{company?.businessName} Client Company</h1>
        </Grid>
      </Grid>
      <Box sx={{width: "100%"}}>
        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Users" {...a11yProps(1)} />
            <Tab label="Order History" {...a11yProps(2)} />
            {profile?.staffAtLaboratory ? (
              <Tab label="Lab Internal" {...a11yProps(3)} />
            ) : null}
            {profile?.staffAtLaboratory ? (
              <Tab label="Custom Pricing" {...a11yProps(4)} />
            ) : null}
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{p: 3}}>
                <Typography variant="h5" fontWeight={"bold"}>
                  Details
                </Typography>
                <Typography variant="body2">
                  An accurate phone and email are required in order to receive
                  tests in the mail. Your information will not be used for
                  anything other than shipping and billing.
                </Typography>

                <ClientCompanyDetailForm company={company}/>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <TrackingColumnsCard company={company}/>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid item xs={12} md={6}>
            <ClientUsersTable company={company}/>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <OrdersTableCard data={ordersData} backendTable={backendTable}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Grid container spacing={3}>
            <ClientCompanyInternalWrapper companyId={company.id}/>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <CustomClientCompanyPricingTable clientCompany={company}/>
        </TabPanel>
      </Box>
    </FullPageLayout>
  );
};

export default ClientCompanyDetail;
