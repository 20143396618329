import { useEffect, useState, useCallback } from "react";
import { Path, useForm } from "react-hook-form";
import { get } from "lodash";
import { ProductLabels } from "../lib/testTypes";

// components
import { AutocompleteHistoryInput } from "./AutocompleteHistoryInput";

// types
import {
  SampleStage,
  SampleStatus,
  SampleSummaryFieldsFragment,
  SampleTestType,
  useUpdateSampleInfoMutation,
} from "../generated/graphql";

// mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import { Cancel, Check, ContentCopy, ContentPaste } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import HTMLTitle from "./HTMLTitle";
import { RENDER_BASE } from "../lib/assets";
import { useAuthenticated } from "../lib/auth";

type SampleInfoRowProps = {
  sample: SampleSummaryFieldsFragment;
  trackingColumns: string[];
  histories: Record<string, string[]>;
  setCopiedRow: (copied?: SampleSummaryFieldsFragment) => void;
  copiedRow: SampleSummaryFieldsFragment | undefined;
};

export function SampleOrderRow({
  sample,
  trackingColumns,
  histories,
  setCopiedRow,
  copiedRow,
}: SampleInfoRowProps) {
  const formMethods = useForm<SampleSummaryFieldsFragment>({
    defaultValues: sample,
  });
  const { register, getValues, watch, setValue } = formMethods;
  const [status, setStatus] = useState<"UNCHANGED" | "SAVING" | "SAVED">(
    "UNCHANGED"
  );
  const [save] = useUpdateSampleInfoMutation();

  const { lab } = useAuthenticated()
  const healthProfiles = lab?.healthProfiles
  const filteredProfiles = healthProfiles?.filter(profile => profile.testType.toString() === sample.testType);

  const complete = sample.status === SampleStatus.Complete;
  const values = watch()

  const saveChanges = useCallback(() => {
    const values = getValues();
    setStatus("SAVING");
    const healthProfileId = values.healthProfileId === "" ? null : values.healthProfileId;
    save({
      variables: {
        input: {
          id: sample.id,
          healthProfileId: healthProfileId,
          collectionDate: values.collectionDate,
          cultivar: values.cultivar,
          daysInStage: values.daysInStage,
          group: values.group,
          harvestGroup: values.harvestGroup,
          label: values.label,
          location: values.location,
          stage: values.stage,
        },
      },
    })
      .then(() => {
        setStatus("SAVED");
      })
      .catch((e) => {
        setStatus("UNCHANGED");
        console.error(e);
      });
  }, [getValues, sample.id, save])
  


  // On componentDidMount set the timer
  useEffect(() => {
    if (status === "SAVED") {
      const timeId = setTimeout(() => {
        // After 1 seconds set the show value to false
        setStatus("UNCHANGED");
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [status]);

  return (
    <TableRow id={`sample-info-${sample.id}`}>
      <TableCell>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="body1">{sample.id}</Typography>
          </Grid>
          <Grid item>
            <Fade in={status === "SAVED"}>
              <Check sx={{ color: "green" }} />
            </Fade>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          <HTMLTitle value={get(ProductLabels, sample.testType)} />
        </Typography>
      </TableCell>

      <TableCell>
        <TextField
          required
          margin="dense"
          fullWidth
          type="date"
          sx={{ minWidth: "6em" }}
          size="small"
          {...register(`collectionDate`, { onChange: saveChanges })}
        />
      </TableCell>

      {trackingColumns.map((column) => {
        return column === "stage" ? (
          <TableCell key={column}>
            <TextField
              required
              margin="dense"
              fullWidth
              sx={{ minWidth: "8em" }}
              select
              size="small"
              {...register(`stage` as const, { onChange: saveChanges })}
              value={values.stage}
            >
              {[
                "A",
                "Mother",
                "Clone",
                "Veg",
                "Flower",
                "Late Flower",
                "Drying",
              ].map((stage) => {
                return stage !== "A" ? (
                  <MenuItem
                    key={stage}
                    value={
                      SampleStage[
                      stage.replaceAll(" ", "") as keyof typeof SampleStage
                      ]
                    }
                  >
                    {stage}
                  </MenuItem>
                ) : (
                  <MenuItem key={stage} value={""}>
                    -
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
        ) : column === "daysInStage" ? (
          <TableCell key={column}>
            <TextField
              sx={{ width: "6em" }}
              margin="dense"
              fullWidth
              size="small"
              type="number"
              InputProps={{ inputProps: { min: 0, max: 200 } }}
              {...register(`${column}`, {
                valueAsNumber: true,
                onChange: saveChanges,
              })}
              defaultValue={sample.daysInStage || 0}
            />
          </TableCell>
        ) : column === "label" ? (
          <TableCell key={column}>
            <TextField
              margin="dense"
              fullWidth
              size="small"
              {...register(`${column}`, { onBlur: saveChanges })}
              defaultValue={sample.label || ""}
            />
          </TableCell>
        ) : (
          <TableCell key={column}>
            <AutocompleteHistoryInput
              formMethods={formMethods}
              name={`${column}` as Path<SampleSummaryFieldsFragment>}
              history={histories[column] || []}
              defaultValue={sample[column as keyof SampleSummaryFieldsFragment]}
              onChange={saveChanges}
            />
          </TableCell>
        );
      })}
      <TableCell>
        {[SampleTestType.FusariumSpp, SampleTestType.HopLatentViroid, SampleTestType.PythiumSpp].includes(sample.testType) ?
          <Typography variant="body2">Detectable</Typography> :
          filteredProfiles?.length && filteredProfiles.length > 0 ? (
            <TextField
              required
              margin="dense"
              fullWidth
              sx={{ minWidth: "8em" }}
              select
              size="small"
              {...register(`healthProfileId` as const, { onChange: saveChanges })}
              value={values.healthProfileId ?? ""}
            >
              {filteredProfiles?.map(profile => (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.title}
                </MenuItem>
              ))}
              <MenuItem value={""}>No Health Profile</MenuItem>
            </TextField>) : (<Typography variant="body2">No Profiles Exist</Typography>)
        }

      </TableCell>
      {complete ? (
        <TableCell>
          <Tooltip title="Open PDF" arrow placement="top">
            <IconButton
              onClick={() =>
                window.open(RENDER_BASE + `api/render/sample_certificate-${sample.id}.pdf`)
              }
            >
              <PictureAsPdf />
            </IconButton>
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell />
      )}
      <TableCell>
        {copiedRow ? (
          copiedRow.id === sample.id ? (
            <Tooltip title="Clear Clipboard">
              <IconButton
                onClick={() => {
                  setCopiedRow()
                }}
              >
                <Cancel />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Paste Row">
              <IconButton
                onClick={() => {
                  formMethods.reset(copiedRow);
                }}
              >
                <ContentPaste />
              </IconButton>
            </Tooltip>
          )
        ) : (
          <Tooltip title="Copy Row">
            <IconButton onClick={() => setCopiedRow(getValues())}>
              <ContentCopy />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
}

export default SampleOrderRow;
