import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { Controller, UseFormReturn, useFieldArray } from "react-hook-form"
import { AnalyteHealthRangeAnalyte, AnalyteInfo, CreateAnalyteHealthRangeInput, HealthProfileFragment, useGetApplicationSettingsQuery } from "../../generated/graphql"
import TextField from "@mui/material/TextField"
import MenuItem from "@mui/material/MenuItem"
import { FC, useEffect } from "react"
import DialogContent from "@mui/material/DialogContent"
import { ProductLabels } from "../../lib/testTypes"
import { get } from "lodash"
import { InputAdornment } from "@mui/material"

export type AnalyteHealthProfileFormProps = {
  formMethods: UseFormReturn<HealthProfileFragment>
  existinghealthProfile?: HealthProfileFragment
};

const stageOptions = [
  { value: "", label: "-" },
  { value: "MOTHER", label: "Mother" },
  { value: "CLONE", label: "Clone" },
  { value: "VEG", label: "Veg" },
  { value: "FLOWER", label: "Flower" },
  { value: "LATE_FLOWER", label: "Late Flower" },
  { value: "DRYING", label: "Drying" }
];

export const AnalyteHealthProfileForm: FC<AnalyteHealthProfileFormProps> = ({ formMethods, existinghealthProfile }) => {
  const { register, setValue, control, watch } = formMethods
  const applicationSettings = useGetApplicationSettingsQuery()?.data?.getApplicationSettings;

  const { fields } = useFieldArray<HealthProfileFragment, "analyteRanges">({
    control,
    name: 'analyteRanges',
  });

  const analytes = applicationSettings?.analytes
  const testAnalytes = applicationSettings?.testAnalytes

  const getAnalyte = (name: string) => analytes?.find(analyte => analyte.id === name)

  // Group analytes by test type
  const groupedAnalytes = testAnalytes?.reduce<Record<string, AnalyteInfo[]>>((acc, test) => {
    acc[test.testType] = test.analytes.map((analyteId) => {
      return analytes?.find((analyte) => analyte.id === analyteId) as AnalyteInfo;
    }).filter(analyte => analyte !== undefined);
    return acc;
  }, {});

  const testType = watch("testType")

  const getUnitAdornment = (unit: string | undefined): string => {
    switch (unit) {
      case "percent":
        return "%";
      case "ec":
        return "mS/cm";
      case "cec_estimation":
        return "cmol/kg";
      case "ppb":
        return "ppb";
      case "no_unit":
        return ""
      default:
        return "ppm";
    }
  };

  useEffect(() => {
    if (testType && !existinghealthProfile) {
      const newAnalytes = groupedAnalytes?.[testType] || [];
      const newFields = newAnalytes.map(analyte => ({
        analyte: analyte.id as AnalyteHealthRangeAnalyte,
        min: 0,
        low: 0,
        high: 0,
        max: 0
      }));

      // Only reset the fields if they actually need to change
      if (JSON.stringify(fields.map(f => f.analyte)) !== JSON.stringify(newFields.map(f => f.analyte))) {
        setValue('analyteRanges', newFields);
      }
    }
  }, [testType, groupedAnalytes, setValue, fields]);

  return (
    <DialogContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              required
              variant="outlined"
              autoFocus
              sx={{ marginTop: "1em" }}
              {...register("title")}
            />
          </Grid>
          <Grid item xs={12}>

            <TextField
              id="test-type-select"
              label="Test Type"
              disabled={existinghealthProfile ? true : false}
              required
              variant="outlined"
              fullWidth
              defaultValue={existinghealthProfile?.testType}

              {...register("testType", {
                onChange: (e) => { // Handling change to immediately trigger related updates
                  setValue("testType", e.target.value);
                }
              })}
              select
            >
              {testAnalytes?.map((test) => {
                // Ensure we filter out test types based on measurement unit criteria within the map
                const testAnalytesMeasurementUnits = test.analytes.map(a => getAnalyte(a)?.measurementUnit);
                if (testAnalytesMeasurementUnits.every(unit => unit !== "detectable")) {
                  return (
                    <MenuItem key={test.testType} value={test.testType}>
                      {get(ProductLabels, test.testType)}
                    </MenuItem>
                  );
                }
                return null;
              })}
            </TextField>

          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Stage"
              fullWidth
              variant="outlined"
              {...register("stage")}
              select
              defaultValue={existinghealthProfile?.stage}
            >
              {stageOptions.map(option =>
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Cultivar"
              fullWidth
              variant="outlined"
              {...register("cultivar")}
            />
          </Grid>

          {fields.map((field, index) => (
            <Grid container item xs={12} spacing={2} key={field.id}>
              <Grid item xs={12}>

                <input
                  type="hidden"
                  {...register(`analyteRanges.${index}.analyte`)}
                  value={field.analyte}
                />
                <Typography
                  variant="subtitle1"
                  component={"div"}
                  dangerouslySetInnerHTML={{
                    __html: getAnalyte(field.analyte.toLowerCase())?.label as string
                  }}
                />

              </Grid>
              {(['min', 'low', 'high', 'max'] as const).map((rangeField) => (
                <Grid item xs={3} key={rangeField}>
                  <Controller
                    name={`analyteRanges.${index}.${rangeField as keyof CreateAnalyteHealthRangeInput}` as const}
                    control={control}
                    defaultValue={field[rangeField]}
                    render={({ field: controllerField }) => (
                      <TextField
                        {...controllerField}
                        label={rangeField.toUpperCase()}
                        variant="outlined"
                        type="number"
                        size="small"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            min: 0
                          },
                          endAdornment: <InputAdornment position="end">
                            {getUnitAdornment(getAnalyte(field.analyte.toLowerCase())?.measurementUnit)}
                          </InputAdornment>
                        }}
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>

      </form>
    </DialogContent>
  )
}

export default AnalyteHealthProfileForm