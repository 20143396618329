import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEasySnackbar } from "../../lib/snackbar";

import {
  CreateAnalyteHealthRangeInput,
  CreateHealthProfileInput,
  HealthProfileFragment,
  MyProfileDocument,
  useCreateHealthProfileMutation,
  useGetApplicationSettingsQuery,
} from "../../generated/graphql";

// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AddCircle from "@mui/icons-material/AddCircle";
import AnalyteHealthRangesForm from "./AnalyteHealthProfileForm";


export const CreateHealthProfileDialog = () => {
  const formMethods = useForm<HealthProfileFragment>();
  const [open, setOpen] = useState(false);
  const analytes = useGetApplicationSettingsQuery()?.data?.getApplicationSettings?.analytes

  const [createHealthProfile] = useCreateHealthProfileMutation({
    refetchQueries: [
      {
        query: MyProfileDocument,
      },
    ],
  });
  const snackbar = useEasySnackbar();

  const getAnalyte = (name: string) => analytes?.find(a => a.id.toLowerCase() === name.toLowerCase())
  const rangeFromFormValues = (range: CreateAnalyteHealthRangeInput) => getAnalyte(range.analyte)?.measurementUnit === "percent" ? ({
    analyte: range.analyte,
    min: (range.min ? range.min * 10000 : 0),
    low: (range.low ? range.low * 10000 : 0),
    high: (range.high ? range.high * 10000 : 0),
    max: (range.max ? range.max * 10000 : 0)
  }) : range


  const _handleSubmit = async (input: CreateHealthProfileInput) => {
    try {
      await createHealthProfile({
        variables: {
          input: {
            title: input.title,
            testType: input.testType,
            stage: input.stage === "A_" ? null : input.stage,
            cultivar: input.cultivar,
            analyteRanges: input.analyteRanges?.map(range => (rangeFromFormValues(range))),
          },
        },
      });
      snackbar.showSuccess("Health Profile successfully created!");
      setOpen(false);
      formMethods.reset()
    } catch (e) {
      snackbar.showError(e, "There was an error creating this health profile.");
    }
  };

  return (
    <>
      <Button
        startIcon={<AddCircle />}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Create Health Profile
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <form noValidate onSubmit={formMethods.handleSubmit(_handleSubmit)}>
          <DialogTitle>Create a New Health Profile</DialogTitle>
          <AnalyteHealthRangesForm formMethods={formMethods} />
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Exit</Button>
            <Button type="submit" variant="contained">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateHealthProfileDialog;
