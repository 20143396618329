import { Controller, Path, UseFormReturn } from "react-hook-form";

import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { SampleSummaryFieldsFragment } from "../generated/graphql";
import {useEffect} from "react";

const filter = createFilterOptions<HistoryOption>();

interface HistoryOption {
  value: string;
  title: string;
}

export function AutocompleteHistoryInput(props: {
  formMethods: UseFormReturn<SampleSummaryFieldsFragment>;
  name: Path<SampleSummaryFieldsFragment>;
  history: string[];
  defaultValue: string;
  onChange: (newValue: string) => void;
}) {
  const { setValue, control, watch } = props.formMethods;
  const { name, history } = props;

  const value = watch(name)

  useEffect(() => {
    props.onChange(value);
  } , [props.onChange, value]);

  return (
    <Controller
      control={control}
      name={name}
      render={(controllerProps) => (
        <Autocomplete
          {...controllerProps}
          freeSolo
          options={history.map((key: string) => ({
            title: key,
            value: key,
          }))}
          value={value}
          onChange={(event, newValue) => {
            if (typeof newValue === "string")
              setValue(name, newValue as string);
            else if (typeof newValue === "object") {
              const { value } = newValue as HistoryOption;
              setValue(name, value as string);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;

            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: inputValue,
                title: `${inputValue}`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.title) {
              return option.title;
            }
            // Regular option
            return option.title;
          }}
          autoHighlight
          selectOnFocus
          disableClearable
          clearOnEscape
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ minWidth: "8em" }}
              margin="dense"
              fullWidth
              size="small"
              onChange={(e) => {
                setValue(name, e.target.value as string);
              }}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      )}
    />
  );
}
