import {useEffect, useState} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { Controller, get, useForm } from "react-hook-form";
import { parseGQLErrorMessage } from "../lib/validation";
import { boolean, object, SchemaOf, string } from "yup";
import { ErrorMessage } from "@hookform/error-message";
import {setAuthToken, useAuthenticated} from "../lib/auth";

// mui
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

// types
import {
  RegisterUserAndClientCompanyMutationVariables, useRegisterUserAndClientCompanyMutation,
} from "../generated/graphql";
import {useProfile} from "./Auth/ProfileProvider";

const validationSchema: SchemaOf<RegisterUserAndClientCompanyMutationVariables["input"]> = object({
  laboratoryId: string().required("Must sign up for a lab"),
  firstName: string().trim().required("Please enter your first name"),
  lastName: string().trim().required("Please enter your last name"),
  email: string()
    .trim()
    .email("Please enter a valid email")
    .required("Please enter your email address"),
  password1: string().required("Please enter a password"),
  password2: string()
    .required("Please confirm your password")
    .test("passwords-match", "Passwords must match", function (value) {
      // @ts-ignore
      return this.parent.password1 === value;
    }),
  phone: string().trim().required(
    "Please enter a phone number for shipping integration"
  ),
  addressCity: string().trim().required("Please enter a city"),
  addressState: string().trim().required("Please enter a state"),
  addressStreet: string().trim().required(
    "Please enter a street for shipping integration"
  ),
  addressZip: string().trim().required("Please enter a zip code"),
  businessName: string().trim().required(
    "Please enter a business name for shipping integration"
  ),
  acceptTerms: boolean().oneOf(
    [true],
    "You must accept the terms to register."
  ),
});

export const RegisterForm = () => {
  const { handleSubmit, control, formState, setValue, register } =
    useForm<RegisterUserAndClientCompanyMutationVariables["input"]>({
      resolver: yupResolver(validationSchema),
    });
  const [error, setError] = useState("");
  const [accept, setAccept] = useState(false);
  const [registerUser] = useRegisterUserAndClientCompanyMutation();
  const navigate = useNavigate();
  const {site} = useProfile();

  const _onSubmit = async (input: RegisterUserAndClientCompanyMutationVariables["input"]) => {
    if (!accept) {
      setError("You must accept the terms to register.");
    } else {
      try {
        setError("");
        const registered = (
          await registerUser({
            variables: { input, },
          })
        ).data?.registerUserAndClientCompany;
        if (registered?.token) {
          setAuthToken(registered.token, registered.refreshToken || "");
          window.location.pathname = "/"
        } else {
          setError("An error occurred, please contact support");
        }
      } catch (err) {
        setError(
          parseGQLErrorMessage(err) ||
            "An error occurred, please contact support"
        );
      }
    }
  };
  useEffect(() => {
    if (site?.id) {
      setValue("laboratoryId", site.id);
    }
  }, [site?.id])

  return (
    <form onSubmit={handleSubmit(_onSubmit)} noValidate>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} lg={4}>
          <Card
            style={{
              padding: "2em",
              textAlign: "center",
              margin: "1em 1em 2em",
            }}
          >
            <h1 style={{ margin: "0 0 0.25em", fontSize: "40px" }}>Sign Up</h1>
            <Typography variant="caption">{site?.businessName}</Typography>
            <h2 style={{ textAlign: "left" }}>Personal Info</h2>
            <input type={"hidden"} {...register("laboratoryId")} />

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="First Name"
                      fullWidth
                      required
                      error={!!get(formState.errors, "firstName")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"firstName"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Last Name"
                      fullWidth
                      required
                      error={!!get(formState.errors, "lastName")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"lastName"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Email"
                      fullWidth
                      required
                      error={!!get(formState.errors, "email")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"email"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Phone Number"
                      fullWidth
                      required
                      error={!!get(formState.errors, "phone")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"phone"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="password1"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Password"
                      fullWidth
                      type="password"
                      required
                      error={!!get(formState.errors, "password1")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"password1"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="password2"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Confirm Password"
                      type="password"
                      fullWidth
                      required
                      error={!!get(formState.errors, "password2")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"password2"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <h2 style={{ textAlign: "left" }}>Business Info</h2>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="businessName"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Name"
                      fullWidth
                      required
                      error={!!get(formState.errors, "businessName")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"businessName"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="addressStreet"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Street Address"
                      fullWidth
                      required
                      error={!!get(formState.errors, "addressStreet")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"addressStreet"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  control={control}
                  name="addressCity"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="City"
                      fullWidth
                      required
                      error={!!get(formState.errors, "addressCity")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"addressCity"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5} sm={3}>
                <Controller
                  control={control}
                  name="addressState"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="State"
                      fullWidth
                      required
                      error={!!get(formState.errors, "addressState")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"addressState"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={7} sm={4}>
                <Controller
                  control={control}
                  name="addressZip"
                  render={({ field: { onChange } }) => (
                    <TextField
                      onChange={onChange}
                      variant="outlined"
                      label="Zip Code"
                      fullWidth
                      required
                      error={!!get(formState.errors, "addressZip")}
                      helperText={
                        <ErrorMessage
                          errors={formState.errors}
                          name={"addressZip"}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"baseline"}
              sx={{ marginTop: "1em", textAlign: "left" }}
            >
              <Grid item xs={1}>
                <Checkbox
                  name="acceptTerms"
                  onChange={(e) => setAccept(e.target.checked)}
                  checked={accept}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body1">
                  I have read and accept the{" "}
                  <Link
                    to="/terms"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "#aed581",
                    }}
                  >
                    Terms of Service
                  </Link>{" "}
                  &{" "}
                  <Link
                    to="/privacy"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "#aed581",
                    }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Grid>
            </Grid>

            <Box color="red" marginTop={error ? "2em" : 0}>
              {error}
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ margin: "2em 0 1em" }}
              disabled={formState.isSubmitting}
            >
              Create Account
            </Button>
            <Box pt={1}>
              <Typography variant="body2">
                Already have an account? <Link to="/login">Login Here</Link>
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
