import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "lodash";
import copy from "copy-to-clipboard";
import { useEasySnackbar } from "../lib/snackbar";
import { ProductLabels } from "../lib/testTypes";
import { imgURL, RENDER_BASE } from "../lib/assets";
import {
  ImportSampleTrackingColumns,
  readWorksheetTrackingColumns,
} from "../lib/importXLS";

import {
  useSampleTrackingColumnHistories,
  useVisibleTrackingColumns,
} from "../lib/trackingColumns";

// components
import FullPageLayout from "../components/Layouts/FullPageLayout";
import SampleOrderRow from "../components/EditSampleInfoRow";
import { Products } from "../components/Checkout/CheckoutTable";

// types
import { columnsToColumnNames, TRACKING_COLUMN_ORDER } from "../types/types";
import {
  GetOrderDocument,
  OrderWithSamplesFragment,
  SampleSummaryFieldsFragment,
  SampleTestType,
  useGetOrderQuery,
  useUpdateSamplesInfoMutation,
} from "../generated/graphql";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TablePagination from "@mui/material/TablePagination";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ViewColumn from "@mui/icons-material/ViewColumn";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import useEventCallback from "@mui/material/utils/useEventCallback";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import UploadFile from "@mui/icons-material/UploadFile";
import CopyAll from "@mui/icons-material/CopyAll";
import Download from "@mui/icons-material/Download";
import Cancel from "@mui/icons-material/Cancel";
import HTMLTitle from "../components/HTMLTitle";

export const OrderDetail = () => {
  let { id } = useParams();

  const { data, loading } = useGetOrderQuery({
    variables: { id: id as string },
  });

  const order = data?.getOrder;

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [copiedRow, setCopiedRow] = useState<SampleSummaryFieldsFragment>();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const trackingColumns = useVisibleTrackingColumns() || [];

  const showColumns = TRACKING_COLUMN_ORDER.filter((c) =>
    trackingColumns.includes(c.toString())
  );

  const histories = useSampleTrackingColumnHistories();

  const sampleTestTypes = [
    SampleTestType.PlantTissue,
    SampleTestType.Soil,
    SampleTestType.Fertilizer,
    SampleTestType.Water,
    SampleTestType.RunOff,
    SampleTestType.Nutrient,
    SampleTestType.HeavyMetal,
    SampleTestType.PythiumSpp,
    SampleTestType.HopLatentViroid,
    SampleTestType.FusariumSpp,
  ].filter((t) =>
    order?.samples?.find((s) => s.testType === t)
  ) as SampleTestType[];

  if (loading)
    return (
      <FullPageLayout>
        <CircularProgress />
      </FullPageLayout>
    );
  if (!order)
    return (
      <FullPageLayout>
        <Card>
          <CardHeader title={"Order Not Found"} />
          <CardContent>
            This Order was not found, maybe you don't have permission to view
            it?
          </CardContent>
        </Card>
      </FullPageLayout>
    );

  return (
    <FullPageLayout>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <h1 style={{ display: "inline-block", marginBottom: 0 }}>
            Order #{id}
          </h1>
          <h2 style={{ margin: "0 0 1em", color: "#5e5e5e" }}>
            {order.orderDate}
          </h2>
        </Grid>
        <Grid item>
          <ImportTrackingColumnsButton order={order} />
          <Button
            variant="contained"
            onClick={() => navigate("/company-profile")}
            startIcon={<ViewColumn />}
          >
            Edit Tracking Columns
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} direction="column" mb={"2em"}>
        {sampleTestTypes.map((testType) => {
          const foundTestType = Products.find(
            (product) => product.type === testType
          );

          return (
            <Grid item container>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container alignItems={"center"} spacing={2}>
                      <Grid item>
                        {typeof foundTestType?.image !== "string" ? (
                          foundTestType?.image
                        ) : (
                          <img
                            style={{ height: "2rem" }}
                            alt="Test Icon"
                            src={
                              foundTestType
                                ? imgURL(foundTestType.image)
                                : undefined
                            }
                          />
                        )}
                      </Grid>
                      <Grid item>
                        <h3 style={{ flexShrink: 0, margin: "0.5em" }}>
                          <HTMLTitle value={get(ProductLabels, testType) as string} />
                        </h3>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Sample ID</TableCell>
                            <TableCell>Test Type</TableCell>

                            <TableCell>Collection Date</TableCell>
                            {showColumns.map((column) => (
                              <TableCell
                                key={column.toString()}
                                sx={
                                  column === "daysInStage"
                                    ? { width: "6em" }
                                    : column === "cultivar"
                                      ? { width: 250 }
                                      : null
                                }
                              >
                                {columnsToColumnNames[column.toString()] ??
                                  column}
                              </TableCell>
                            ))}
                            <TableCell>Health Profile</TableCell>

                            <TableCell>Results</TableCell>

                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.samples
                            .filter((sample) => sample.testType === testType)
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((sample) => {
                              return (
                                <SampleOrderRow
                                  copiedRow={copiedRow}
                                  setCopiedRow={setCopiedRow}
                                  sample={sample}
                                  key={sample.id}
                                  trackingColumns={showColumns}
                                  histories={histories}
                                />
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      component="div"
                      count={
                        order.samples.filter(
                          (field) => field.testType === testType
                        ).length
                      }
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </FullPageLayout>
  );
};

export function ImportTrackingColumnsButton({
  order,
}: {
  order: OrderWithSamplesFragment;
}) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [importSheet, setImport] = useState<
    ImportSampleTrackingColumns | undefined
  >();
  const [updateSamples] = useUpdateSamplesInfoMutation();
  const trackingColumns = ["collectionDate", ...useVisibleTrackingColumns()];
  const snackbar = useEasySnackbar();

  const doClipboardCopy = (text: string, descriptor: string) => {
    copy(text, { format: "text" });
    snackbar.showSuccess(
      `${order?.samples.length} ${descriptor} copied to clipboard.`
    );
  };

  const handleCopyIds = () => {
    const rows = order.samples.map((s) => s.id);
    doClipboardCopy(rows.join("\n"), "IDs");
  };

  const handleFileChange = useEventCallback(async (file: File) => {
    setFile(file);
    const extension = file.name.toLowerCase().split(".").pop() || "";
    if (["xlsx", "xls"].includes(extension)) {
      await readWorksheetTrackingColumns(
        "import values",
        file,
        trackingColumns,
        async (sheet) => {
          setImport(sheet);
          if (sheet) {
            const result = await updateSamples({
              variables: {
                input: {
                  samples: sheet.rows
                    .map((s) => ({
                      id: s.sampleId,
                      ...trackingColumns.reduce(
                        (data, tc) => ({
                          ...data,
                          [tc]: get(s.importData, tc),
                        }),
                        {} as Record<string, string>
                      ),
                    }))
                    .filter((row) => row !== undefined),
                },
              },
              refetchQueries: [
                { query: GetOrderDocument, variables: { id: order.id } },
              ],
            });
            if (!result.data?.updateSamplesInfo?.samples?.length) {
              snackbar.showError(
                null,
                `No samples imported, please check the file`
              );
            } else {
              snackbar.showSuccess(
                `Imported ${result.data?.updateSamplesInfo?.samples?.length} samples`
              );
            }
            setOpen(false);
            window.location.reload();
          }
        }
      );
    }
  });

  return (
    <>
      <Tooltip title={`Import Tracking Values`}>
        <IconButton onClick={() => setOpen(true)}>
          <UploadFile />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Card>
          <CardHeader
            title={"Import Tracking Columns"}
            subheader={file?.name}
          />
          <CardContent>
            <Grid container alignContent="center" spacing={2}>
              <Grid item style={{ marginTop: "0.5em" }}>
                <IconButton onClick={handleCopyIds}>
                  <CopyAll />
                </IconButton>
              </Grid>
              <Grid item>
                <Box sx={{ marginTop: "1em" }}>
                  <a
                    href={RENDER_BASE + `api/render/template-trackingcolumns`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#888" }}
                  >
                    <Grid container>
                      <Grid item>
                        <Download />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          Download Template
                        </Typography>
                      </Grid>
                    </Grid>
                  </a>
                </Box>
              </Grid>
              <Grid item style={{ marginTop: "0.5em" }}>
                <Button variant="contained" component="label">
                  OPEN FILE
                  <input
                    hidden
                    accept="*"
                    type="file"
                    onChange={(e) =>
                      e.target.files?.length &&
                      handleFileChange(e.target.files[0])
                    }
                  />
                </Button>
                {file ? (
                  <IconButton
                    onClick={() => {
                      setFile(undefined);
                      setImport(undefined);
                    }}
                  >
                    <Cancel />
                  </IconButton>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}

export default OrderDetail;
