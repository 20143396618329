import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useFormContext} from "react-hook-form";

export default function CouponCode() {
  const formMethods = useFormContext()

  return <Grid container>
    <Grid item xs={12}>
      <h4>Discount Code</h4>
    </Grid>
    <Grid item xs={12}>
      <TextField
        variant="outlined"
        placeholder="Enter Code Here"
        fullWidth
        {...formMethods.register("couponCode")}
      />
    </Grid>
  </Grid>

}