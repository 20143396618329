import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useEasySnackbar } from "../../lib/snackbar";

import {
  AnalyteHealthRangeAnalyte,
  HealthProfileFragment,
  MyProfileDocument,
  useCreateHealthProfileMutation,
  useGetApplicationSettingsQuery,
  useUpdateHealthProfileMutation,
} from "../../generated/graphql";

// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import AnalyteHealthProfileForm from "./AnalyteHealthProfileForm";
import { ContentCopy } from "@mui/icons-material";

export type UpdateHealthProfileDialogProps = {
  existingHealthProfile: HealthProfileFragment;
  duplicate?: Boolean
};

interface UpdateAnalyteHealthRangeInput {
  analyte: AnalyteHealthRangeAnalyte;
  min: number;
  low: number;
  high: number;
  max: number;
}

export function UpdateHealthProfileDialog({
  existingHealthProfile, duplicate
}: UpdateHealthProfileDialogProps) {

  const [open, setOpen] = useState(false);
  const snackbar = useEasySnackbar();

  const [createHealthProfile] = useCreateHealthProfileMutation({
    refetchQueries: [
      {
        query: MyProfileDocument,
      },
    ],
  });

  const analytes = useGetApplicationSettingsQuery()?.data?.getApplicationSettings?.analytes

  const getAnalyte = useCallback((name: string) => {
    return analytes?.find(a => a.id.toLowerCase() === name.toLowerCase())
  }, [analytes])

  const rangeToFormValues = useCallback((range: UpdateAnalyteHealthRangeInput) => {
    return getAnalyte(range.analyte)?.measurementUnit === "percent" ? {
      analyte: range.analyte as AnalyteHealthRangeAnalyte,
      min: (range.min ? range.min / 10000 : 0),
      low: (range.low ? range.low / 10000 : 0),
      high: (range.high ? range.high / 10000 : 0),
      max: (range.max ? range.max / 10000 : 0)
    } : range;
  }, [getAnalyte]);

  const rangeFromFormValues = (range: UpdateAnalyteHealthRangeInput) => getAnalyte(range.analyte)?.measurementUnit === "percent" ? ({
    analyte: range.analyte as AnalyteHealthRangeAnalyte,
    min: (range.min ? range.min * 10000 : 0),
    low: (range.low ? range.low * 10000 : 0),
    high: (range.high ? range.high * 10000 : 0),
    max: (range.max ? range.max * 10000 : 0)
  }) : range

  const formMethods = useForm<HealthProfileFragment>();

  const [updateHealthProfile] = useUpdateHealthProfileMutation({
    refetchQueries: [
      {
        query: MyProfileDocument,
      },
    ],
  });

  const _handleSubmit = async (input: HealthProfileFragment) => {
    try {
      const { __typename, testType, ...submissionInput } = input;

      // Further process analyteRanges if needed to fit the expected type
      const processedAnalyteRanges = submissionInput.analyteRanges?.map(range => {
        const { __typename, ...rest } = range;
        return rangeFromFormValues(rest);
      });

      if (duplicate) {
        await createHealthProfile({
          variables: {
            input: {
              title: submissionInput.title,
              testType: existingHealthProfile.testType,
              stage: submissionInput.stage === "A_" ? null : submissionInput.stage,
              cultivar: submissionInput.cultivar,
              analyteRanges: processedAnalyteRanges,
            },
          },
        });
        snackbar.showSuccess("Health Profile was successfully duplicated");
      } else {
        await updateHealthProfile({
          variables: {
            input: {
              ...submissionInput,
              id: existingHealthProfile?.id,
              stage: submissionInput.stage === "A_" ? null : submissionInput.stage,
              analyteRanges: processedAnalyteRanges,
            },
          },
        });
        snackbar.showSuccess("Health Profile was successfully edited");
      }
      setOpen(false);
    } catch (e) {
      snackbar.showError(e, "There was an error editing this health profile.");
    }
  };

  const { reset } = formMethods;

  useEffect(() => {
    reset({
      title: duplicate ? "" : existingHealthProfile.title,
      testType: existingHealthProfile.testType,
      stage: existingHealthProfile.stage,
      cultivar: existingHealthProfile.cultivar,
      analyteRanges: existingHealthProfile.analyteRanges?.map(range => (rangeToFormValues(range))).reverse(),
    });
  }, [existingHealthProfile, reset, duplicate, rangeToFormValues]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        {duplicate ? <ContentCopy /> : <Edit />}
      </IconButton>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <form noValidate onSubmit={formMethods.handleSubmit(_handleSubmit)}>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item>Edit Health Profile</Grid>
              <Grid item>
                {/* TODO add deactivate button */}
              </Grid>
            </Grid>
          </DialogTitle>
          <AnalyteHealthProfileForm formMethods={formMethods} existinghealthProfile={existingHealthProfile} />
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Exit</Button>
            <Button type="submit" variant="contained">
              Save Changes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default UpdateHealthProfileDialog;
