import {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useEasySnackbar} from "../../lib/snackbar";

import {
  ClientCompanyFieldsFragment,
  GetClientCompanyDocument,
  useUpdateClientCompanyMutation, useVerifyAddressMutation, VerifyAddressError,
} from "../../generated/graphql";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {USStates} from "../../lib/states";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";

type ClientCompanyDetailFormProps = {
  company: ClientCompanyFieldsFragment;
};
export const ClientCompanyDetailForm: FC<ClientCompanyDetailFormProps> = ({
                                                                            company,
                                                                          }) => {
  const {register, handleSubmit, getValues, watch} = useForm<ClientCompanyFieldsFragment>({
    defaultValues: company,
  });

  const [verified, setVerified] = useState<boolean | undefined>();
  const [errors, setErrors] = useState<VerifyAddressError[]>();

  const [updateClientCompany] = useUpdateClientCompanyMutation({
    refetchQueries: [
      {
        query: GetClientCompanyDocument,
        variables: {
          id: company.id,
        },
      },
    ],
  });

  const [verifyAddress] = useVerifyAddressMutation();

  const snackbar = useEasySnackbar();

  const _handleValidateAddress = async () => {
    const input = getValues();
    try {
      const reply = await verifyAddress({
        variables: {
          input: {
            id: company.id,
            addressStreet: input.addressStreet,
            addressStreet2: input.addressStreet2,
            addressCity: input.addressCity,
            addressState: input.addressState,
            addressZip: input.addressZip,
            phone: input.phone,
            businessName: input.businessName,
          }
        }
      })
      setVerified(reply.data?.verifyAddress?.success || false);
      setErrors(reply.data?.verifyAddress?.errors || [])
      if (reply.data?.verifyAddress?.success)
        snackbar.showSuccess("Address is valid for shipping")
      else
        snackbar.showError(null, "Address is invalid for shipping")
    } catch (e) {
      setVerified(false)
      setErrors([{message: (e as Error).message, code: "UNKNOWN"}])
      snackbar.showError(e, "Address is invalid")
    }
  }

  const registerAndVerify = (name: keyof ClientCompanyFieldsFragment) => {
    return register(name);
  }

  const _handleSubmit = async (input: ClientCompanyFieldsFragment) => {
    try {
      await updateClientCompany({
        variables: {
          input: {
            id: company.id,
            addressStreet: input.addressStreet,
            addressStreet2: input.addressStreet2,
            addressCity: input.addressCity,
            addressState: input.addressState,
            addressZip: input.addressZip,
            billingEmail: input.billingEmail,
            phone: input.phone,
          },
        },
      });
      snackbar.showSuccess("Client Company was successfully updated");
    } catch (e) {
      snackbar.showError(e, "There was an error updating this Client Company");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(_handleSubmit)}
      noValidate
      style={{marginTop: "1em"}}
    >
      <Typography variant="body1" fontWeight={"bold"}>
        Address:
      </Typography>
      <Grid container spacing={2} mt={0.5} mb={1}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={"Address Line 1"}
            {...registerAndVerify("addressStreet")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={"Address Line 2"}
            {...registerAndVerify("addressStreet2")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={"City"}
            {...registerAndVerify("addressCity")}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={"State"}
            {...registerAndVerify("addressState")}
            defaultValue={company.addressState}
            select
          >
            {
              USStates.map(state => (
                <MenuItem key={state.code} value={state.code}>{state.code}</MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label={"Zip Code"}
            {...registerAndVerify("addressZip")}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" fontWeight={"bold"}>
        Billing Email:
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        sx={{mb: 2}}
        {...register("billingEmail")}
      />

      <Typography variant="body1" fontWeight={"bold"}>
        Phone:
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        sx={{mb: 2}}
        {...registerAndVerify("phone")}
      />
      {errors?.length ?
        <Grid container>
          <Grid item xs={12}>
            One or more address errors prevent shipping to this address:
          </Grid>
          {errors.map((error, i) => (
            <Grid item xs={12} key={i}>
              {error.message}
            </Grid>
          ))}
        </Grid> : null}
      {company.easypostAddressId ?
        <Chip color="success" label={"Address Verified"}/> :
        <Chip color="warning" label={"USPS Couldn't Verify This Address, Shipping Disabled"}/>
      }
      <Button
        color="primary"
        variant="contained"
        size="small"
        type="submit"
        sx={{float: "right"}}
      >
        Update Company
      </Button>
    </form>
  );
};
