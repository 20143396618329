import {FC, PropsWithChildren, useEffect} from "react";
import {useForm, useFieldArray, FormProvider} from "react-hook-form";

import {
  DataTableContainerControls,
  DataTableProviderProps,
  DEFAULT_PER_PAGE,
} from "./types";

import Box from "@mui/material/Box";

export const DataTableProvider: FC<
  PropsWithChildren<DataTableProviderProps>
> = ({children, defaultControls, allColumnNames, backendTable, backendPagingResponse}) => {
  const defaultValues = {
    page: defaultControls?.page ?? 0,
    perPage: defaultControls?.perPage ?? DEFAULT_PER_PAGE,
    showColumns:
      defaultControls?.showColumns ?? allColumnNames.map((name) => ({name})),
    sortBy: defaultControls?.sortBy || "",
    sortOrder: defaultControls?.sortOrder || "asc",
    view: defaultControls?.view || "",
  };
  const methods = useForm<DataTableContainerControls>({defaultValues});
  const showColumns = useFieldArray({
    name: "showColumns",
    control: methods.control,
  });
  const defaultColumnNames = defaultControls?.showColumns?.length
    ? defaultControls.showColumns.map(({name}) => name)
    : allColumnNames;

  const backendPaging = backendTable?.options?.paging

  function defaultShowColumn(name: string) {
    const column = defaultControls?.showColumns?.find((c) => c.name === name);
    if (column === undefined || column?.show === undefined) return true;
    if (!column?.name) return false;
    return column.show;
  }

  useEffect(() => {
    methods.reset(defaultValues);
  }, []);

  useEffect(() => {
    if (defaultControls && Object.keys(defaultControls).length > 0) {
      Object.entries(defaultControls).map(([key, value]) =>
        methods.setValue(key as keyof DataTableContainerControls, value)
      );
      if (defaultColumnNames.length) {
        showColumns.fields.forEach((field) => showColumns.remove());
        allColumnNames.forEach((name) =>
          showColumns.append({name, show: defaultShowColumn(name)})
        );
      }
    }
  }, [JSON.stringify(defaultControls), JSON.stringify(defaultColumnNames)]);

  const perPage = methods.getValues("perPage") || DEFAULT_PER_PAGE
  const page = methods.getValues("page") || 0

  useEffect(() => {
    if (backendPaging?.size && backendPaging?.size !== perPage)
      methods.setValue("perPage", backendPaging.size);
    if (backendPaging?.start && backendPaging?.start !== page)
      methods.setValue("page", backendPaging.start / perPage);

  }, [backendPaging?.size, backendPaging?.start])

  useEffect(() => {
    const sortOrder = !backendPaging?.ordering?.startsWith("-") ? "asc" : "desc"
    const sortBy = backendPaging?.ordering?.replace(/^-/, "")
    if (backendPaging?.ordering && sortBy && sortBy !== methods.getValues("sortBy"))
      methods.setValue("sortBy", sortBy);
    if (backendPaging?.ordering && sortOrder && sortOrder !== methods.getValues("sortOrder"))
      methods.setValue("sortOrder", sortOrder);
  }, [backendPaging?.ordering])

  useEffect(() => {
    const search = backendTable?.options?.filters?.search
    if (search && search !== methods.getValues("search"))
      methods.setValue("search", search);
  }, [backendTable?.options?.filters?.search])


  return (
    <FormProvider {...methods}>
      <Box style={{display: "none"}}>
        <input {...methods.register("page", {valueAsNumber: true})} />
        <input {...methods.register("perPage", {valueAsNumber: true})} />
        <input {...methods.register("sortBy")} />
        <input {...methods.register("sortOrder")} />
        <input {...methods.register("search")} />
        <input {...methods.register("view")} />
        {showColumns.fields.map((column, index) => {
          return (
            <Box id={column.id} key={column.id}>
              <input {...methods.register(`showColumns.${index}.name`)} />
              <input
                {...methods.register(`showColumns.${index}.show`)}
                type="checkbox"
              />
            </Box>
          );
        })}
      </Box>
      {children}
    </FormProvider>
  );
};
