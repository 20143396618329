import FullPageLayout from "../../components/Layouts/FullPageLayout";
import { LoginForm } from "../../components/LoginForm";
import {useAnonymousRequired} from "../../lib/auth";

export const Login = () => {
  useAnonymousRequired();
  return (
    <FullPageLayout>
      <LoginForm />
    </FullPageLayout>
  );
};

export default Login;
