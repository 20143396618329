import { useNavigate } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";
import { camelCase, get, identity } from "lodash";
import { STAGE_OPTIONS } from "../types/types";
import { exportCSV } from "../lib/exportCSV";
import { ProductLabels } from "../lib/testTypes";
import { RENDER_BASE } from "../lib/assets";

import {
  analyzeHealth,
  useFormatAnalyte,
  useHealthAnalysisClasses,
} from "../lib/analytes";

import {
  AnalyteInfoFragment,
  SampleFullFieldsFragment,
  SampleStatus,
  useGetApplicationSettingsQuery,
  useSampleFilterResultsQuery,
} from "../generated/graphql";

// components
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { DataTable } from "../components/DataTable";
import {
  ColumnDefinition,
  DataTableContainerControls,
  ShowColumn,
} from "../components/DataTable/types";
import SampleFilterCard, {
  useSampleFilterVariables,
} from "../components/SampleFilterCard";
import HTMLTitle from "../components/HTMLTitle";

// mui
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import FileDownload from "@mui/icons-material/FileDownload";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAuthenticated } from "../lib/auth";

export const Reports = () => {
  const navigate = useNavigate();
  const [filters] = useSampleFilterVariables();

  const settings =
    useGetApplicationSettingsQuery()?.data?.getApplicationSettings;

  const classes = useHealthAnalysisClasses();
  const formatAnalyte = useFormatAnalyte();

  const samplesQuery = useSampleFilterResultsQuery({
    variables: { filters: { ...filters, status: SampleStatus.Complete } },
  });

  const samples = samplesQuery.data?.getSamples;
  const healthProfiles = useAuthenticated()?.lab?.healthProfiles

  const analytes = (
    settings?.testAnalytes.find((t) => t.testType === filters.testType)
      ?.analytes || []
  )
    .map((id) => settings?.analytes.find((a) => a.id === id))
    .filter(identity) as AnalyteInfoFragment[];

  const analyteColumns: ColumnDefinition<SampleFullFieldsFragment>[] =
    analytes.map(
      (analyte) =>
        ({
          name: analyte?.id,
          getValue: (sample) => {
            const field = camelCase(`result_${analyte.id}`);
            return parseFloat(get(sample, field) as string);
          },
          render: (sample) => {
            if (!analyte?.id) return null;
            const field = camelCase(`result_${analyte.id}`);
            const value = parseFloat(get(sample, field) as string);
            const healthProfileId = sample.healthProfileId
            const healthProfile = healthProfiles?.find(hp => hp.id === healthProfileId)
            const health =
              healthProfile && analyzeHealth(healthProfile, analyte.id, value);
            return (
              <Tooltip title={health || ""}>
                <Box className={health ? classes[health] : undefined}>
                  {formatAnalyte(analyte.id, value)}
                </Box>
              </Tooltip>
            );
          },
          label: (
            <div dangerouslySetInnerHTML={{ __html: analyte?.label || "" }} />
          ),
        } as ColumnDefinition<SampleFullFieldsFragment>)
    );

  const columns: ColumnDefinition<SampleFullFieldsFragment>[] = [
    {
      name: "results",
      label: "Results",
      align: "center",
      render: (sample) => {
        return (
          <Tooltip title="Open PDF" arrow placement="top">
            <a
              href={
                RENDER_BASE + `api/render/sample_certificate-${sample.id}.pdf`
              }
              target="_blank"
              rel="noreferrer"
              style={{ color: "#888" }}
            >
              <PictureAsPdf />
            </a>
          </Tooltip>
        );
      },
    },
    {
      name: "id",
      label: "ID",
    },
    {
      name: "collectionDate",
      label: "Collection Date",
    },
    {
      name: "cultivar",
      label: "Cultivar",
    },
    {
      name: "testType",
      label: "Test Type",
      render: (sample) => {
        return <HTMLTitle value={get(ProductLabels, sample?.testType) || ""} />;
      },
    },
    {
      name: "stage",
      label: "Stage",
      render: (sample) => {
        return (
          STAGE_OPTIONS.find((option) => option.value === sample.stage)
            ?.label || ""
        );
      },
    },
    ...analyteColumns,
  ];

  return (
    <FullPageLayout>
      <Box textAlign="center">
        <h1>Reports</h1>
      </Box>

      <SampleFilterCard />

      {samples?.length ? (
        <Card sx={{ padding: "1em", marginBottom: "2em" }}>
          <DataTable<SampleFullFieldsFragment>
            data={samples}
            columns={columns}
            defaultControls={{
              showColumns: analytes.map((a) => ({
                name: a?.id || "",
                show: true,
              })),
            }}
            options={{
              extraButtons: [
                <AnalyteSwitches analytes={analytes} />,
                <Button
                  startIcon={<FileDownload />}
                  onClick={() =>
                    samples?.length &&
                    exportCSV(
                      samples,
                      Object.values(filters)
                        .filter((v) => v)
                        .reduce((name, v) => `${name} ${v}`)
                        .trim()
                    )
                  }
                >
                  Export
                </Button>,
              ],
            }}
          />
        </Card>
      ) : (
        <>
          <h3>You have no reports.</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/checkout")}
          >
            Order Now
          </Button>
        </>
      )}
    </FullPageLayout>
  );
};

function AnalyteSwitches({ analytes }: { analytes: AnalyteInfoFragment[] }) {
  const methods = useFormContext<DataTableContainerControls>();
  const showColumns = useWatch({
    name: "showColumns",
    control: methods.control,
  }) as ShowColumn[];

  function groupAnalytes(group: string) {
    return analytes.filter((a) => a.group === group);
  }

  function wholeGroupIsChecked(group: string) {
    return groupAnalytes(group).every((a) =>
      Boolean(showColumns.find((c) => c.name === a.id && c.show !== false))
    );
  }

  function setGroup(group: string, show: boolean) {
    analytes
      .filter((a) => a.group === group)
      .forEach((a) => {
        const showColumnIndex = showColumns.findIndex(
          ({ name }) => name === a.id
        );
        const field = `showColumns.${showColumnIndex}.show` as const;
        methods.setValue(field, show);
      });
  }

  return (
    <>
      {groupAnalytes("primary").length ? (
        <FormControlLabel
          control={<Switch />}
          label={"Primary"}
          checked={wholeGroupIsChecked("primary")}
          onChange={(e, show) => setGroup("primary", show)}
        />
      ) : null}
      {groupAnalytes("secondary").length ? (
        <FormControlLabel
          control={<Switch />}
          label={"Secondary"}
          checked={wholeGroupIsChecked("secondary")}
          onChange={(e, show) => setGroup("secondary", show)}
        />
      ) : null}
      {groupAnalytes("micro").length ? (
        <FormControlLabel
          control={<Switch />}
          label={"Micro"}
          checked={wholeGroupIsChecked("micro")}
          onChange={(e, show) => setGroup("micro", show)}
        />
      ) : null}
      {groupAnalytes("detectables").length ? (
        <FormControlLabel
          control={<Switch />}
          label={"Detectables"}
          checked={wholeGroupIsChecked("detectables")}
          onChange={(e, show) => setGroup("detectables", show)}
        />
      ) : null}
      {groupAnalytes("heavy").length ? (
        <FormControlLabel
          control={<Switch />}
          label={"Heavy Metals"}
          checked={wholeGroupIsChecked("heavy")}
          onChange={(e, show) => setGroup("heavy", show)}
        />
      ) : null}
    </>
  );
}

export default Reports;
