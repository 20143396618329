import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { clearAuthAndLogout, useAuthenticated } from "../../lib/auth";
import { LogoBox } from "../Logo/LogoBox";

// mui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import Help from "@mui/icons-material/Help";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Science from "@mui/icons-material/Science";
import AccountBox from "@mui/icons-material/AccountBox";
import Business from "@mui/icons-material/Business";
import Typography from "@mui/material/Typography/Typography";
import Chip from "@mui/material/Chip";

type NavPage = {
  title?: string;
  route: string;
};

const INTERNAL_STAFF_PAGES: NavPage[] = [
  {
    route: "staff/labs",
    title: "Manage Labs",
  },
];

const LAB_STAFF_PAGES: NavPage[] = [
  {
    route: "lab/manage-orders",
    title: "Manage Orders",
  },
  {
    route: "lab/samples",
    title: "Samples",
  },
  {
    route: "lab/client-companies",
    title: "Client Companies",
  },
  {
    route: "lab/upload-results",
    title: "Upload Results",
  },
];
const ANONYMOUS_PAGES: NavPage[] = [
  {
    route: "contact",
    title: "Contact Us",
  },
];

const CLIENT_PAGES: NavPage[] = [
  {
    route: "checkout",
    title: "Buy Tests",
  },
  {
    route: "orders",
    title: "Orders",
  },
  {
    route: "reports?testType=PLANT_TISSUE",
    title: "Reports",
  },
  {
    route:
      "analytics?testType=PLANT_TISSUE&groupingColumn=cultivar&analyte=result_nitrogen&stage=&xAxis=collection_date",
    title: "Analytics",
  },
];

export type Navbars = "internal" | "lab" | "client" | "anonymous";

const NavbarLinks: Record<Navbars, NavPage[]> = {
  internal: INTERNAL_STAFF_PAGES,
  lab: LAB_STAFF_PAGES,
  client: CLIENT_PAGES,
  anonymous: ANONYMOUS_PAGES,
};

export const NavBar = () => {
  const { profile, lab, labStaff, loading } = useAuthenticated();
  const navigate = useNavigate();

  const loggedIn = profile?.email;
  const navbar = labStaff ? "lab" : loggedIn ? "client" : "anonymous"

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (page: string) => {
    setAnchorElNav(null);
    navigate(page);
  };


  const links = [
    ...NavbarLinks[navbar].filter(
      (p) => lab?.checkoutEnabled || !p.route.includes("checkout")
    ),
  ];

  useEffect(() => {
    if (loading) return;
  }, [loggedIn, loading]);

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {links.map((page) => (
                <MenuItem
                  key={page.route}
                  onClick={() => handleNavigate(`/${page.route}`)}
                >
                  <Typography textAlign="center">
                    {page.title || page.route}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <LogoBox navbar={navbar} />

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {links.map((page) => (
              <Button
                key={page.route}
                onClick={() => handleNavigate(`/${page.route}`)}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                {page.title || page.route}
              </Button>
            ))}
          </Box>
          {navbar === "lab" ? (
            <Chip label="Lab Management" style={{ marginRight: "1em" }} />
          ) : null}

          <UserAvatar />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

function UserAvatar() {
  const { profile } = useAuthenticated();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const impersonating = localStorage.getItem("impersonate");
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = () => {
    handleCloseUserMenu();
    clearAuthAndLogout();
    window.location.pathname = "/login";
  };

  const stopImpersonating = () => {
    localStorage.setItem("impersonate", "");
    window.location.pathname = "/";
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      {profile?.email ? (
        <>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt={profile?.lastName}
                sx={{ bgcolor: theme.palette.primary.main }}
              >
                {profile?.firstName[0]?.toUpperCase()}
                {profile?.lastName[0]?.toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {profile.isStaff ? (
              <MenuItem onClick={() => navigate("/staff/labs")}>
                <ListItemIcon>
                  <AdminPanelSettings fontSize="small" />
                </ListItemIcon>
                <ListItemText>Internal Staff</ListItemText>
              </MenuItem>
            ) : null}
            {profile.staffAtLaboratory ? (
              <MenuItem onClick={() => navigate("/lab/lab-settings")}>
                <ListItemIcon>
                  <Science fontSize="small" />
                </ListItemIcon>
                <ListItemText>Lab Settings</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => navigate("/company-profile")}>
                <ListItemIcon>
                  <Business fontSize="small" />
                </ListItemIcon>
                <ListItemText>Company Settings</ListItemText>
              </MenuItem>
            )}

            <MenuItem onClick={() => navigate("/user-profile")}>
              <ListItemIcon>
                <AccountBox fontSize="small" />
              </ListItemIcon>
              <ListItemText>User Profile</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => navigate("/contact")}>
              <ListItemIcon>
                <Help fontSize="small" />
              </ListItemIcon>
              <ListItemText>Contact Support</ListItemText>
            </MenuItem>
            {impersonating ? (
              <MenuItem onClick={stopImpersonating}>
                <ListItemIcon>
                  <ExitToApp fontSize="small" />
                </ListItemIcon>
                <ListItemText>Stop Impersonating</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => logOut()}>
                <ListItemIcon>
                  <ExitToApp fontSize="small" />
                </ListItemIcon>
                <ListItemText>Log Out</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </>
      ) : smallScreen ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}
          size="small"
        >
          Login
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}
        >
          Login
        </Button>
      )}
    </Box>
  );
}
