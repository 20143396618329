import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import FullPageLayout from "../components/Layouts/FullPageLayout";

// mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const Pricing = () => {
  const navigate = useNavigate();

  return (
    <FullPageLayout>
      <Box textAlign="center">
        <h1>Pricing</h1>

        <Grid
          container
          justifyContent={"center"}
          spacing={3}
          alignItems="stretch"
        >
          <Grid item xs={12} sm={6} md={4}>
            <PricingCard
              text="For orders of 20 tests or less (1 to 4 kits)."
              price="$75"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PricingCard
              text="For orders of 25 tests up to 100 (5 to 20 kits)."
              price="$65"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PricingCard
              text="For orders of more than 100 tests, please"
              link={<Link style={{ textDecoration: 'none', color: "#aed581"}} to="/contact">contact our team!</Link>}
              price="$-"
            />
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          sx={{
            fontSize: "20px",
            padding: "0.5em 1em",
            margin: '2em 0'
          }}
          onClick={() => navigate("/register")}
        >
          Register Now
        </Button>
      </Box>
    </FullPageLayout>
  );
};

export default Pricing;

export type PricingCardProps = {
  price: string;
  text: string;
  link?: any;
};

export const PricingCard: FC<PricingCardProps> = ({ price, text, link }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <Box sx={{ height: "100%", padding: "4em" }}>
        <Grid container direction="column" alignItems={"center"}>
          <Grid item>
            <h1 style={{ margin: 0, fontSize: "50px", color: "#aed581" }}>
              {price}
            </h1>
          </Grid>
          <Grid item>
            <Typography variant="body2">per test</Typography>
          </Grid>
          <Grid item style={{ marginTop: "2em", width: "95%" }}>
            <Typography variant="body1">
              {text} {link ? link : null}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
