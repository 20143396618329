import {useState} from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import copy from "copy-to-clipboard";
import {countBy, get, omit} from "lodash";
import FullPageLayout from "../../components/Layouts/FullPageLayout";

import {Loading} from "../../components/Loading";
import {useEasySnackbar} from "../../lib/snackbar";
import {exportCSV} from "../../lib/exportCSV";
import {useAuthenticated} from "../../lib/auth";
import {useVisibleTrackingColumns} from "../../lib/trackingColumns";
import {ColumnDefinition, useBackendTable} from "../../components/DataTable/types";
import {formatPrice} from "../../lib/testTypes";
import {DataTable} from "../../components/DataTable";
import {OrderPaymentStatuses, OrderProcessStatuses, OrderStatusProps,} from "../../lib/types";

import {
  OrderPaymentStatus,
  OrderProcessStatus, OrderQuerysetOptionsInput,
  OrderWithSamplesFragment,
  SampleSummaryFieldsFragment,
  useGetOrdersQuery,
  useUpdateOrderPaymentStatusMutation,
  useUpdateOrderProcessStatusMutation,
} from "../../generated/graphql";

import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ListAlt from "@mui/icons-material/ListAlt";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Save from "@mui/icons-material/Save";
import Edit from "@mui/icons-material/Edit";
import Label from "@mui/icons-material/Label";
import FileDownload from "@mui/icons-material/FileDownload";
import CopyAll from "@mui/icons-material/CopyAll";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import CardHeader from "@mui/material/CardHeader";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Payment from "@mui/icons-material/Payment";
import {AppRegistration} from "@mui/icons-material";

function getValue(sample: SampleSummaryFieldsFragment, key: string) {
  const value = get(sample, key);
  if (value === null || value === "A_") {
    return "";
  }
  return value.toString();
}

export function CopySampleInfoButton({
                                       order,
                                     }: {
  order: OrderWithSamplesFragment;
}) {
  const [open, setOpen] = useState(false);
  const [showHeaders, setShowHeaders] = useState(false);
  const snackbar = useEasySnackbar();
  const trackingColumns = useVisibleTrackingColumns();

  const doCopy = (text: string, descriptor: string) => {
    copy(text, {format: "text"});
    snackbar.showSuccess(
      `${order.samples.length} ${descriptor} copied to clipboard.`
    );
  };

  const handleCopyIds = () => {
    const rows = [
      ...(showHeaders ? ["Sample ID"] : []),
      ...order.samples.map((s) => s.id),
    ];
    doCopy(rows.join("\n"), "IDs");
  };
  const handleCopyDetails = () => {
    const columns = ["id", ...trackingColumns];
    const rows = [
      ...(showHeaders ? [["Sample ID", ...trackingColumns].join("\t")] : []),
      ...order.samples.map((s) =>
        columns.map((r) => getValue(s, r) || "").join("\t")
      ),
    ];
    doCopy(rows.join("\n"), "sample details");
  };

  return (
    <>
      <Tooltip title="Copy Sample Info">
        <IconButton color="primary" onClick={() => setOpen(true)}>
          <CopyAll/>
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Card>
          <CardHeader
            title="Copy Sample Info"
            subheader={`Order ${order.id} - ${order.samples.length} samples`}
          />
          <CardContent>
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={showHeaders}/>}
                  label={"Include Header Row?"}
                  onChange={() => setShowHeaders(!showHeaders)}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCopyIds}
                >
                  Copy Sample Test IDs
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCopyDetails}
                >
                  Copy Sample Test Tracking Info
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}

export function getStatusLabel(
  findStatus: string,
  choices: OrderStatusProps[]
) {
  const foundStatus = choices.find(
    (s) =>
      s.name.replaceAll("_", "").toUpperCase() ===
      findStatus.replaceAll("_", "").toUpperCase()
  );
  return foundStatus?.label ?? findStatus.replaceAll("_", " ");
}

export default function LabManageOrders() {
  const [editProcessStatus, setEditProcessStatus] = useState<string>();
  const [editPaymentStatus, setEditPaymentStatus] = useState<string>();
  const [newProcessStatus, setNewProcessStatus] = useState<string>("");
  const [newPaymentStatus, setNewPaymentStatus] = useState<string>("");

  const [updateOrderProcessStatus] = useUpdateOrderProcessStatusMutation();
  const [updateOrderPaymentStatus] = useUpdateOrderPaymentStatusMutation();

  const snackbar = useEasySnackbar();

  const [searchParams] = useSearchParams();
  const labId = searchParams.get("labId");
  const {lab: myLab} = useAuthenticated();
  const backendTable = useBackendTable<OrderQuerysetOptionsInput>({
    filters: {
      laboratoryId: labId || (myLab?.id as string),
      clientCompanyId: "all",
    }
  })

  const getOrdersQuery = useGetOrdersQuery({
    variables: {options: backendTable.options},
  });


  const orders = getOrdersQuery.data?.getOrders;

  const navigate = useNavigate();

  const handleSaveProcessStatus = (orderId: string) => {
    try {
      const willLock = ([
        OrderProcessStatus.Complete,
      ].includes(newProcessStatus as OrderProcessStatus))
      const proceed = willLock ? window.confirm(`Mark order as ${newProcessStatus}? This will lock the order details and can't be undone!`) : true
      if (proceed) {
        updateOrderProcessStatus({
          variables: {
            input: {
              orderId,
              processStatus: newProcessStatus,
            },
          },
        })
        snackbar.showSuccess("Order Status updated successfully.");
      }
      setEditProcessStatus("");
    } catch (e) {
      console.error(e);
      snackbar.showError(e, "Error updating status of this order.");
    }
  };

  const handleSavePaymentStatus = (orderId: string) => {
    try {
      const willLock = ([
        OrderPaymentStatus.Paid,
        OrderPaymentStatus.Voided
      ].includes(newPaymentStatus as OrderPaymentStatus))
      const proceed = willLock ? window.confirm(`Mark order as ${newPaymentStatus}? This will lock the order details and can't be undone!`) : true
      if (proceed) {
        updateOrderPaymentStatus({
          variables: {
            input: {
              orderId,
              paymentStatus: newPaymentStatus,
            },
          },
        })
        snackbar.showSuccess("Payment Status updated successfully.");
      }
      setEditPaymentStatus("");
    } catch (e) {
      console.error(e);
      snackbar.showError(e, "Error updating payment status of this order.");
    }
  };

  const {lab} = useAuthenticated();

  function exportOrderColumns(order: OrderWithSamplesFragment | null) {
    if (!order?.id) return;
    const testCounts = countBy(order?.samples, (s) => s.testType);
    return {
      "Order ID": order.id,
      clientCompanyName: order?.clientCompany?.businessName,
      ...omit(order, [
        "samples",
        "laboratory",
        "clientCompany",
        "shipmentToLab",
        "shipmentToBuyer",
      ]),
      ...lab?.testTypesEnabled?.reduce(
        (acc, testType) => ({
          ...acc,
          [testType]: get(testCounts, testType) || 0,
        }),
        {}
      ),
    };
  }

  const exportOrderCSV = () => {
    exportCSV(orders?.items.map(exportOrderColumns), "orders.csv");
  };

  const columns: ColumnDefinition<OrderWithSamplesFragment>[] = [
    {
      name: "orderDate",
      label: "Ordered",
    },
    {
      name: "id",
      label: "Order ID",
      render: (order) => {
        return (
          <>
            <Link to={`/orders/${order.id}`}>{order?.id}</Link>
            <CopySampleInfoButton order={order}/>
          </>
        );
      },
    },
    {
      name: "clientCompany.businessName",
      label: "Company",
    },
    {
      name: "paymentStatus",
      label: "Payment Status",
      render: (order) => {
        return editPaymentStatus === order.id ? (
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <TextField
                variant="outlined"
                select
                fullWidth
                defaultValue={order.paymentStatus}
                onChange={(e) => setNewPaymentStatus(e.target.value)}
              >
                {Object.keys(OrderPaymentStatus)
                  .filter((s) => s !== "A")
                  .map((status) => (
                    <MenuItem
                      value={get(OrderPaymentStatus, status)}
                      key={status}
                    >
                      {getStatusLabel(status, OrderPaymentStatuses)}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <Tooltip title="Save Payment Status">
                <IconButton
                  color="primary"
                  onClick={() => handleSavePaymentStatus(order.id)}
                >
                  <Save/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              {getStatusLabel(order.paymentStatus, OrderPaymentStatuses)}
              {order.paymentStatus === OrderPaymentStatus.Unpaid ?
                <Tooltip title={"Copy Payment URL"}>
                  <IconButton color="primary" onClick={() => {
                    navigator.clipboard.writeText(window.location.protocol + "://" + window.location.hostname + "/pay/" + order.id);
                    snackbar.showSuccess("Payment URL copied to clipboard.");
                  }
                  }>
                    <Payment/>
                  </IconButton>
                </Tooltip>
                : null}
            </Grid>
            {[OrderPaymentStatus.Paid, OrderPaymentStatus.Voided].includes(order.paymentStatus) || <Grid item>
                <Tooltip title="Edit Payment Status">
                    <IconButton
                        onClick={() => setEditPaymentStatus(order.id)}
                        color="primary"
                    >
                        <Edit/>
                    </IconButton>
                </Tooltip>
            </Grid>}
          </Grid>
        );
      },
    },
    {
      name: "processStatus",
      label: "Process Status",
      render: (order) => {
        return editProcessStatus === order.id ? (
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <TextField
                variant="outlined"
                select
                fullWidth
                defaultValue={order.processStatus}
                onChange={(e) => setNewProcessStatus(e.target.value)}
              >
                {Object.keys(OrderProcessStatus)
                  .filter((s) => s !== "A" && s !== "CheckoutIncomplete")
                  .map((status) => (
                    <MenuItem
                      value={get(OrderProcessStatus, status)}
                      key={status}
                    >
                      {getStatusLabel(status, OrderProcessStatuses)}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <Tooltip title="Save Order Status">
                <IconButton
                  color="primary"
                  onClick={() => handleSaveProcessStatus(order.id)}
                >
                  <Save/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              {getStatusLabel(order.processStatus, OrderProcessStatuses)}
            </Grid>
            {["CANCELED", "COMPLETE"].includes(order.processStatus) || <Grid item>
                <Tooltip title="Edit Order Status">
                    <IconButton
                        onClick={() => setEditProcessStatus(order.id)}
                        color="primary"
                    >
                        <Edit/>
                    </IconButton>
                </Tooltip>
            </Grid>}
          </Grid>
        );
      },
    },
    {
      name: "samplesOrdered",
      label: "# of Tests",
    },
    {
      name: "totalPriceInCents",
      label: "Price",
      render: (order) => {
        return formatPrice(order.totalPriceInCents);
      },
    },
    {
      name: "edit",
      label: "Edit",
      sortable: false,
      render: (order) => {
        const disabled = order.processStatus === OrderProcessStatus.Complete || order.paymentStatus === OrderPaymentStatus.Paid || order.paymentStatus === OrderPaymentStatus.Voided
        return !disabled &&
            <Tooltip title={"Edit Order"}>
                <Link to={`/lab/order/?id=${order.id}`}>
                    <IconButton
                        color="primary"
                    >
                        <AppRegistration/>
                    </IconButton>
                </Link>
            </Tooltip>
      }
    },
    {
      name: "checklist",
      label: "Checklist",
      sortable: false,
      render: (order) => {
        return (
          <Tooltip title="Print Checklist">
            <IconButton
              color="primary"
              onClick={() => {
                console.log(order?.packingUrl);
                if (order?.packingUrl) window.open(order.packingUrl);
              }}
            >
              <ListAlt/>
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      name: "labels",
      label: "Labels",
      sortable: false,
      render: (order) => {
        return (
          <>
            {order.shipmentToBuyer?.labelUrl && (
              <Tooltip title="Delivery Label">
                <IconButton
                  color="primary"
                  onClick={() =>
                    window.open(order.shipmentToBuyer?.labelUrl)
                  }
                >
                  <Label/>
                </IconButton>
              </Tooltip>
            )}
            {order.shipmentToLab?.labelUrl && (
              <Tooltip title="Return Label">
                <IconButton
                  color="primary"
                  onClick={() =>
                    window.open(order.shipmentToLab?.labelUrl)
                  }
                >
                  <Label/>
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  return (
    <FullPageLayout>
      <Card sx={{padding: "0 2em 2em", height: "100%", marginBottom: "2em"}}>
          <Grid container>
            <Grid
              item
              container
              xs={12}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <h1>Manage Lab Orders</h1>
              </Grid>
              <Grid item>
                <Button startIcon={<FileDownload/>} onClick={exportOrderCSV}>
                  Export
                </Button>
                <Button
                  onClick={() => navigate("/lab/order")}
                  variant={"contained"}
                  color={"primary"}
                >
                  Create Order
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataTable<OrderWithSamplesFragment>
                data={orders?.items.filter((o) => o?.id) as OrderWithSamplesFragment[]}
                columns={columns}
                options={{
                  backendTable,
                  backendPagingResponse: orders?.options.paging
                }}
                loading={getOrdersQuery.loading}
                defaultControls={{
                  showColumns: columns.map((c) => ({
                    name: c.name,
                    show: true,
                  })),
                }}
              />
            </Grid>
          </Grid>
      </Card>
    </FullPageLayout>
  );
}
