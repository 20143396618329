import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { imgURL } from "../lib/assets";

// mui
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import East from "@mui/icons-material/East";

export const ServiceHero = () => {
  return (
    <Grid
      container
      padding="3em 1em"
      justifyContent={"center"}
      spacing={4}
    >
      <Grid item md={6}>
        <LandingInfoCard
          title="Plant Tissue"
          text="Sample collection is critical for plant analysis as plant nutrient composition varies with age, the portion of the plant sampled, and many other factors."
          url="lab-services/plant-tissue"
          icon={"/grass_black_24dp.svg"}
        />
      </Grid>
      <Grid item md={6}>
        <LandingInfoCard
          title="Soil"
          text="Soil test results for pH, organic matter, phosphorous and potassium (routine test) can be useful for correlating with plant analysis results to pinpoint a nutrient problem."
          url="lab-services/soil"
          icon={"/bubble_chart_black_24dp.svg"}
        />
      </Grid>
    </Grid>
  );
};

type LandingInfoCardProps = {
  icon: string;
  title: string;
  text: string;
  url: string;
};

export const LandingInfoCard: FC<LandingInfoCardProps> = ({
  icon,
  title,
  text,
  url,
}) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ padding: "2em" }}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <img src={imgURL(icon)} style={{height: "100%", maxWidth: "100%"}} alt={title} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container direction={"column"} spacing={2}>
            <Grid item>
              <h2 style={{ fontSize: "36px", margin: "0" }}>{title}</h2>
            </Grid>
            <Grid item>
              <Typography variant="body1">{text}</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate(`/${url}`)}
                endIcon={<East />}
              >
                Read More
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
