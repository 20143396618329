import {FormProvider, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useAuthenticated} from "../../lib/auth";
import {useEasySnackbar} from "../../lib/snackbar";
import FullPageLayout from "../../components/Layouts/FullPageLayout";

import {
  CreateClientCompanyInput, GetClientCompaniesDocument,
  useCreateClientCompanyMutation,
} from "../../generated/graphql";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

export const CreateClientCompany = () => {
  return (
    <FullPageLayout>
      <Grid container justifyContent={"center"}>
        <Grid item xs lg={6}>
          <Card sx={{padding: "0 1em 1em"}}>
            <CreateCompanyForm/>
          </Card>
        </Grid>
      </Grid>
    </FullPageLayout>
  );
};

export const CreateCompanyForm = () => {
  const formMethods = useForm<CreateClientCompanyInput>();

  const {register} = formMethods;

  const navigate = useNavigate();
  const snackbar = useEasySnackbar();
  const [createCompany] = useCreateClientCompanyMutation();
  const {lab} = useAuthenticated();

  const handleSubmit = async (input: CreateClientCompanyInput) => {
    try {
      const result = await createCompany({
        variables: {
          input: {
            ...input,
            laboratoryId: lab?.id,
          },
        },
        refetchQueries: [{query: GetClientCompaniesDocument}]
      });
      snackbar.showSuccess("A company was successfuly created.");
      navigate(
        `/lab/client-companies/${result.data?.createClientCompany?.clientCompany?.id}`
      );
    } catch (e) {
      snackbar.showError(e, "There was an error creating this company.");
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)} noValidate>
        <h1>Create A Client Company</h1>
        <Grid container>
          <Typography variant="body1" fontWeight={"bold"}>
            Business Name*:
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            required
            {...register("businessName")}
          />
          <Typography variant="body1" fontWeight={"bold"} mt={1}>
            Address*:
          </Typography>
          <Grid container spacing={1} mb={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                required
                label="Address Line 1"
                InputLabelProps={{shrink: true}}
                {...register("addressStreet")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                label="Address Line 2"
                InputLabelProps={{shrink: true}}
                {...register("addressStreet2")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                required
                label="City"
                InputLabelProps={{shrink: true}}
                {...register("addressCity")}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                required
                label="State (2 letters)"
                InputLabelProps={{shrink: true}}
                {...register("addressState")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
                required
                label={"Zip Code"}
                InputLabelProps={{shrink: true}}
                {...register("addressZip")}
              />
            </Grid>
            <Grid item xs={12} md={7} mt={1}>
              <Typography variant="body1" fontWeight={"bold"}>
                Billing Email:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                sx={{mb: 2}}
                {...register("billingEmail")}
              />
            </Grid>
            <Grid item xs={12} md={5} mt={1}>
              <Typography variant="body1" fontWeight={"bold"}>
                Phone:
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                sx={{mb: 2}}
                {...register("phone")}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{m: "1em 0 0"}}
            >
              Create Company
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default CreateClientCompany;
