import React, {FC, Fragment, PropsWithChildren} from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { useProfileRequired } from "./lib/auth";
import { Loading } from "./components/Loading";
import Checkout from "./pages/Checkout";
import HomePage from "./pages/HomePage";
import Login from "./pages/auth/Login";
import Orders from "./pages/Orders";
import Register from "./pages/auth/Register";
import Analytics from "./pages/Analytics";
import PasswordReset from "./pages/auth/PasswordReset";
import Services from "./pages/Services";
import SoilPage from "./pages/SoilPage";
import PlantTissuePage from "./pages/PlantTissuePage";
import Reports from "./pages/Reports";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OrderDetail from "./pages/OrderDetail";
import StaffIndexRouter from "./pages/staff";
import LabIndexRouter from "./pages/lab";
import ClientCompanyDetail from "./pages/lab/ClientCompanyDetail";
import UserProfile from "./pages/UserProfile";
import {ProfileProvider} from "./components/Auth/ProfileProvider";

export const Private: FC<PropsWithChildren> = ({children}) => {
  const {loading} = useProfileRequired();
  if (loading) return <Loading/>;
  return <Fragment>{children}</Fragment>;
};

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <ProfileProvider>
        <Routes>
          <Route path="/">
            <Route index element={<HomePage/>}/>
            <Route path="staff/*" element={<StaffIndexRouter/>}/>
            <Route path="lab/*" element={<LabIndexRouter/>}/>
            <Route path="register" element={<Register/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="password-reset/:token" element={<PasswordReset/>}/>
            <Route path="password-reset" element={<PasswordReset/>}/>

            <Route path="terms" element={<TermsOfService/>}/>
            <Route path="privacy" element={<PrivacyPolicy/>}/>

            <Route path="lab-services/soil" element={<SoilPage/>}/>
            <Route
              path="lab-services/plant-tissue"
              element={<PlantTissuePage/>}
            />
            <Route path="lab-services" element={<Services/>}/>
            <Route path="pricing" element={<Pricing/>}/>
            <Route path="contact" element={<Contact/>}/>
            <Route
              path="company-profile"
              element={
                <Private>
                  <ClientCompanyDetail/>
                </Private>
              }
            />
            <Route
              path="user-profile"
              element={
                <Private>
                  <UserProfile/>
                </Private>
              }
            />
            <Route
              path="checkout"
              element={
                <Private>
                  <Checkout/>
                </Private>
              }
            />
            <Route
              path="orders/:id"
              element={
                <Private>
                  <OrderDetail/>
                </Private>
              }
            />
            <Route
              path="orders"
              element={
                <Private>
                  <Orders/>
                </Private>
              }
            />
            <Route
              path="analytics"
              element={
                <Private>
                  <Analytics/>
                </Private>
              }
            />
            <Route
              path="reports"
              element={
                <Private>
                  <Reports/>
                </Private>
              }
            />
          </Route>
        </Routes>
      </ProfileProvider>
    </BrowserRouter>
  );
};

export default AppRouter;
