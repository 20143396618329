import { get } from "lodash";
import { useAuthenticated } from "../lib/auth";
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { Loading } from "../components/Loading";
import {
  MyProfileDocument,
  useUpdateNotificationEnabledMutation,
} from "../generated/graphql";
import { useEasySnackbar } from "../lib/snackbar";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

type NotificationGroupType = "client" | "lab";

const NotificationGroups: Record<
  NotificationGroupType,
  Record<string, string>
> = {
  client: {
    buyer_order_created: "Order Created",
    buyer_order_to_buyer: "An Order is Shipping to You",
    buyer_order_to_lab: "An Order is Returning to the Lab",
    buyer_sample_results: "Results are Ready",
  },
  lab: {
    staff_order_to_lab: "Client Order Shipping To Lab",
    staff_order_created: "Client Order Recieved",
    staff_new_user_register: "New Client Registered",
    staff_sample_results: "Client's Results are Ready",
    staff_order_delivered_to_buyer: "Order Delivered to Client"
  },
};

export const UserProfile = () => {
  const { profile, loading } = useAuthenticated();
  const [updateNotification] = useUpdateNotificationEnabledMutation();
  const snackbar = useEasySnackbar();

  if (loading) {
    return <Loading />;
  }

  const allNotifications = JSON.parse(profile?.notificationsEnabled || "{}");

  const handleChange = async (
    name: string,
    enabled: boolean,
    label: string
  ) => {
    try {
      await updateNotification({
        variables: {
          name,
          enabled,
        },
        refetchQueries: [{ query: MyProfileDocument }],
      });
      snackbar.showSuccess(
        enabled
          ? `${label} is now enabled as a notification.`
          : `${label} is now disabled as a notification.`
      );
    } catch (e) {
      snackbar.showError(
        e,
        "There was an error updating your notification settings"
      );
    }
  };

  return (
    <FullPageLayout>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <h1 style={{ marginBottom: 0 }}>
            {profile?.firstName} {profile?.lastName}
          </h1>
          <Typography variant="body1" mb={2}>
            {profile?.clientCompany?.businessName}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" fontWeight={"bold"}>
            Notification Settings
          </Typography>
          <Typography variant="body2">
            Choose which email notifications you would like to receive.
          </Typography>
          <form onSubmit={() => console.log()} noValidate>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  {Object.entries(NotificationGroups.client).map(
                    ([key, label]) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox checked={get(allNotifications, key)} />
                        }
                        label={label}
                        onChange={(e, newValue) =>
                          handleChange(key, newValue, label)
                        }
                      />
                    )
                  )}
                </FormGroup>
              </Grid>
              {profile?.staffAtLaboratory ? (
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    {Object.entries(NotificationGroups.lab).map(
                      ([key, label]) => (
                        <FormControlLabel
                          key={key}
                          control={
                            <Checkbox checked={get(allNotifications, key)} />
                          }
                          label={label}
                          onChange={(e, newValue) =>
                            handleChange(key, newValue, label)
                          }
                        />
                      )
                    )}
                  </FormGroup>
                </Grid>
              ) : null}
            </Grid>
          </form>
        </Card>
      </Grid>
    </FullPageLayout>
  );
};

export default UserProfile;
