import {BackendTableControls, ColumnDefinition, DataRow, getHeaderLabel} from "./types";
import {useFormContext} from "react-hook-form";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Tooltip from "@mui/material/Tooltip";
import Help from "@mui/icons-material/Help";
import {useEffect} from "react";

export type HeaderCellProps<T extends DataRow> = {
  column: ColumnDefinition<T>;
  backendTable?: BackendTableControls<any>;
};

export function HeaderCell<T extends DataRow>({column, backendTable}: HeaderCellProps<T>) {
  const {setValue, watch} = useFormContext();

  const sortBy = watch("sortBy");
  const sortOrder = watch("sortOrder");
  const isSorted = column.name === sortBy;
  const isAscending = sortOrder === "asc";
  const backendSortingName = typeof (column.sortable) === "string" ? column.sortable : column.name
  const handleClick = () => {
    if (isSorted) {
      const direction = isAscending ? "desc" : "asc"
      setValue("sortOrder", direction);
      backendTable?.setSorting(backendSortingName, direction);
    } else {
      setValue("sortBy", column.name);
      setValue("sortOrder", "asc");
      backendTable?.setSorting(backendSortingName);
    }
  };

  const arrowStyle = {
    transform: `rotate(${isAscending ? 0 : 180}deg)`,
    display: isSorted ? "inline-block" : "none",
  };

  return (
    <TableCell
      align={column.align}
      sx={{fontSize: "80%", padding: 0}}
      {...column.setCellProps?.()}
      {...column.setHeaderCellProps?.()}
    >
      <Button
        onClick={column.sortable !== false ? handleClick : undefined}
        style={{textTransform: "none", fontWeight: "bold"}}
        color="secondary"
        endIcon={<ArrowDownward style={arrowStyle}/>}
      >
        {getHeaderLabel(column)}
      </Button>

      {column.hint && (
        <Tooltip title={column.hint}>
          <Help fontSize="small"/>
        </Tooltip>
      )}
    </TableCell>
  );
}
