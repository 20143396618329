import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import _, { keyBy } from "lodash";
import { HeaderCell } from "./TableHeadCell";
import { DataTablePagination } from "./index";

import {
  DataRow,
  DataTableContainerControls,
  DataTableWidgetProps,
  getSortValue,
  renderCell,
  searchRow,
} from "./types";

// mui
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

export function DataTableWidget<T extends DataRow>({
  data,
  columns,
  options,
}: DataTableWidgetProps<T>) {
  const columnsByName = keyBy(columns, "name");
  const methods = useFormContext<DataTableContainerControls>();

  const controls = useWatch({
    control: methods.control,
  }) as DataTableContainerControls;

  const frontendControls = !options?.backendTable && controls;

  const columnOrder =
    controls.showColumns &&
    controls.showColumns
      .filter((show) => columnsByName[show.name]?.name && show.show !== false)
      .map((show) => show.name);

  const sorted =
    frontendControls && controls.sortBy && columnsByName?.[controls.sortBy]?.name
      ? _.sortBy(data, (row) =>
          getSortValue(columnsByName[controls.sortBy || ""], row)
        )
      : data;

  const ordered = frontendControls && controls.sortOrder === "desc" ? sorted.reverse() : sorted;

  const results = ordered.filter((row) =>
    frontendControls && controls.search ? searchRow(row, columns, controls.search) : true
  );

  const pageSlice = frontendControls && controls.perPage
    ? results.slice(
        controls.page && controls.page * controls.perPage,
        ((controls.page || 0) + 1) * controls.perPage
      )
    : results;

  useEffect(() => {
    options?.callbacks?.onControlsChange?.(controls);
  }, [JSON.stringify(controls)]);

  useEffect(() => {
    options?.callbacks?.onRowsChange?.(results);
  }, [results.map((r) => r.id).join(",")]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columnOrder &&
            columnOrder.map((name) => (
              <HeaderCell key={name} column={columnsByName[name]} backendTable={options?.backendTable} />
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {pageSlice.map((row, index) => (
          <TableRow key={index}>
            {columnOrder?.map((name) => {
              const column = columnsByName[name];
              return (
                <TableCell
                  key={name}
                  align={column.align}
                  sx={{fontSize: "80%"}}
                  {...column.setCellProps?.()}
                >
                  {renderCell(column, row, methods)}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
      <DataTablePagination count={results.length} backendTable={options?.backendTable} backendPagingResponse={options?.backendPagingResponse}/>
    </Table>
  );
}
