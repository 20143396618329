import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEasySnackbar } from "../../lib/snackbar";
import CouponForm from "./CouponCodeForm";

import {
  CouponCodeFieldsFragment,
  CouponCodeInput,
  GetCouponCodesDocument,
  LaboratoryFieldsFragment,
  useEditCouponCodeMutation,
} from "../../generated/graphql";

// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";

export type CouponCodeDialogProps = {
  existingCoupon: CouponCodeFieldsFragment;
  lab: LaboratoryFieldsFragment;
};

export function EditCouponCodeDialog({
  existingCoupon,
  lab,
}: CouponCodeDialogProps) {
  const input = existingCoupon;
  const formMethods = useForm<CouponCodeFieldsFragment>({
    defaultValues: {
      code: input.code,
      kind: input.kind,
      discountAppliesToShippingCosts:
      input.discountAppliesToShippingCosts,
      freeShippingCostUpToPriceInCents:
        (input.freeShippingCostUpToPriceInCents &&
          input.freeShippingCostUpToPriceInCents * 100) ||
        0,
      minimumOrderPriceInCents:
        (input.minimumOrderPriceInCents &&
          input.minimumOrderPriceInCents * 100) ||
        0,
      value: input.value,
      maxOrdersPerUser: input.maxOrdersPerUser || 0,
      maxSamplesPerUser: input.maxSamplesPerUser || 0,
      validUntil: input.validUntil || null,
      publicAutomatic: input.publicAutomatic,
    },
  });
  const [open, setOpen] = useState(false);

  const [editCouponCode] = useEditCouponCodeMutation({
    refetchQueries: [
      {
        query: GetCouponCodesDocument,
        variables: {
          filters: {
            laboratoryId: lab.id,
          },
        },
      },
    ],
  });
  const snackbar = useEasySnackbar();

  const _handleSubmit = async (input: CouponCodeInput) => {
    try {
      await editCouponCode({
        variables: {
          input: {
            ...input,
            id: existingCoupon?.id,
            discountAppliesToShippingCosts:
              input.discountAppliesToShippingCosts,
            freeShippingCostUpToPriceInCents:
              (input.freeShippingCostUpToPriceInCents &&
                input.freeShippingCostUpToPriceInCents * 100) ||
              0,
            minimumOrderPriceInCents:
              (input.minimumOrderPriceInCents &&
                input.minimumOrderPriceInCents * 100) ||
              0,
          },
        },
      });
      snackbar.showSuccess("Coupon code was successfully edited");
      setOpen(false);
    } catch (e) {
      snackbar.showError(e, "There was an error editing this coupon code.");
    }
  };

  const _handleSubmitDeactivate = async () => {
    try {
      await editCouponCode({
        variables: {
          input: {
            id: existingCoupon?.id,
            code: existingCoupon?.code,
            enabled: false,
          },
        },
      });
      snackbar.showSuccess("Coupon code was successfully deactivated");
      setOpen(false);
    } catch (e) {
      snackbar.showError(
        e,
        "There was an error deactivating this coupon code."
      );
    }
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Edit />
      </IconButton>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <form noValidate onSubmit={formMethods.handleSubmit(_handleSubmit)}>
          <DialogTitle>
            <Grid container justifyContent="space-between">
              <Grid item>Edit Coupon Code</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => _handleSubmitDeactivate()}
                >
                  Deactivate
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <CouponForm formMethods={formMethods} />
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Exit</Button>
            <Button type="submit" variant="contained">
              Save Changes
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default EditCouponCodeDialog;
