import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["'Quicksand'", "sans-serif"].join(","),
  },

  palette: {
    primary: {
        main: "#aed581",
      },
      secondary: {
        main: "#000",
      },
  
  },
});
