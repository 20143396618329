import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useEasySnackbar } from "../../lib/snackbar";

import {
  CouponCodeFieldsFragment,
  CouponCodeInput,
  GetCouponCodesDocument,
  LaboratoryFieldsFragment,
  useCreateCouponCodeMutation,
} from "../../generated/graphql";

// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AddCircle from "@mui/icons-material/AddCircle";
import CouponForm from "./CouponCodeForm";

type CreateCouponCodeDialogProps = {
  lab: LaboratoryFieldsFragment;
};

export const CreateCouponCodeDialog: FC<CreateCouponCodeDialogProps> = ({
  lab,
}) => {
  const formMethods = useForm<CouponCodeFieldsFragment>();
  const [open, setOpen] = useState(false);

  const [createCouponCode] = useCreateCouponCodeMutation({
    refetchQueries: [
      {
        query: GetCouponCodesDocument,
        variables: {
          filters: {
            laboratoryId: lab.id,
          },
        },
      },
    ],
  });
  const snackbar = useEasySnackbar();

  const _handleSubmit = async (input: CouponCodeInput) => {
    try {
      await createCouponCode({
        variables: {
          input: {
            code: input.code,
            kind: input.kind,
            discountAppliesToShippingCosts:
              input.discountAppliesToShippingCosts,
            freeShippingCostUpToPriceInCents:
              (input.freeShippingCostUpToPriceInCents &&
                input.freeShippingCostUpToPriceInCents * 100) ||
              0,
            minimumOrderPriceInCents:
              (input.minimumOrderPriceInCents &&
                input.minimumOrderPriceInCents * 100) ||
              0,
            value: input.value,
            maxOrdersPerUser: input.maxOrdersPerUser || 0,
            maxSamplesPerUser: input.maxSamplesPerUser || 0,
            validUntil: input.validUntil || null,
            publicAutomatic: input.publicAutomatic,
          },
        },
      });
      snackbar.showSuccess("Coupon code was successfully created");
      setOpen(false);
    } catch (e) {
      snackbar.showError(e, "There was an error creating this coupon code.");
    }
  };

  return (
    <>
      <Button
        startIcon={<AddCircle />}
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Create Code
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <form noValidate onSubmit={formMethods.handleSubmit(_handleSubmit)}>
          <DialogTitle>Create a New Coupon Code</DialogTitle>
          <CouponForm formMethods={formMethods} />
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Exit</Button>
            <Button type="submit" variant="contained">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateCouponCodeDialog;
