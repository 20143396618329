import { FC } from "react";
import { useForm } from "react-hook-form";
import {
  ClientCompanyFieldsFragment,
  ClientCompanyWithPricingFragment,
  CustomClientCompanyPricesFragment,
  useUpdateClientCompanyPricingMutation,
} from "../generated/graphql";
import { useEasySnackbar } from "../lib/snackbar";
import { get } from "lodash";

import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import HTMLTitle from "./HTMLTitle";
import { useProfile } from "./Auth/ProfileProvider";
import { formatPrice } from "../lib/testTypes";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";

const PlainCell = styled(TableCell)({
  border: "none",
});

type TestProps = {
  clientCompany: ClientCompanyFieldsFragment | undefined;
};

type ProductPricing = {
  field: keyof CustomClientCompanyPricesFragment;
  label: string;
};

const PRODUCT_PRICINGS: ProductPricing[] = [
  { field: "priceTestPlantTissue", label: "Plant Tissue Test" },
  { field: "priceTestSoil", label: "Soil Test" },
  { field: "priceTestFertilizer", label: "Fertilizer Test" },
  { field: "priceTestWater", label: "Water Test" },
  { field: "priceTestRunOff", label: "Runoff Test" },
  { field: "priceTestNutrient", label: "Nutrient Test" },
  { field: "priceTestPythiumSpp", label: "<i>Pythium spp.</i> Test" },
  { field: "priceTestHopLatentViroid", label: "Hop Latent Viroid Test" },
  { field: "priceTestHeavyMetal", label: "Heavy Metal Test" },
  { field: "priceTestFusariumSpp", label: "<i>Fusarium spp.</i> Test" },
];

export const CustomClientCompanyPricingTable: FC<TestProps> = ({
  clientCompany,
}) => {
  const { profile } = useProfile();
  const defaultValues = {
    ...PRODUCT_PRICINGS.reduce(
      (acc, { field }) => ({
        ...acc,
        [field]: (parseFloat(get(clientCompany, field) || "0") || 0) / 100,
      }),
      {}
    ),
  };
  console.log("Loaded defaults", defaultValues, clientCompany);
  const { register, handleSubmit, watch, setValue } =
    useForm<ClientCompanyWithPricingFragment>({ defaultValues });
  const [updateClientCompanyPricing] = useUpdateClientCompanyPricingMutation();
  const snackbar = useEasySnackbar();

  const _handleSubmit = async (input: ClientCompanyWithPricingFragment) => {
    try {
      await updateClientCompanyPricing({
        variables: {
          input: {
            id: clientCompany?.id,
            paymentTerms: input.paymentTerms,
            ...PRODUCT_PRICINGS.reduce(
              (acc, { field }) => ({
                ...acc,
                [field]: get(input, field)
                  ? Math.round(((get(input, field) as number) || 0) * 100)
                  : null,
              }),
              {}
            ),
          },
        },
      });
      snackbar.showSuccess("Client Company pricing was successfully updated!");
    } catch (e) {
      snackbar.showError(
        e,
        "There was an error updating the client company custom prices."
      );
    }
  };

  const values = watch();

  const lab = profile?.staffAtLaboratory;

  return (
    <Card sx={{ p: 3 }}>
      <TableContainer>
        <form onSubmit={handleSubmit(_handleSubmit)} noValidate>
          <Table size="small">
            <TableBody>
              <TableRow>
                <PlainCell>
                  <Typography variant="body1">
                    Payment Terms
                  </Typography>
                </PlainCell>
                <PlainCell>
                  <TextField
                    fullWidth
                    {...register("paymentTerms")}
                    variant="outlined"
                    select
                    defaultValue={clientCompany?.paymentTerms || "IMMEDIATE"}
                   >
                    <MenuItem value="IMMEDIATE">No Terms - Due Immediately</MenuItem>
                    <MenuItem value="NET_15">15 days after purchase</MenuItem>
                    <MenuItem value="NET_30">30 days after purchase</MenuItem>
                    <MenuItem value="MONTHLY">End of Month</MenuItem>
                    <MenuItem value="MONTHLY_PLUS_15">End of Month Plus 15 Days</MenuItem>
                    <MenuItem value="MONTHLY_PLUS_30">End of Month Plus 30 Days</MenuItem>
                  </TextField>
                </PlainCell>
              </TableRow>
              {PRODUCT_PRICINGS.filter((p) => (
                get(lab, p.field) !== 0
              )).map((product) => (
                <TableRow key={product.field}>
                  <PlainCell>
                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    <Typography variant="body1">
                      <HTMLTitle value={product.label} />
                    </Typography>
                  </PlainCell>
                  <PlainCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            !!get(
                              values,
                              product.field as keyof CustomClientCompanyPricesFragment
                            )
                          }
                        />
                      }
                      onChange={(e, checked) =>
                        setValue(
                          product.field as keyof CustomClientCompanyPricesFragment,
                          checked ? 50 : 0
                        )
                      }
                      label="Override Price?"
                    />
                  </PlainCell>
                  <PlainCell>
                    {!!get(
                      values,
                      product.field as keyof CustomClientCompanyPricesFragment
                    ) ? (
                      <TextField
                        size="small"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        {...register(
                          product.field as keyof CustomClientCompanyPricesFragment
                        )}
                      />
                    ) : (
                      formatPrice(get(lab, product.field) as number)
                    )}
                  </PlainCell>
                </TableRow>
              ))}
              <TableRow>
                <PlainCell />
                <PlainCell />
                <PlainCell>
                  <Grid container justifyContent={"flex-end"}>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        type="submit"
                      >
                        Save Price Changes
                      </Button>
                    </Grid>
                  </Grid>
                </PlainCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </TableContainer>
    </Card>
  );
};
