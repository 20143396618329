import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {parseGQLErrorMessage} from "../lib/validation";

import {
  OrderPricing,
  PerformCheckoutInput,
  usePerformCheckoutMutation,
} from "../generated/graphql";

// components
import FullPageLayout from "../components/Layouts/FullPageLayout";
import CheckoutTable from "../components/Checkout/CheckoutTable";
import LineItems from "../components/Checkout/LineItems";
import ShippingOptions from "../components/Checkout/ShippingOptions";
import CouponCode from "../components/Checkout/CouponCode";
import Subtotal from "../components/Checkout/Subtotal";

// mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {useAuthenticated} from "../lib/auth";

const DEFAULT_VALUES = {
  testTypes: [],
} as PerformCheckoutInput;

export const Checkout = () => {
  const formMethods = useForm<PerformCheckoutInput>({
    defaultValues: DEFAULT_VALUES,
  });
  const [performCheckout, {loading}] = usePerformCheckoutMutation();
  const [pricingInfo, setPricingInfo] = useState<OrderPricing | undefined>();

  const [error, setError] = useState("");
  const {profile} = useAuthenticated();

  const handleSubmit = async (input: PerformCheckoutInput) => {
    try {
      const result = await performCheckout({
        variables: {
          input: {
            clientCompanyId: profile?.clientCompany?.id,
            couponCode: input.couponCode || "",
            paymentToken: input.paymentToken || "",
            testTypes: input.testTypes,
            shipToBuyerMethod: input.shipToBuyerMethod,
            shipToLabMethod: input.shipToLabMethod,
          },
        },
      });
      setError("");
      if (result.data?.performCheckout?.order?.id) {
        if (result.data?.performCheckout?.paymentUrl)
          window.open(result.data?.performCheckout?.paymentUrl);
        window.location.pathname = "/";
      } else {
        setError(
          "An unknown error occurred, please contact support"
        );
      }
    } catch (e) {
      setError(
        parseGQLErrorMessage(e) ||
        "An unknown error occurred, please contact support"
      );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)} noValidate>
        <Grid container spacing={6}>
          <Grid item xs={12} md={7} lg={8}>
            <h2>Shopping Cart</h2>
            <CheckoutTable/>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <h2>Order Summary</h2>
            {pricingInfo ? <LineItems pricingInfo={pricingInfo}/> : null}
            <ShippingOptions company={profile?.clientCompany || undefined}/>
            <CouponCode/>
            <Subtotal pricingInfo={pricingInfo} setPricingInfo={setPricingInfo} error={error} setError={setError}/>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={Boolean(error) || !pricingInfo || loading}
              sx={{m: "1em 0 0"}}
            >
              {pricingInfo?.totalPriceInCents ? "Pay Now" : "Submit Order"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default function CheckoutPage() {
  return (
    <FullPageLayout>
      <Card sx={{padding: "1em", mb: "1em"}}>
        <Checkout/>
      </Card>
    </FullPageLayout>
  );
}