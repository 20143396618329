import * as XLSX from "xlsx";
import {AnalyteInfo, ApplicationSettings, ApplicationSettingsFragment, SampleTestType} from "../generated/graphql";
import {groupBy, keyBy, maxBy, uniqBy} from "lodash";

function getRow(location: string) {
  return XLSX.utils.decode_cell(location).r
}

function getColumn(location: string) {
  return XLSX.utils.encode_col(XLSX.utils.decode_cell(location).c)
}

type RowData = Record<string, string>

// Import common

type SampleImportRow = {
  sampleId: string;
  rowNumber: number | string;
  cellData: Record<string, string>;
  importData: Record<string, string>;
}

export function toLowerSlug(a?: string) {
  return (a || "").toLowerCase().replaceAll("(", "").replaceAll(")", "")
}

export function noSpaces(target: string, replaceValue = "") {
  return target.replaceAll(" ", replaceValue).replaceAll("_", replaceValue)
}

const toBase64 = (file: File) => new Promise((resolve: (result: string) => void, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

const toBinary = (file: File) => new Promise((resolve: (result: BinaryData) => void, reject) => {
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = () => resolve(reader.result as BinaryData);
  reader.onerror = error => reject(error);
});

export async function readWorksheet(findSheet: string, file: File) {
  const binary = await toBinary(file);

  const Data = XLSX.read(binary, {type: 'binary'});
  const sheetName: string | undefined = Data.SheetNames.find(name => noSpaces(toLowerSlug(name)) === noSpaces(toLowerSlug(findSheet)));
  return {sheetName, Data}
}

export async function readWorksheetTrackingColumns(findSheet: string, file: File, trackingColumns: string[], onReadSheet: (sheet: ImportSampleTrackingColumns) => void) {
  const {sheetName, Data} = await readWorksheet(findSheet, file);
  console.log("Read sheet", sheetName, Data)
  if (sheetName) {
    const sheet = sheetToTrackingColumns(sheetName, Data.Sheets[sheetName], trackingColumns)
    if (sheet)
      onReadSheet(sheet);
  }
}

export function sheetToTrackingColumns(name: string, sheet: XLSX.WorkSheet, trackingColumns: string[]): ImportSampleTrackingColumns | undefined {
  const sheetData = parseSampleSheetData(name, sheet)
  if (!sheetData) {
    return
  }
  const {headers, getRows, sampleIdLocation} = sheetData;

  const findTrackingColumn = (label: string) => {
    if (!label)
      return
    const slug = (s: string) => noSpaces(toLowerSlug(s))
    const search = slug(label)
    const exact = trackingColumns.find(c => slug(c) === search)
    return exact ? exact : undefined
  }

  const sampleIdColumn = getColumn(sampleIdLocation);
  const columnsExceptSampleId = Object.keys(headers).filter(c => c !== sampleIdColumn)
  const columnMap = columnsExceptSampleId.map<TrackingColumnPointer>((key) => ({
    key,
    label: headers[key],
    trackingColumn: findTrackingColumn(headers[key])
  }))
  const foundTrackingColumns = uniqBy(columnMap.filter(c => c.trackingColumn), column => column.trackingColumn)
  const ignoredColumns = columnMap.filter(c => !c.trackingColumn)

  // Convert rows to usable sample data

  return {
    name,
    headers,
    trackingColumns: foundTrackingColumns,
    ignoredColumns,
    sampleIdLocation,
    rows: getRows(foundTrackingColumns.map(c => ({from: c.key, to: c.trackingColumn || ""})))
  }

}

export type ImportSampleSheetData = {
  fileData?: string;
  fileName?: string;
  headers: Record<string, string>;
  sampleIdLocation: string;
  name: string;
}
export type ImportSampleSheetDataGetRows = {
  getRows: (mapFields: ImportFieldMapping[]) => SampleImportRow[];
}

type TrackingColumnPointer = {
  key: string,
  label: string,
  trackingColumn?: string
}

export type ImportSampleTrackingColumns = ImportSampleSheetData & {
  rows: SampleImportRow[];
  trackingColumns: TrackingColumnPointer[];
  ignoredColumns: TrackingColumnPointer[];
}

// Analyte Import

type AnalyteColumnPointer = {
  key: string,
  label: string,
  analyte?: AnalyteInfo
}

export type ImportSampleAnalytesSheetData = ImportSampleSheetData & {
  rows: SampleImportRow[];
  analyteColumns: AnalyteColumnPointer[];
  ignoredColumns: AnalyteColumnPointer[];
}
export type ImportSampleAnalytes = {
  testType: SampleTestType;
  sheet: ImportSampleAnalytesSheetData;
  fileData?: string;
  fileName?: string;
}

export type ImportFieldMapping = { from: string, to: string }


export function getTestAnalytes(settings: ApplicationSettingsFragment, testType: SampleTestType) {
  const testAnalytes = settings.testAnalytes.find(a => a.testType === testType)?.analytes || [];
  const analytes = settings.analytes.filter(f => testAnalytes.includes(f.id));
  const byId = keyBy(analytes, "id");

  const nicknames = settings.analytes.reduce<Record<string, string[]>>((result, a) => ({
    ...result,
    [a.id]: [a.id, ...a.nicknames]
  }), {}) as Record<string, string[]>
  return {analytes, nicknames, byId}
}


export async function readWorksheetAnalytes(findSheet: string, file: File, settings: ApplicationSettings, testType: SampleTestType, onReadSheet: (sheet: ImportSampleAnalytesSheetData, fileName: string, fileData: string) => void) {
  const base64 = await toBase64(file);
  const {sheetName, Data} = await readWorksheet(findSheet, file);
  if (sheetName) {
    const sheet = sheetToAnalytes(sheetName, Data.Sheets[sheetName], settings, testType)
    if (sheet)
      onReadSheet(sheet, file.name, base64);
  }
}

export function parseSampleSheetData(name: string, sheet: XLSX.WorkSheet): ImportSampleSheetData & ImportSampleSheetDataGetRows | undefined {
  // Find SampleID location in the sheet
  const [sampleIdLocation,] = Object.entries(sheet).find(([key, cell]) => noSpaces(toLowerSlug(cell.w)) === 'sampleid') || ["", {} as XLSX.CellObject];
  if (!sampleIdLocation) {
    return;
  }
  const headerRow = getRow(sampleIdLocation);
  const sampleIdColumn = getColumn(sampleIdLocation);

  // Parse sheet headers
  const headers = Object.entries(sheet).filter(([key, cell]) => getRow(key) === headerRow).reduce<RowData>((result, [key, cell]) => ({
    ...result,
    [getColumn(key)]: cell.w
  }), {})

  const getRows = (mapFields: ImportFieldMapping[]) => {
    const byRow = groupBy(Object.entries(sheet).filter(([key, cells]) => !key.startsWith("!")), ([key, cells]) => getRow(key))
    return Object.entries(byRow).map(([rowNumber, columns],) => {
      const sampleId = columns.find(([key, cells]) => getColumn(key) === sampleIdColumn)?.[1]?.w;
      if (!sampleId || parseInt(rowNumber) <= headerRow)
        return null

      const cellData = columns.reduce<RowData>((result, [key, cell]) => ({...result, [getColumn(key)]: cell.w}), {})
      const importData = mapFields.reduce<RowData>((result, field) => ({
        ...result,
        [field.to]: cellData[field.from]
      }), {})

      return {
        rowNumber,
        sampleId,
        cellData,
        importData
      } as SampleImportRow
    }).filter(r => r !== null) as SampleImportRow[]
  }

  return {headers, sampleIdLocation, name, getRows}
}

export function sheetToAnalytes(name: string, sheet: XLSX.WorkSheet, settings: ApplicationSettingsFragment, testType: SampleTestType): ImportSampleAnalytesSheetData | undefined {
  const sheetData = parseSampleSheetData(name, sheet)
  if (!sheetData) {
    return
  }
  const {headers, getRows, sampleIdLocation} = sheetData;

  const {analytes, nicknames, byId} = getTestAnalytes(settings, testType)
  const findAnalyte = (label: string) => {
    if (!label)
      return

    const slug = (s: string) => noSpaces(toLowerSlug(s))
    const search = slug(label)
    const exact = analytes.find(a => nicknames[a.id].find((nickname: string) => slug(nickname) === search))
    const contains = analytes.map(a => maxBy(nicknames[a.id].filter(n => search.includes(slug(n))), 'length')).filter(i => i)
    const longestContains = maxBy(contains, 'length')
    const found = exact?.id ?? longestContains
    return found ? byId[found] : undefined
  }

  const sampleIdColumn = getColumn(sampleIdLocation);
  const columnsExceptSampleId = Object.keys(headers).filter(c => c !== sampleIdColumn)
  const columnMap = columnsExceptSampleId.map<AnalyteColumnPointer>((key) => ({
    key,
    label: headers[key],
    analyte: findAnalyte(headers[key])
  }))
  const analyteColumns = uniqBy(columnMap.filter(c => c.analyte), column => column.analyte)
  const ignoredColumns = columnMap.filter(c => !c.analyte)

  // Convert rows to usable sample data

  return {
    name,
    headers,
    analyteColumns,
    ignoredColumns,
    sampleIdLocation,
    rows: getRows(analyteColumns.map(c => ({from: c.key, to: c.analyte?.id || ""})))
  }
}