import {identity} from "lodash";

export function exportCSV<T extends Record<string, unknown>>(rows?: (T | undefined | null)[], filename = "generated") {
  const validRows = rows?.filter(identity) as T[] ?? [];
  if (!validRows.length) {
    return;
  }
  const separator: string = ",";

  const keys: (keyof T)[] = Object.keys(validRows[0]).filter(k => k !== '__typename') as (keyof T)[];

  let columnHeaders: (keyof T)[] = keys

  const csvContent = columnHeaders.join(separator) +
    '\n' +
    validRows.map(row => {
      return keys.map(k => {
        let cell = row[k] === null || row[k] === undefined ? '' : row[k] as any;

        cell = cell instanceof Date
          ? cell.toLocaleString()
          : cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join('\n');

  const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}