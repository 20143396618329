import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthenticated } from "../../lib/auth";
import { Loading } from "../../components/Loading";
import FullPageLayout from "../../components/Layouts/FullPageLayout";
import { TestSettingsTable } from "../../components/LabTestSettingsTable";
import { LabInfoForm } from "../../components/LabInfoForm";
import { CheckoutForm } from "../../components/CheckoutForm";
import { CouponCodesTable } from "../../components/CouponCodes/CouponCodesTable";
import CreateCouponCodeDialog from "../../components/CouponCodes/CreateCouponCodeDialog";
import { LabUsersTable } from "../../components/LabUsersTable";

import { useGetLaboratoryQuery } from "../../generated/graphql";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CreateHealthProfileDialog from "../../components/AnalyteHealthProfiles/CreateHealthProfileDialog";
import { HealthProfileTable } from "../../components/AnalyteHealthProfiles/HealthProfilesTable";


export default function LabDetails() {
  const [searchParams] = useSearchParams();
  const [deactivatedCoupons, setDeactivatedCoupons] = useState(false);
  const [deactivatedProducts, setDeactivatedProducts] = useState(false);
  const [archivedHealthProfiles, setArchivedHealthProfiles] = useState(false);

  const { lab: myLab } = useAuthenticated();

  const labId = searchParams.get("labId");

  const labQuery = useGetLaboratoryQuery({
    variables: { id: labId || (myLab?.id as string) },
  });

  const lab = labQuery.data?.getLaboratory;

  if (!lab) {
    return <Loading />;
  }

  return (
    <FullPageLayout>
      <h1 style={{ margin: "0.5em" }}>{`${lab?.businessName}`}</h1>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h5" fontWeight={"bold"} mb={2}>
              Details
            </Typography>
            <LabInfoForm lab={lab} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 3 }}>
            <CheckoutForm lab={lab} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 3 }}>
            <Grid
              container
              alignItems={"center"}
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h5" fontWeight={"bold"} mb={2}>
                  Test Prices
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event, checked) =>
                        setDeactivatedProducts(checked)
                      }
                    />
                  }
                  label="Show Deactivated Tests"
                />
              </Grid>
            </Grid>

            <TestSettingsTable
              lab={lab}
              showDeactivated={deactivatedProducts}
            />
          </Card>
        </Grid>

        <LabUsersTable lab={lab} />
        <Grid item xs={12} mb={2}>
          <Card sx={{ p: 3 }}>
            <Grid
              container
              alignItems={"center"}
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h5" fontWeight={"bold"}>
                  Health Profiles
                </Typography>
              </Grid>
              <Grid item>
                {/*<FormControlLabel
                  control={
                    <Switch
                      onChange={() =>
                        setArchivedHealthProfiles(!archivedHealthProfiles)
                      }
                    />
                  }
                  label="Show Archived Health Profiles"
                /> */}
                <CreateHealthProfileDialog />
              </Grid>
              <HealthProfileTable lab={lab} showArchived={archivedHealthProfiles} />
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Card sx={{ p: 3 }}>
            <Grid
              container
              alignItems={"center"}
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h5" fontWeight={"bold"}>
                  Coupon Codes
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={() =>
                        setDeactivatedCoupons(!deactivatedCoupons)
                      }
                    />
                  }
                  label="Show Deactivated Codes"
                />
                <CreateCouponCodeDialog lab={lab} />
              </Grid>
              <CouponCodesTable lab={lab} showInactive={deactivatedCoupons} />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </FullPageLayout>
  );
}
