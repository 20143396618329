import { Link } from "react-router-dom";
import { imgURL } from "../lib/assets";

// mui
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Mail from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  footerLink: {
    color: "#D9DBE1",
    textDecoration: "none",
  },
});

export const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <Box
        id="FooterWrapper"
        paddingY={6}
        bgcolor="#212121"
        color="#D9DBE1"
        width="100%"
        flexShrink={0}
      >
        <Container maxWidth="xl">
          <Grid container spacing={5} justifyContent="space-between">
            <Grid
              item
              xs={12}
              md={6}
              sm={4}
              container
              direction="column"
              spacing={5}
            >
              <Grid item>
                <img
                  src={imgURL("/Soil-Lab-Logo-1.png")}
                  alt="logo"
                  width="200"
                />
              </Grid>
            </Grid>
            <Grid item xs container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Quick Links
                </Typography>
              </Grid>

              <Grid item>
                <Link to="/lab-services/soil" className={classes.footerLink}>
                  Soil Sample Procedure
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="/lab-services/plant-tissue"
                  className={classes.footerLink}
                >
                  Plant Tissue Sample Procedure
                </Link>
              </Grid>
              <Grid item>
                <Link to="/pricing" className={classes.footerLink}>
                  Pricing
                </Link>
              </Grid>
              <Grid item>
                <Link to="/contact" className={classes.footerLink}>
                  Contact Us
                </Link>
              </Grid>
            </Grid>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Reach Us
                </Typography>
              </Grid>
              <Grid item>
                <Grid item container alignItems="center" spacing={2}>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        (window.location.href = "mailto:sales@thesoillabs.com")
                      }
                    >
                      <Mail sx={{ color: "#aed581" }} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <a
                      style={{ textDecoration: "none", color: "#aed581" }}
                      target="_blank"
                      href="mailto:sales@thesoillabs.com"
                      rel="noreferrer"
                    >
                      <Typography variant="body1">
                        support@thesoillabs.com
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <SmallFooter />
    </>
  );
};

export default Footer;

export function SmallFooter() {
  const classes = useStyles();
  return (
    <Box paddingY={2} bgcolor="#212121" color="#D9DBE1">
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body1">
              &copy; 2022 - {new Date().getFullYear()} Arvum Plant Labs. All rights
              reserved.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <Link to="/terms" className={classes.footerLink}>
                Terms of Service
              </Link>{" "}
              |{" "}
              <Link to="/privacy" className={classes.footerLink}>
                {" "}
                Privacy Policy
              </Link>
              
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
