import { Exact, SampleSummaryFieldsFragment } from "../generated/graphql";

export type TrackingColumns = Omit<SampleSummaryFieldsFragment, "testType">;

export const EMPTY_SAMPLE_LINE = {
  id: "",
  label: "",
  group: "",
  testType: undefined,
  stage: undefined,
  daysInStage: undefined,
  location: "",
  cultivar: "",
  harvestGroup: "",
};

export const columnsToColumnNames: Record<string, string> = {
  cultivar: "Strain/Cultivar",
  stage: "Stage",
  daysInStage: "Day in Stage",
  label: "Label/Notes",
  location: "Location",
  harvestGroup: "Harvest Group",
  group: "Grouping Code",
};

export type TrackingColumnType = keyof TrackingColumns;
export type TrackingColumnHistoriesMap = Record<TrackingColumnType, string[]>;
export const TRACKING_COLUMN_ORDER: TrackingColumnType[] = [
  "cultivar",
  "stage",
  "daysInStage",
  "label",
  "location",
  "harvestGroup",
  "group",
];

export type MenuOptions = {
  value: string;
  label?: string;
};

export const STAGE_OPTIONS = [
  { value: "MOTHER", label: "Mother" },
  { value: "CLONE", label: "Clone" },
  { value: "VEG", label: "Veg" },
  { value: "FLOWER", label: "Flower" },
  { value: "LATE_FLOWER", label: "Late Flower" },
  { value: "DRYING", label: "Drying" },
] as MenuOptions[];

export type SampleFiltersCommon = Exact<{
  id?: string;
  testType?: string;
  cultivar?: string;
  harvestGroup?: string;
  location?: string;
  stage?: string;
  group?: string;
  startDate?: string;
  endDate?: string;
}>;

export type KitFiltersCommon = Exact<{
  status?: string;
  orderDate?: string;
}>;
