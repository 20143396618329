import {ThemeProvider} from "@mui/material/styles";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import React from "react";
import "./App.css";
import AppRouter from "./routes";
import {theme} from "./theme";
import {setContext} from "@apollo/client/link/context";
import {getAuthToken} from "./lib/auth";
import {loadStripe} from "@stripe/stripe-js"
import {SnackbarProvider} from "notistack";

declare global {
  interface Window {
    stripePromise: any;
    REACT_APP_BACKEND_GRAPHQL?: string;
    REACT_APP_STRIPE_PUBLIC_KEY?: string;
    ENVIRONMENT: string;
    BUCKET_URL: string;
    SITE_ID?: string;
    SITE_NAME?: string;
    SITE_CUSTOM_DOMAIN?: string;
    SITE_LOGO?: string;
  }
}

function App() {
  const BACKEND_GRAPHQL = process.env.REACT_APP_BACKEND_GRAPHQL || window.REACT_APP_BACKEND_GRAPHQL;
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || window.REACT_APP_STRIPE_PUBLIC_KEY;
  if (!BACKEND_GRAPHQL)
    throw new Error("REACT_APP_BACKEND_GRAPHQL is undefined");
  console.debug("Stripe Public Key:", STRIPE_PUBLIC_KEY)
  if (STRIPE_PUBLIC_KEY)
    window.stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

  const impersonate = localStorage.getItem("impersonate");

  const httpLink = createHttpLink({
    uri: BACKEND_GRAPHQL + (impersonate ? `?__impersonate=${impersonate}` : ""),
  });

  const authLink = setContext((_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = getAuthToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `JWT ${token}` : "",
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        {/*@ts-ignore*/}
        <SnackbarProvider maxSnack={3}>
          <AppRouter/>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
