import {formatPrice, ProductLabels} from "../../lib/testTypes";
import {get} from "lodash";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {OrderPricing} from "../../generated/graphql";
import HTMLTitle from "../HTMLTitle";

export default function LineItems({pricingInfo}: {pricingInfo: OrderPricing}) {
  const lineItems =
    pricingInfo &&
    pricingInfo.lineItems.filter((line) => line && parseInt(line.quantity) > 0);

  return <Grid container>
    <Grid item xs={12}>
      <h4>Line Items</h4>
    </Grid>
    {lineItems?.map((line) => (
      <Grid
        container
        key={line?.product}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="body1">
            {line?.quantity}
            {"  "}
            <HTMLTitle value={line?.product && get(ProductLabels, line?.product)} /> Tests
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {formatPrice(line?.totalInCents || 0)}
          </Typography>
        </Grid>
      </Grid>
    ))}
  </Grid>

}