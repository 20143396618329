// components
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { ServiceHero } from "../components/ServiceHero";

// mui
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const Services = () => {
  return (
    <FullPageLayout>
      <Box textAlign="center">
        <h1 style={{ fontSize: "50px" }}>Lab Services</h1>
      </Box>
      <Typography variant="h2" fontSize={"30px"}>
        The purpose of The Soil Labs is to focus on the health of the plant
        throughout its growth cycle. Healthy plants with proper nutrition will
        lead to greater yields.
        <br />
        <br />
        Analytical testing of plant tissue and growing media is critically
        important for the understanding of plant nutrition.
        <br />
        <br />
        Identifying these crucial aspects of cultivation can help optimize crop
        yields, reduce fertilizer waste, and potentially preemptively identify
        crop issues.
        <br />
        <br />
        <b>
          IT IS RECOMMENDED THAT TESTING OCCUR EVERY 7-14 DAYS THROUGHOUT THE
          CROP GROWTH CYCLE.
        </b>
      </Typography>
      <ServiceHero />
    </FullPageLayout>
  );
};

export default Services;
