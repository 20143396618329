import { useAuthenticated } from "../lib/auth";

// components
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { imgURL } from "../lib/assets";

// mui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Phone from "@mui/icons-material/Phone";
import ShoppingBag from "@mui/icons-material/ShoppingBag";
import Support from "@mui/icons-material/Support";

export const Contact = () => {
  const { site, lab } = useAuthenticated();

  const supportEmail = lab?.supportEmail || site?.supportEmail;
  const salesEmail = lab?.salesEmail || site?.salesEmail;
  const phone = lab?.phone || site?.phone;

  return (
    <FullPageLayout>
      <Box textAlign="center">
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={10} md={8} lg={4}>
            <Card sx={{ padding: "2em", marginBottom: "2em" }}>
              <Box height="100%" width="30%" margin="auto">
                <img
                  src={imgURL("/contact_support_green_24dp.svg")}
                  alt="contact icon"
                  height="100%"
                  width="100%"
                />
              </Box>
              <h1>Contact Us</h1>
              <h3>
                If you have any questions or need help placing an order, please
                feel free to contact us, and we will get back to you as soon as
                possible.
              </h3>
              <Grid container justifyContent="center">
                <Grid item>
                  <Grid container alignItems={"center"} spacing={3}>
                    <Grid item>
                      <Support color="primary" />
                    </Grid>
                    <Grid item>
                      <a
                        style={{ textDecoration: "none", color: "#aed581" }}
                        target="_blank"
                        href={`mailto:${supportEmail}`}
                        rel="noreferrer"
                      >
                        <h2>{supportEmail}</h2>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid container alignItems={"center"} spacing={3}>
                    <Grid item>
                      <ShoppingBag color="primary" />
                    </Grid>
                    <Grid item>
                      <a
                        style={{ textDecoration: "none", color: "#aed581" }}
                        target="_blank"
                        href={`mailto:${salesEmail}`}
                        rel="noreferrer"
                      >
                        <h2>{salesEmail}</h2>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid container alignItems={"center"} spacing={3}>
                    <Grid item>
                      <Phone color="primary" />
                    </Grid>
                    <Grid item>
                      <a
                        style={{ textDecoration: "none", color: "#aed581" }}
                        href={`tel:${phone}`}
                      >
                        <h2>{phone}</h2>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <h4 style={{ marginTop: "2em" }}>
                Review our{" "}
                <a
                  href="/terms"
                  style={{ textDecoration: "none", color: "#aed581" }}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="/privacy"
                  style={{ textDecoration: "none", color: "#aed581" }}
                >
                  Privacy Policy
                </a>
                .
              </h4>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </FullPageLayout>
  );
};

export default Contact;
