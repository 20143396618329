import {useEffect, useMemo, useState} from "react";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {keyBy} from "lodash";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {useAuthenticated} from "../../lib/auth";
import {parseGQLErrorMessage} from "../../lib/validation";

import {
  ClientCompanyWithPricingFragment,
  GetOrdersDocument,
  OrderPricing, OrderSummaryFieldsFragment,
  PerformCheckoutInput, useEditOrderMutation,
  useGetClientCompaniesQuery, useGetOrderLazyQuery, useGetOrderQuery,
  usePerformCheckoutMutation,
} from "../../generated/graphql";

import ShippingOptions from "../../components/Checkout/ShippingOptions";
import LineItems from "../../components/Checkout/LineItems";
import CouponCode from "../../components/Checkout/CouponCode";
import CheckoutTable from "../../components/Checkout/CheckoutTable";
import Subtotal from "../../components/Checkout/Subtotal";
import FullPageLayout from "../../components/Layouts/FullPageLayout";

// mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Loading} from "../../components/Loading";

const DEFAULT_VALUES = {
  testTypes: [],
} as PerformCheckoutInput;

function OrderForm({
                     order,
                     companies
                   }: { order?: OrderSummaryFieldsFragment, companies: ClientCompanyWithPricingFragment[] }) {
  const formMethods = useForm<PerformCheckoutInput>({
    defaultValues: DEFAULT_VALUES,
  });

  const navigate = useNavigate();
  const [performCheckout, {loading: checkoutLoading}] = usePerformCheckoutMutation();
  const [editOrder, {loading: editLoading}] = useEditOrderMutation();
  const [pricingInfo, setPricingInfo] = useState<OrderPricing | undefined>();
  const {lab} = useAuthenticated();

  const [error, setError] = useState("");
  const companyId = formMethods.watch('clientCompanyId')
  const company = useMemo(() => {
    return companies.find(c => c.id === companyId)
  }, [companyId, companies])

  const handleSubmit = async (input: PerformCheckoutInput) => {
    try {
      const reply = order?.id ?
        window.confirm(
          "WARNING: Editing an existing order will delete all existing samples and re-attach new samples; previously submitted test results WILL BE LOST. Continue?"
        ) && await editOrder({
          variables: {
            input: {
              id: order.id,
              clientCompanyId: input.clientCompanyId,
              couponCode: input.couponCode || "",
              testTypes: input.testTypes,
              shipToBuyerMethod: input.shipToBuyerMethod,
              shipToLabMethod: input.shipToLabMethod,
            }
          }
        })
        :
        await performCheckout({
          variables: {
            input: {
              clientCompanyId: input.clientCompanyId,
              couponCode: input.couponCode || "",
              paymentToken: input.paymentToken || "",
              testTypes: input.testTypes,
              shipToBuyerMethod: input.shipToBuyerMethod,
              shipToLabMethod: input.shipToLabMethod,
            },
          },
        });

      setError("");
      window.location.pathname = (`/lab/manage-orders`);
    } catch (e) {
      setError(
        parseGQLErrorMessage(e) ||
        "An unknown error occurred, please contact support"
      );
    }
  };

  useEffect(() => {
    if (order?.id) {
      const testTypes = order.pricingInfo.lineItems.map(li => ({
        testType: li.product,
        numberOfSamples: parseInt(li.quantity),
        pricePerUnitInCents: li.pricePerUnitInCents
      }))

      if(!testTypes.length)
        setError("Order was created in an old version of the system, and is missing Line Item information; you can fill in the correct line items and save this order to add the missing information.")

      formMethods.reset({
        clientCompanyId: order.clientCompany?.id,
        couponCode: order.pricingInfo.couponCode,
        testTypes,
      })
    }

  }, [order?.id])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)} noValidate>
        <Grid container spacing={6}>
          <Grid item xs={12} md={7} lg={8}>
            <Grid container style={{marginBottom: "1em"}}>
              <Grid item xs={12}>
                <h2>{order?.id ? "Edit" : "Create"} Order</h2>
              </Grid>
              <Grid item xs={12}>
                <CompanyPicker companies={companies}/>
              </Grid>
            </Grid>
            <CheckoutTable company={company}/>
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <h2>Order Summary</h2>
            {pricingInfo ? <LineItems pricingInfo={pricingInfo}/> : null}
            <ShippingOptions company={company} disabled={Boolean(order?.id)}/>
            <CouponCode/>
            <Subtotal
              pricingInfo={pricingInfo}
              setPricingInfo={setPricingInfo}
              error={error}
              setError={setError}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={Boolean(error) || !pricingInfo || checkoutLoading || !company || editLoading}
              sx={{m: "1em 0 0"}}
            >
              {order?.id ? "Edit Order" : "Submit Order"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export function CompanyPicker({companies}: { companies: ClientCompanyWithPricingFragment[] }) {
  const formMethods = useFormContext();
  const [params] = useSearchParams();
  const byId = keyBy(companies, "id");
  const URL_COMPANY_ID = params.get("companyId");
  const value = formMethods.watch("clientCompanyId") || URL_COMPANY_ID;
  const company = value ? byId[value] : undefined;

  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={12} md={6}>
        {URL_COMPANY_ID ? (
          <input
            type="hidden"
            {...formMethods.register("clientCompanyId")}
            value={URL_COMPANY_ID}
          />
        ) : (
          <Autocomplete
            fullWidth
            options={companies.map((c) => c.id)}
            getOptionLabel={(id) => byId[id]?.businessName}
            disabled={Boolean(URL_COMPANY_ID)}
            value={value}
            onChange={(event, newValue) => {
              formMethods.setValue("clientCompanyId", newValue);
            }}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client Company"
                variant="outlined"
                required
                autoFocus
              />
            )}
          />
        )}
      </Grid>
      {company?.id ? (
        <Grid item xs={12} md={6}>
          <Typography variant={"h5"}>{company.businessName}</Typography>
          <Typography variant={"body2"}>
            {company.addressStreet} {company.addressStreet2}
            <br/>
            {company.addressCity}, {company.addressState} {company.addressZip}
            <br/>
            {company.phone}
            <br/>
            {company.billingEmail}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default function LabCreateEditOrder() {
  const [get, existing] = useGetOrderLazyQuery()
  const [params, setParams] = useSearchParams({id: ""})
  const companiesQuery = useGetClientCompaniesQuery();
  const companies = companiesQuery?.data?.getClientCompanies || [];
  const id = params.get("id")

  useEffect(() => {
    if (id) {
      get({variables: {id}})
    }
  }, [id, get])

  return (
    <FullPageLayout>
      <Card sx={{padding: "1em", mb: "1em"}}>
        {!companies.length || !id || (!existing.loading && existing?.data?.getOrder?.id) ?
          <OrderForm order={existing?.data?.getOrder || undefined} companies={companies}/> : <Loading/>}
      </Card>
    </FullPageLayout>
  );
}
